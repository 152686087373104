export function formatArcaNumber(number: any) {
	if (isNaN(number)) return '0,00';

	return number.toLocaleString('es-ES', {
		useGrouping: true,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
}

export const formatArcaNumberCalculeCents = (
	totalSaved: number,
	companyContributions: number,
	userContributions: number
): { formattedCompanyContributions: string; formattedUserContributions: string } => {
	// Sumar las contribuciones
	const totalContributions = companyContributions + userContributions;

	// Calcular el factor de ajuste inicial usando valores absolutos
	let adjustmentFactor = totalSaved / totalContributions;

	if (adjustmentFactor > 1) {
		adjustmentFactor = adjustmentFactor - 1;
	}

	// Redondear hacia arriba
	adjustmentFactor = Math.ceil(adjustmentFactor * 100) / 100; // Redondeo hacia arriba

	// Redondear el adjustmentFactor basado en potencias de 10 (0.1, 0.01, 0.001, etc.)
	if (adjustmentFactor <= 0) {
		adjustmentFactor = 0.1; // Valor mínimo por defecto
	} else {
		const exponent = Math.floor(Math.log10(adjustmentFactor));
		adjustmentFactor = Math.pow(10, exponent);
	}

	// Ajustar las contribuciones
	const adjustedCompanyContributions =
		Math.round(companyContributions * adjustmentFactor * 100) / 100;
	const adjustedUserContributions = Math.round(userContributions * adjustmentFactor * 100) / 100;

	// Formatear las contribuciones
	const formattedCompanyContributions = formatArcaNumber(adjustedCompanyContributions);
	const formattedUserContributions = formatArcaNumber(adjustedUserContributions);

	return { formattedCompanyContributions, formattedUserContributions };
};

export const formatNumberToDate = (n: number): string =>
	n.toString().replace(/(\d{4})(\d{2})(\d{2})/, '$3/$2/$1');

export const formatNetAssetValue = (number: number): number => {
	const integerPart = Math.floor(number / 1000000000); // Parte entera
	const decimalPart = (number % 1000000000).toString().padStart(9, '0'); // Parte decimal con ceros a la izquierda
	return Number(`${integerPart}.${decimalPart}`);
};

export const formatNumParticipations = (numParticipations: number): number => {
	const numParticipationsFormatted = numParticipations / 1000000;
	return numParticipationsFormatted;
};
