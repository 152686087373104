import { Menu, MenuButton, MenuList, MenuItem, Button, Box, Image, Text } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useContext, useState } from 'react';
import ProjectConfig from '../../constants';
import { triggerMetricsWaveEvent } from '../../metricswave';
import { METRICS_WAVE_FUNNEL_PAYFIT_FEATURE } from '../../metricswave/MetricswaveEnums';
import { ArcaContext } from '../../context/ArcaContext.context';

export enum SoftwareHHRR {
	SELECT = 'Selecciona tu software de nóminas',
	PAYFIT = 'Payfit',
	OTHER = 'Otro'
}

const SoftwareSelector = ({ onSelect }: { onSelect: (value: string) => void }) => {
	const { userId } = useContext(ArcaContext);
	const [selectedSoftware, setSelectedSoftware] = useState<SoftwareHHRR>(SoftwareHHRR.SELECT);

	const handleSelect = (value: SoftwareHHRR) => {
		setSelectedSoftware(value);
		onSelect(value);
	};

	return (
		<Menu>
			<MenuButton
				as={Button}
				rightIcon={<ChevronDownIcon />}
				bg={ProjectConfig.ARCA_COLORS.PINK_200}
				_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_200 }}>
				{selectedSoftware}
			</MenuButton>
			<MenuList bg={ProjectConfig.ARCA_COLORS.WHITE}>
				<MenuItem
					onClick={() => handleSelect(SoftwareHHRR.SELECT)}
					_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_200 }}>
					<Text>Selecciona tu software de nóminas</Text>
				</MenuItem>
				<MenuItem
					onClick={() => {
						const bodyParams = {
							step: METRICS_WAVE_FUNNEL_PAYFIT_FEATURE.CLICK_PAYFIT_HHRR_SOFTWARE_SELECTED
						};
						triggerMetricsWaveEvent('8daaff64-9c3b-4af2-ad2b-4655431673e3', userId, bodyParams);
						handleSelect(SoftwareHHRR.PAYFIT);
					}}
					_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_200 }}>
					<Box display="flex" alignItems="center">
						<Text mr={2}>Payfit</Text>
						<Image src="/imgs/payfit.png" alt="Payfit Logo" width="20px" height="20px" />
					</Box>
				</MenuItem>
				<MenuItem
					onClick={() => handleSelect(SoftwareHHRR.OTHER)}
					_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_200 }}>
					<Text>Otro</Text>
				</MenuItem>
			</MenuList>
		</Menu>
	);
};

export default SoftwareSelector;
