import { Box, Skeleton, Table, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import ProjectConfig from '../../constants';
import Pagination from '../ChakraUi/Pagination';
import { SingleUserContributionData } from '../../api/users/interfaces/ContributionData';

interface DesktopAdminContributionsTableProps {
	contributionItems: SingleUserContributionData[];
	isPageLoading: boolean;
	currentPage: number;
	totalPages: number;
	handlePageChange: (page: number) => void;
	translate: (key: string) => string;
	formatArcaNumber: (num: number) => string;
	formatDate: (dateString: string) => string;
}

const DesktopAdminContributionsTable: React.FC<DesktopAdminContributionsTableProps> = ({
	contributionItems,
	isPageLoading,
	currentPage,
	totalPages,
	handlePageChange,
	translate,
	formatArcaNumber,
	formatDate
}) => {
	return (
		<Table width="100%">
			<Thead>
				<Tr>
					<Th
						py="12px"
						color={ProjectConfig.ARCA_COLORS.GREEN_800}
						borderBottomWidth="2px"
						borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
						textAlign="center">
						{translate('admin.contributions.contributionsTableAmount')}
					</Th>
					<Th
						color={ProjectConfig.ARCA_COLORS.GREEN_800}
						borderBottomWidth="2px"
						borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
						textAlign="center">
						{translate('admin.contributions.contributionsTableDate')}
					</Th>
					<Th
						color={ProjectConfig.ARCA_COLORS.GREEN_800}
						borderBottomWidth="2px"
						borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
						textAlign="center">
						{translate('admin.contributions.contributionsTableWhoContributes')}
					</Th>
					<Th
						color={ProjectConfig.ARCA_COLORS.GREEN_800}
						borderBottomWidth="2px"
						borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
						textAlign="center">
						{translate('admin.contributions.contributionsTableContributionStatus')}
					</Th>
					<Th
						color={ProjectConfig.ARCA_COLORS.GREEN_800}
						borderBottomWidth="2px"
						borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
						textAlign="center">
						{translate('admin.contributions.contributionsTableNetValue')}
					</Th>
					<Th
						color={ProjectConfig.ARCA_COLORS.GREEN_800}
						borderBottomWidth="2px"
						borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
						textAlign="center">
						{translate('admin.contributions.contributionsTableShares')}
					</Th>
				</Tr>
			</Thead>
			<Tbody>
				{isPageLoading
					? Array.from({ length: 5 }).map((_, index) => (
							<Tr key={index}>
								<Td py="10px" borderColor="gray.300" textAlign="center">
									<Skeleton height="20px" />
								</Td>
								<Td py="10px" borderColor="gray.300" textAlign="center">
									<Skeleton height="20px" />
								</Td>
								<Td py="10px" borderColor="gray.300" textAlign="center">
									<Skeleton height="20px" />
								</Td>
								<Td py="10px" borderColor="gray.300" textAlign="center">
									<Skeleton height="20px" />
								</Td>
								<Td py="10px" borderColor="gray.300" textAlign="center">
									<Skeleton height="20px" />
								</Td>
								<Td py="10px" borderColor="gray.300" textAlign="center">
									<Skeleton height="20px" />
								</Td>
							</Tr>
						))
					: contributionItems?.map((item, index) => (
							<Tr key={index}>
								<Td py="10px" borderColor="gray.300" textAlign="center">
									{`${formatArcaNumber(item.contributionAmount)} €`}
								</Td>
								<Td py="10px" borderColor="gray.300" textAlign="center">
									{formatDate(item.dateContribution)}
								</Td>
								<Td py="10px" borderColor="gray.300" textAlign="center">
									{translate(`user.myContributions.contributionsTableType${item.typeContribution}`)}
								</Td>
								<Td py="10px" borderColor="gray.300" textAlign="center">
									{translate(
										`user.myContributions.contributionsTableStatus${item.statusContribution}`
									)}
								</Td>
								<Td py="10px" borderColor="gray.300" textAlign="center">
									{`${item.liquidationValue} €`}
								</Td>
								<Td py="10px" borderColor="gray.300" textAlign="center">
									{item.sharesBought}
								</Td>
							</Tr>
						))}
			</Tbody>
			<Tfoot>
				<Tr>
					<Td colSpan={6} textAlign="center" py="20px">
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</Td>
				</Tr>
			</Tfoot>
		</Table>
	);
};

export default DesktopAdminContributionsTable;
