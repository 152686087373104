import React from 'react';
import { Box, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import ProjectConfig from '../../../constants';

interface SimulatorCardProps {
	onClick: () => void;
	imageSrc: string;
	text: string;
}

const SimulatorCard: React.FC<SimulatorCardProps> = ({ onClick, imageSrc, text }) => {
	// Responsive width and height based on breakpoint
	const cardWidth = useBreakpointValue({
		base: '260px',
		sm: '200px',
		md: '200px',
		lg: '260px'
	});

	const cardHeight = useBreakpointValue({
		base: '260px',
		sm: '220px',
		md: '240px',
		lg: '280px'
	});

	return (
		<Box
			width={cardWidth}
			height={cardHeight}
			bg="gray.100"
			borderRadius="md"
			boxShadow="md"
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			cursor="pointer"
			onClick={onClick}
			mx={{ base: 'auto', sm: 3, md: 4 }} // Increased horizontal margin for better spacing
			my={4}
			p={{ base: 4, md: 5, lg: 6 }}
			border="4px solid"
			borderColor={ProjectConfig.ARCA_COLORS.PINK_300}
			transition="all 0.2s"
			_hover={{
				borderColor: ProjectConfig.ARCA_COLORS.PINK_600,
				boxShadow: 'lg',
				transform: { base: 'scale(1.05)', sm: 'scale(1.03)' }
			}}>
			<Image
				src={imageSrc}
				alt="Imagen del simulador"
				height={{ base: '150px', sm: '110px', md: '130px', lg: '160px' }}
				width={{ base: '150px', sm: '110px', md: '130px', lg: '160px' }}
				objectFit="cover"
			/>
			<Text
				fontWeight="bold"
				fontSize={{ base: 'xl', sm: 'md', md: 'lg', lg: 'xl' }}
				mt={{ base: 4, sm: 3 }}
				textAlign="center">
				{text}
			</Text>
		</Box>
	);
};

export default SimulatorCard;
