import { Flex, Box, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ArcaPrimaryButton from '../components/Buttons/ArcaPrimaryButton';
import ProjectConfig from '../constants';

const NotFoundPage = () => {
	return (
		<Flex justify="center" align="center" h="100vh">
			<Box textAlign="center">
				<Text fontSize="3xl" fontWeight="bold" mb={4}>
					Página no encontrada
				</Text>
				<Text mb={6}>Lo sentimos, la página que estás buscando no existe.</Text>
				<Link to="/">
					<ArcaPrimaryButton
						backgroundColor={ProjectConfig.ARCA_COLORS.PINK_200}
						_hover={{
							bg: ProjectConfig.ARCA_COLORS.PINK_400,
							color: ProjectConfig.ARCA_COLORS.WHITE
						}}>
						Volver al inicio
					</ArcaPrimaryButton>
				</Link>
			</Box>
		</Flex>
	);
};

export default NotFoundPage;
