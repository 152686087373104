// Este archivo contiene los tipos para el mapping de dropdowns en Payfit

// Enum para la columna "Tipo"
export enum TipoOptions {
	// Se usan nombres de claves descriptivos para cada valor
	VehiculoEmpresa = 'Vehículo de empresa',
	Parking = 'Parking',
	UsoVivienda = 'Uso de vivienda',
	RopaSerigrafiada = 'Ropa serigrafiada',
	Formacion = 'Formación',
	Telefonia = 'Telefonía',
	PlanesPensiones = 'Planes de pensiones',
	ViajesIncentivo = 'Viajes de incentivo',
	Gimnasio = 'Gimnasio',
	CuotaAutonomo = 'Cuota autónomo',
	Otro = 'Otro',
	PlanEmpleoEmpresaPromotora = 'Plan de empleo empresa promotora'
}

// Enum para la columna "Periodicidad"
export enum PeriodicidadOptions {
	UnaSolaVez = 'Una sola vez',
	Mensual = 'Mensual'
}

// Enum para la columna "Modelo de retribución"
export enum ModeloRetribucionOptions {
	RetribucionFlexible = 'Retribución flexible',
	BeneficioEnEspecie = 'Beneficio en especie',
	ModeloMixto = 'Modelo mixto'
}

// Enum para la columna "Ingreso a cuenta a cargo de la empresa"
export enum ReglasValoracionOptions {
	Entrega = 'Entrega',
	Uso = 'Uso',
	UsoYPosteriorEntrega = 'Uso y posterior entrega'
}

// Enum para la columna "(Tipo Vehículo) Reglas de valoración"
export enum ColumnaIngresoEmpresaBoolean {
	No = 'No',
	Si = 'Sí'
}

// Enum para la columna "(Tipo Vehículo) Vehículo propiedad del pagador?"
export enum VehiculoPropiedadBoolean {
	No = 'No',
	Si = 'Sí'
}

export const columnType = 'Tipo';
export const columnPeriodicity = 'Periodicidad';
export const columnModeloRetribucion = 'Modelo de retribución';
export const columnIngresoEmpresa = 'Ingreso a cuenta a cargo de la empresa';
export const columnReglasValoracion = '(Tipo Vehículo) Reglas de valoración';
export const columnVehiculoPropiedad = '(Tipo Vehículo) Vehículo propiedad del pagador?';

// Interfaz que representa el objeto de configuración completo de dropdown
export interface DropdownMapping {
	[columnType]: TipoOptions[];
	[columnPeriodicity]: PeriodicidadOptions[];
	[columnModeloRetribucion]: ModeloRetribucionOptions[];
	[columnIngresoEmpresa]: ColumnaIngresoEmpresaBoolean[];
	[columnReglasValoracion]: ReglasValoracionOptions[];
	[columnVehiculoPropiedad]: VehiculoPropiedadBoolean[];
}
