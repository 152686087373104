import React, { useContext, useEffect, useState } from 'react';
import { Box, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import DocumentDownload from '../../../components/Documents/DocumentDownload';
import LoadingViewSpinner from '../../../components/ChakraUi/Spinners/LoadingViewSpinner';
import getInvoices from '../../../api/invoices/getInvoices';
import { ArcaContext } from '../../../context/ArcaContext.context';
import SectionTitle from '../../../components/Titles/SectionTitle';

const AdminBilling: React.FC = () => {
	const {
		state: { translate }
	} = React.useContext(I18nContext);

	const { token, companyId } = useContext(ArcaContext);

	const [initialLoading, setInitialLoading] = useState<boolean>(true);
	const [documentLinks, setDocumentLinks] = useState<{ name: string; url: string }[]>([]);

	useEffect(() => {
		const fetchDocuments = async () => {
			const invoicesResponse = await getInvoices(token, companyId);

			if (invoicesResponse.success) {
				const fetchedDocuments = invoicesResponse.data.map((url: string) => {
					const fileName = url.split('/').pop() || 'Documento';
					const fileExtension = fileName.match(/\.[0-9a-z]+$/i)?.[0] || '';
					const nameWithoutExtension = fileName.replace(fileExtension, '');

					return {
						name:
							nameWithoutExtension.length > 25
								? `${nameWithoutExtension.substring(0, 25)}...${fileExtension}`
								: fileName,
						url: url
					};
				});
				setDocumentLinks(fetchedDocuments);
			} else {
				console.error('Error fetching invoices:', invoicesResponse);
			}
			setInitialLoading(false);
		};

		fetchDocuments();
	}, []);

	return (
		<Box mt="4">
			<SectionTitle text={translate('admin.billing.title')} />
			<Text fontSize="18px">{translate('admin.billing.subtitle')}</Text>

			{/* Loader mientras se cargan los documentos */}
			{initialLoading ? (
				<Box h="80dvh" display="flex" justifyContent="center" alignItems="center">
					<LoadingViewSpinner />
				</Box>
			) : (
				<Wrap spacing="4" justify="flex-start" w="100%" mt={4}>
					{documentLinks.map((doc, index) => (
						<WrapItem key={index}>
							<DocumentDownload documentName={doc.name} downloadLink={doc.url} />
						</WrapItem>
					))}
				</Wrap>
			)}
		</Box>
	);
};

export default AdminBilling;
