import { Box } from '@chakra-ui/react';
import React from 'react';
import ProjectConfig from '../../constants';
import MobileAdminContributionsTable from './MobileAdminContributionsTable';
import DesktopAdminContributionsTable from './DesktopAdminContributionsTable';
import { SingleUserContributionData } from '../../api/users/interfaces/ContributionData';

interface AdminContributionsTableWrapperProps {
	contributionItems: SingleUserContributionData[];
	isPageLoading: boolean;
	currentPage: number;
	totalPages: number;
	handlePageChange: (page: number) => void;
	translate: (key: string) => string;
	formatArcaNumber: (num: number) => string;
	formatDate: (dateString: string) => string;
}

const AdminContributionsTableWrapper: React.FC<AdminContributionsTableWrapperProps> = ({
	contributionItems,
	isPageLoading,
	currentPage,
	totalPages,
	handlePageChange,
	translate,
	formatArcaNumber,
	formatDate
}) => {
	return (
		<Box
			backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
			borderRadius="12px"
			width="100%"
			overflowX="auto">
			{/* Desktop and tablet view */}
			<Box display={{ base: 'none', md: 'block' }}>
				<DesktopAdminContributionsTable
					contributionItems={contributionItems}
					isPageLoading={isPageLoading}
					currentPage={currentPage}
					totalPages={totalPages}
					handlePageChange={handlePageChange}
					translate={translate}
					formatArcaNumber={formatArcaNumber}
					formatDate={formatDate}
				/>
			</Box>

			{/* Mobile view - card-based layout */}
			<Box display={{ base: 'block', md: 'none' }}>
				<MobileAdminContributionsTable
					contributionItems={contributionItems}
					isPageLoading={isPageLoading}
					currentPage={currentPage}
					totalPages={totalPages}
					handlePageChange={handlePageChange}
					translate={translate}
					formatArcaNumber={formatArcaNumber}
					formatDate={formatDate}
				/>
			</Box>
		</Box>
	);
};

export default AdminContributionsTableWrapper;
