import React, { useState, useEffect } from 'react';
import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	useDisclosure,
	SimpleGrid
} from '@chakra-ui/react';
import FinancialPlannerPensionSimulator from '../../../components/Simulators/FinancialPlannerPensionSimulator';
import SimulatorCard from '../../../components/ChakraUi/Cards/SimulatorCard';
import PayrollSimulator from '../../../components/Simulators/PayrollSimulator';
import InflationSimulator from '../../../components/Simulators/InflationSimulator';

interface SimulatorsProps {
	currentAge: number;
}

type SimulatorOption = {
	id: string;
	text: string;
	imageSrc: string;
	component: React.ReactNode;
};

const Simulators: React.FC<SimulatorsProps> = ({ currentAge }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedSimulator, setSelectedSimulator] = useState<SimulatorOption | null>(null);

	// Añadir efecto para resetear scroll cuando el componente se monte
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const simulatorOptions: SimulatorOption[] = [
		{
			id: 'pension',
			text: 'Planificador plan de pensiones',
			imageSrc: '/imgs/simulators/bed-money.png',
			component: <FinancialPlannerPensionSimulator currentAge={currentAge} />
		},
		{
			id: 'payroll',
			text: 'Simulador de nóminas',
			imageSrc: '/imgs/simulators/payroll.png',
			component: <PayrollSimulator />
		},
		{
			id: 'inflation',
			text: 'Simulador de inflación',
			imageSrc: '/imgs/simulators/inflation.png',
			component: <InflationSimulator currentAge={currentAge} />
		}
	];

	const handleCardClick = (simulator: SimulatorOption) => {
		setSelectedSimulator(simulator);
		onOpen();
	};

	return (
		<>
			<Box padding="4">
				<SimpleGrid columns={[1, 2, 3]} spacing="4">
					{simulatorOptions.map((simulator) => (
						<SimulatorCard
							key={simulator.id}
							onClick={() => handleCardClick(simulator)}
							imageSrc={simulator.imageSrc}
							text={simulator.text}
						/>
					))}
				</SimpleGrid>
			</Box>
			<Modal
				isOpen={isOpen}
				onClose={() => {
					setSelectedSimulator(null);
					onClose();
				}}
				size="5xl"
				isCentered
				motionPreset="slideInTop">
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody>{selectedSimulator && selectedSimulator.component}</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default Simulators;
