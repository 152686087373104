import React, { useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { Tooltip, Box, Text, Link, useDisclosure } from '@chakra-ui/react';
import ProjectConfig from '../../constants';

/** #BOOSTME Pendiente de ver si se pudiera poner un blur alrededor del Tooltip para destacarlo aún más */

interface infoIconClickProps {
	text: string;
	isVisible: boolean;
	maxW?: string;
	showLink?: string;
}

const InfoIconClick: React.FC<infoIconClickProps> = ({
	text,
	isVisible,
	maxW = '200px',
	showLink = ''
}) => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const [openedByClick, setOpenedByClick] = useState(false);

	// Add click outside handler
	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (openedByClick && !(event.target as Element).closest('[role="tooltip"]')) {
				onClose();
				setOpenedByClick(false);
			}
		};

		if (openedByClick) {
			document.addEventListener('click', handleClickOutside);
		}

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [openedByClick, onClose]);

	if (!isVisible) {
		return null;
	}

	const handleClick = (e: React.MouseEvent) => {
		e.stopPropagation(); // Prevent immediate trigger of click outside

		if (!isOpen) {
			onOpen();
			setOpenedByClick(true);
		} else {
			onClose();
			setOpenedByClick(false);
		}

		if (showLink) {
			window.open(showLink, '_blank');
		}
	};

	const handleMouseEnter = () => {
		if (!openedByClick) {
			onOpen();
		}
	};

	const handleMouseLeave = () => {
		if (!openedByClick) {
			onClose();
		}
	};

	return (
		<Tooltip
			label={
				<Box>
					<Text color="white">{text}</Text>
					{showLink !== '' && (
						<Link href={showLink} isExternal textDecoration="underline">
							{showLink}
						</Link>
					)}
				</Box>
			}
			isOpen={isOpen} // Control tooltip visibility with state
			openDelay={300}
			bg="gray.800"
			color="white"
			borderRadius="md"
			p="15px"
			maxW={maxW}
			placement="right-start"
			fontSize="15px"
			shadow="dark-lg"
			colorScheme={ProjectConfig.ARCA_COLORS.PINK_500}
			closeOnPointerDown={false}
			closeOnMouseDown={false}
			closeOnClick={false}
			hasArrow
			gutter={4}>
			<Box
				as="span"
				color={ProjectConfig.ARCA_COLORS.PINK_500}
				onClick={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				cursor="pointer"
				aria-label={text ? text.substring(0, 20) + '...' : 'More information'}>
				<FiAlertCircle />
			</Box>
		</Tooltip>
	);
};

export default InfoIconClick;
