import { useNavigate, useLocation } from 'react-router';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import {
	Flex,
	Box,
	VStack,
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Avatar,
	Text,
	useBreakpointValue
} from '@chakra-ui/react';
import { GrUserAdmin } from 'react-icons/gr';
import { FaRegUser, FaChevronDown, FaCalculator } from 'react-icons/fa';
import {
	TbChevronLeftPipe,
	TbChevronRightPipe,
	TbFileInvoice,
	TbLogout,
	TbPigMoney
} from 'react-icons/tb';
import { GoHome } from 'react-icons/go';
import { PiUsersFourLight } from 'react-icons/pi';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi2';
import { MdDisplaySettings } from 'react-icons/md';
import { AiOutlineMessage } from 'react-icons/ai';
// import { Latitude } from '@latitude-data/sdk';

// Tabs del usuario
import UserMyBenefit from './DashboardTabs/User/MiBeneficio';
import UserConfigurarBeneficioTab from './DashboardTabs/User/ConfigurarBeneficio';
import PlanEmpleo from './DashboardTabs/User/PlanEmpleo';
import UserAjustesTab from './DashboardTabs/User/AjustesUser';
import Simulators from './DashboardTabs/User/Simulators';

// Tabs del administrador
import AdminInicioTab from './DashboardTabs/Admin/InicioAdmin';
import PlantillaTab from './DashboardTabs/Admin/Plantilla';
import AdminConfigurarBeneficioTab from './DashboardTabs/Admin/ConfigurarBeneficio';
import AdminBilling from './DashboardTabs/Admin/AdminBilling';

import { ArcaContext } from '../context/ArcaContext.context';
import { I18nContext } from '../context/translations/TranslationsContext.context';
import ProjectConfig from '../constants';
import getCurrentUser from '../api/users/getCurrentUser';
import EMPTY_CURRENT_USER, { CurrentUserI } from '../api/users/interfaces/Users ';
import AdminContribuciones from './DashboardTabs/Admin/AdminContribuciones';
import { clearContextAndLocalStorage } from '../utils/clearLocalStorageAndContext';
import syncAllMovements from '../api/users/syncAllMovements';
import dayjs from 'dayjs';
import { trackVisit } from '../metricswave/TrackVisit';
import checkFeatureFlagByName from '../api/feature-flags/checkFeatureFlagByName';
import AnnounceNewFeatureModal from '../components/ChakraUi/Modals/AnnounceNewFeatureModal';
import MobileBottomNav from '../components/Navigation/MobileBottomNav';
import DraggableMobileChatButton from '../components/Chat/DraggableMobileChatButton';
import ContactSupportModal from '../components/Modals/ContactSupportModal';
import AIAssistantModal from '../components/Chat/AIAssistantModal';
import TaxdownBenefitModal from '../components/ChakraUi/Modals/TaxdownBenefitModal';

interface Tab {
	key: string;
	label: string;
	icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
	component: ReactNode;
	route: string;
}

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
	const { token, companyId, userId } = useContext(ArcaContext);
	const navigate = useNavigate();
	const location = useLocation();
	const { userAccountType, language } = useContext(ArcaContext);

	const {
		state: { translate }
	} = useContext(I18nContext);

	const myBenefit = `${translate('user.dashboard.myBenefit')}`;
	const userMyContributions = `${translate('user.dashboard.myContributions')}`;
	const adminMyContributions = `${translate('admin.dashboard.myContributions')}`;
	const employmentPlan = `${translate('user.dashboard.employmentPlan')}`;
	const simulators = `${translate('user.dashboard.simulators')}`;
	const userSettings = `${translate('user.dashboard.userSettings')}`;
	const home = `${translate('admin.dashboard.home')}`;
	const staff = `${translate('admin.dashboard.staff')}`;
	const setBenefit = `${translate('admin.dashboard.setBenefit')}`;
	const adminSettings = `${translate('admin.dashboard.adminSettings')}`;
	const billing = `${translate('admin.dashboard.billing')}`;

	// Defino los tabs por defecto para el usuario y el administrador
	const USER_DEFAULT_TAB = myBenefit;
	const ADMIN_DEFAULT_TAB = home;

	// Define estos breakpoints de forma reactiva
	const isMobileScreen = useBreakpointValue({ base: true, sm: false });
	const defaultMenuOpen = useBreakpointValue({ base: false, lg: true });

	// Inicializa el estado del menú con un valor por defecto
	const [isMenuOpen, setIsMenuOpen] = useState(() => {
		// Initialize based on defaultMenuOpen, fallback to false if undefined
		return defaultMenuOpen ?? false;
	});

	// Actualiza isMenuOpen cuando cambie el tamaño de la pantalla
	useEffect(() => {
		if (defaultMenuOpen !== undefined) {
			setIsMenuOpen(defaultMenuOpen);
		}
	}, [defaultMenuOpen]);

	const [activeTab, setActiveTab] = useState(
		userAccountType === 'BOTH' || userAccountType === 'ADMIN' ? ADMIN_DEFAULT_TAB : USER_DEFAULT_TAB
	);
	const [viewType, setViewType] = useState<string>(
		userAccountType === 'BOTH' || userAccountType === 'ADMIN' ? 'ADMIN' : 'USER'
	);

	const [isChatOpen, setIsChatOpen] = useState(false);

	const [currentUser, setCurrentUser] = useState<CurrentUserI>(EMPTY_CURRENT_USER);
	const [onboardingDone, setOnboardingDone] = useState<boolean>(true);

	const [isFreelance, setIsFreelance] = useState<boolean>(false);
	const [currentAge, setCurrentAge] = useState<number>(18);

	const startYear = Number(currentUser?.user?.createdAt?.split('-')[0]);

	const [showSimulatorTab, setShowSimulatorTab] = useState<boolean>(false);

	const [announceModalParams, setAnnounceModalParams] = useState<{
		featureFlagName: string;
		isOpen: boolean;
		mainRoleForFeature: 'USER' | 'ADMIN';
		title: string;
		description: string;
		imageSrc: string;
		path?: string;
		link?: string;
	}>({
		featureFlagName: '',
		isOpen: false,
		title: '',
		description: '',
		imageSrc: '',
		mainRoleForFeature: 'USER',
		link: ''
	});

	const [isAIAssistantOpen, setIsAIAssistantOpen] = useState(false);

	// useEffect(() => {
	// 	const checkFeatureFlag = async () => {
	// 		const checkFeatureFlag = await checkFeatureFlagByName(
	// 			token,
	// 			companyId,
	// 			userId,
	// 			'simulator-tab-feature'
	// 		);
	// 		const { isActive, userHasSeenFeature } = checkFeatureFlag?.data;
	// 		setShowSimulatorTab(isActive);
	// 		if (!userHasSeenFeature) {
	// 			setAnnounceModalParams({
	// 				featureFlagName: 'simulator-tab-feature',
	// 				isOpen: true,
	// 				mainRoleForFeature: 'USER',
	// 				title: '¡Estrenamos un nuevo Simulador de Pensiones!',
	// 				description:
	// 					'Proyecta cómo tus aportaciones y las de tu empresa crecen con el tiempo y planifica tu futuro con mayor claridad. Lo tienes en la sección "Simuladores" del portal Empleado ¡Que viva el ahorro compuesto!',
	// 				imageSrc: '/imgs/simulators/bed-money.png',
	// 				path: viewType === 'USER' ? '/dashboard/simulators' : ''
	// 			});
	// 		}
	// 	};
	// 	checkFeatureFlag();
	// }, [token, companyId, userId]);

	useEffect(() => {
		userAccountType === 'USER'
			? navigate(`/dashboard/${userTabs[0].route}`)
			: navigate(`/dashboard/${adminTabs[0].route}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const getCurrentUserAsync = async () => {
			const currentUser = await getCurrentUser(token);
			const currentUserData = currentUser.data as CurrentUserI;
			setCurrentUser(currentUserData);

			setIsFreelance(currentUserData?.user?.isFreelance);
			const userBirthDate = currentUserData?.user?.birthdate;
			const currentDate = dayjs();
			const age = currentDate.diff(userBirthDate, 'year');
			setCurrentAge(age);
			const userOnboard = currentUser.data.user.completedOnboarding;
			setOnboardingDone(userOnboard);

			if (!userOnboard) {
				navigate('/onboarding-user');
			}

			const user = currentUserData?.user;
			const date = dayjs(user.lastMovementsUpdate);
			const userHasSynchronisedMovementsToday = date.isSame(dayjs(), 'day');

			if (!userHasSynchronisedMovementsToday) {
				await syncAllMovements(token);
			}

			const sameViewType = viewType;
			setViewType(sameViewType);

			const newActiveTab = sameViewType === 'ADMIN' ? ADMIN_DEFAULT_TAB : USER_DEFAULT_TAB;
			setActiveTab(newActiveTab);
		};

		getCurrentUserAsync();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language, userAccountType, viewType, token]);

	// Pestañas para el usuario
	const userTabs: Tab[] = [
		{
			key: myBenefit,
			label: myBenefit,
			icon: <GoHome />,
			component: (
				<UserMyBenefit
					userName={currentUser?.user.name || ''}
					isFreelance={isFreelance}
					startYear={startYear}
				/>
			),
			route: 'my-benefit'
		},
		{
			key: userMyContributions,
			label: userMyContributions,
			icon: <TbPigMoney />,
			component: <UserConfigurarBeneficioTab isFreelance={isFreelance} />,
			route: 'my-contributions'
		},
		{
			key: employmentPlan,
			label: employmentPlan,
			icon: <HiOutlineDocumentDuplicate />,
			component: <PlanEmpleo isFreelance={isFreelance} />,
			route: 'employment-pension-plan'
		},
		{
			key: simulators,
			label: simulators,
			icon: <FaCalculator />,
			component: <Simulators currentAge={currentAge} />,
			route: 'simulators'
		},
		{
			key: userSettings,
			label: userSettings,
			icon: <MdDisplaySettings />,
			component: <UserAjustesTab />,
			route: 'settings'
		}
	];

	const adminTabs: Tab[] = [
		{
			key: home,
			label: home,
			icon: <GoHome />,
			component: (
				<AdminInicioTab
					userName={currentUser?.user.name || ''}
					representsCompany={currentUser?.user.representsCompany}
					representsWorkers={currentUser?.user.representsParticipant}
					startYear={startYear}
				/>
			),
			route: 'home'
		},
		{
			key: staff,
			label: staff,
			icon: <PiUsersFourLight />,
			component: <PlantillaTab startYear={startYear} />,
			route: 'staff'
		},
		{
			key: adminMyContributions,
			label: adminMyContributions,
			icon: <TbPigMoney />,
			component: <AdminContribuciones />,
			route: 'contributions'
		},
		{
			key: setBenefit,
			label: setBenefit,
			icon: <HiOutlineDocumentDuplicate />,
			component: <AdminConfigurarBeneficioTab />,
			route: 'set-benefit'
		},
		{
			key: billing,
			label: billing,
			icon: <TbFileInvoice />,
			component: <AdminBilling />,
			route: 'billing'
		},
		{
			key: adminSettings,
			label: adminSettings,
			icon: <MdDisplaySettings />,
			component: <UserAjustesTab />,
			route: 'settings'
		}
	];

	const currentTabs = viewType === 'USER' ? userTabs : adminTabs;

	const handleLogout = () => {
		clearContextAndLocalStorage();
		// Metricswave - Track visit (logout)
		trackVisit(null);
		navigate('/');
	};

	const handleChatClick = () => {
		setIsChatOpen((isChatOpen) => !isChatOpen);
	};

	const setActiveTabAndNavigate = (tab: Tab) => {
		setActiveTab(tab.key);
		navigate(`/dashboard/${tab.route}`);
	};

	const findTabByRoute = (route: string): Tab | undefined => {
		const tabs = viewType === 'USER' ? userTabs : adminTabs;
		return tabs.find((tab) => tab.route === route);
	};

	useEffect(() => {
		const path = location.pathname.split('/').pop() || '';
		const tab = findTabByRoute(path);
		if (tab) {
			setActiveTab(tab.key);
		}
	}, [location, viewType]);

	const getActiveComponent = (activeTab: string): ReactNode | null => {
		const tabs = viewType === 'USER' ? userTabs : adminTabs;
		const tab = tabs.find((tab) => tab.key === activeTab);
		return tab ? tab.component : null;
	};

	// Esta función ayudará a navegar al tab por defecto según el tipo de vista
	const navigateToDefaultTab = (type: string) => {
		if (type === 'ADMIN') {
			setActiveTab(ADMIN_DEFAULT_TAB);
			navigate(`/dashboard/${adminTabs[0].route}`);
		} else {
			setActiveTab(USER_DEFAULT_TAB);
			navigate(`/dashboard/${userTabs[0].route}`);
		}
	};

	const handleOpenAIAssistant = () => {
		// Cerrar el modal de soporte si está abierto
		if (isChatOpen) {
			handleChatClick();
		}

		// Abrir el modal del asistente IA
		setIsAIAssistantOpen(true);
	};

	return (
		<Flex h="100%" objectFit="contain">
			{/* Menú lateral - se oculta en móviles */}
			<VStack
				zIndex="2"
				textAlign="center"
				justifyContent="space-between"
				alignItems="center"
				width={isMenuOpen ? '330px' : '100px'}
				height="100vh"
				position="sticky"
				top={0}
				p={4}
				bg={ProjectConfig.ARCA_COLORS.WHITE}
				display={{ base: 'none', sm: 'flex' }} // Ocultar en base (móvil)
			>
				<Box>
					{/* OPEN/CLOSE MENU */}
					{!isMobileScreen && (
						<Button
							width="14%"
							onClick={() => setIsMenuOpen(!isMenuOpen)}
							bgColor={ProjectConfig.ARCA_COLORS.PINK_500}
							_hover={{ color: ProjectConfig.ARCA_COLORS.PINK_100 }}
							fontSize={28}>
							<Box fontSize="28px" fontWeight="bold">
								{isMenuOpen ? <TbChevronLeftPipe /> : <TbChevronRightPipe />}
							</Box>
						</Button>
					)}

					{/* OPEN/CLOSE MENU */}

					{/* LOGO EMPRESA PROMOTORA*/}
					{/* <Box mt={1}>
						<Box display="flex" justifyContent="center">
							<Img src="" />
						</Box>
					</Box> */}

					{/* LOGO EMPRESA PROMOTORA*/}

					{/* AVATAR */}

					<Box mt={1} display="flex" justifyContent="center">
						{isMenuOpen ? (
							<Box display="flex" alignItems="center" height="72px">
								{' '}
								{/* Establece la altura fija aquí */}
								<Avatar
									size="md"
									name={currentUser?.user.name || ''}
									src="/imgs/user-profile.png"
								/>
								<Box ml="4">
									<Text fontWeight="bold" fontSize={18}>
										{currentUser?.user.name || ''} {currentUser?.user.firstSurname || ''}
									</Text>
									<Text>
										{currentUser?.user.email?.substring(0, 30) +
											(currentUser?.user.email?.length > 30 ? '...' : '') || ''}
									</Text>
								</Box>
							</Box>
						) : (
							<Box display="flex" alignItems="center" height="72px">
								{' '}
								{/* Mantén la misma altura fija aquí */}
								<Avatar
									size="md"
									name={currentUser?.user.name || ''}
									src="/imgs/user-profile.png"
								/>
							</Box>
						)}
					</Box>

					{/* AVATAR */}

					{/* ROL SELECTOR */}

					{userAccountType === 'BOTH' ? (
						<Box marginTop={6} borderRadius="30px" border="1px solid" overflow="hidden">
							<Box display="flex" justifyContent="center" w="100%">
								<Menu>
									<MenuButton
										as={Button}
										w="100%"
										bgColor={ProjectConfig.ARCA_COLORS.WHITE}
										fontWeight=""
										fontSize="18px"
										borderRadius="20px"
										rightIcon={isMenuOpen ? <FaChevronDown /> : undefined}
										m={0}
										pl={isMenuOpen ? '50px' : '10px'}
										pr={isMenuOpen ? '50px' : '0px'}
										textAlign="center">
										{viewType === 'USER' ? (
											<Box
												display="flex"
												justifyContent="space-between"
												alignItems="center"
												margin="12px">
												<Box fontSize="21px" pr="10px">
													<FaRegUser />
												</Box>

												{isMenuOpen &&
													(isFreelance
														? translate('general.avatar.freelanceView')
														: translate('general.avatar.userView'))}
											</Box>
										) : (
											<Box
												display="flex"
												justifyContent="space-between"
												alignItems="center"
												margin="12px">
												<Box fontSize="23px">
													<GrUserAdmin />
												</Box>
												{isMenuOpen && translate('general.avatar.adminView')}
											</Box>
										)}
									</MenuButton>
									<MenuList borderRadius="25px">
										<MenuItem
											p="10px 0px 10px 100px"
											bg={viewType === 'ADMIN' ? ProjectConfig.ARCA_COLORS.PINK_100 : 'white'}
											onClick={() => {
												setViewType('ADMIN');
												setActiveTab(home);
												navigate(`/dashboard/${adminTabs[0].route}`);
											}}>
											<Box fontSize="24px">
												<GrUserAdmin />
											</Box>
											&nbsp;&nbsp; {translate('general.avatar.admin')}
										</MenuItem>
										<MenuItem
											p="10px 95px 10px 90px"
											bg={viewType === 'USER' ? ProjectConfig.ARCA_COLORS.PINK_100 : 'white'}
											onClick={() => {
												setViewType('USER');
												setActiveTab(myBenefit);
												navigate(`/dashboard/${userTabs[0].route}`);
											}}>
											<Box fontSize="22px">
												<FaRegUser />
											</Box>
											&nbsp;&nbsp; {translate('general.avatar.user')}
										</MenuItem>
									</MenuList>
								</Menu>
							</Box>
						</Box>
					) : (
						<Box marginTop={6} borderRadius="30px" border="1px solid" overflow="hidden">
							<Box display="flex" justifyContent="center" w="100%">
								<Button
									w="100%"
									bgColor={ProjectConfig.ARCA_COLORS.WHITE}
									fontWeight=""
									fontSize="18px"
									borderRadius="20px"
									m={0}
									pl="10px"
									pr="0px"
									textAlign="center"
									_hover={{ color: ProjectConfig.ARCA_COLORS.PINK_100 }}>
									<Box
										display="flex"
										justifyContent="space-between"
										alignItems="center"
										margin="12px">
										<Box fontSize="21px" pr="10px">
											<FaRegUser />
										</Box>
										{isFreelance
											? translate('general.avatar.freelanceView')
											: translate('general.avatar.userView')}
									</Box>
								</Button>
							</Box>
						</Box>
					)}

					{/* ROL SELECTOR */}
				</Box>

				{/* TABS */}

				<Box>
					{currentTabs.map((tab) => (
						<Button
							fontSize="20px"
							justifyContent="start"
							alignItems="center"
							key={tab.key}
							onClick={() => setActiveTabAndNavigate(tab)}
							bg={ProjectConfig.ARCA_COLORS.WHITE}
							borderRadius="md"
							m={1}
							p={1}
							fontWeight={activeTab === tab.key ? 'bold' : 'normal'}
							w={isMenuOpen ? 'full' : 'auto'}
							paddingBlock={8}>
							<Box
								bg={activeTab === tab.key ? ProjectConfig.ARCA_COLORS.PINK_100 : ''}
								borderRadius="md"
								display="flex"
								alignItems="center"
								justifyContent="center"
								padding={4}>
								{tab.icon}
							</Box>
							{isMenuOpen && (
								<Box paddingLeft={6}>
									{
										<Text
											fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
											fontSize="20px"
											fontWeight="900"
											height="34px">
											{tab.label}
										</Text>
									}
								</Box>
							)}
						</Button>
					))}
				</Box>
				{/* TABS */}

				{/* BY ARCA */}

				<Box
					mt={1}
					display="flex"
					justifyContent="center"
					flexDirection="column"
					alignItems="center">
					{isMenuOpen ? (
						<Box display="flex" alignItems="center" height="72px">
							{' '}
							<Box ml="4">
								<Text fontWeight="bold" fontSize={18} marginEnd={5}>
									{translate('generic.dashboard.poweredBy')}
								</Text>
							</Box>
							<Avatar size="md" name={currentUser?.user.name || ''} src="/imgs/ICONO_RRSS.png" />
						</Box>
					) : (
						<Box display="flex" alignItems="center" height="72px">
							{' '}
							<Avatar size="md" name={currentUser?.user.name || ''} src="/imgs/ICONO_RRSS.png" />
						</Box>
					)}

					{/* BY ARCA */}

					{/* LOGOUT */}

					{isMenuOpen ? (
						<Button
							alignSelf="flex-end"
							// mb="20px"
							onClick={handleLogout}
							bg={ProjectConfig.ARCA_COLORS.WHITE}
							_hover={{ color: ProjectConfig.ARCA_COLORS.PINK_100 }}>
							<TbLogout />
							<Box ml="10px">{translate('general.button.logOut')}</Box>
						</Button>
					) : (
						<Button
							width="14%"
							onClick={handleLogout}
							bg={ProjectConfig.ARCA_COLORS.WHITE}
							_hover={{ color: ProjectConfig.ARCA_COLORS.PINK_100 }}>
							<Box fontSize="28px" fontWeight="bold">
								<TbLogout />
							</Box>
						</Button>
					)}
					{/* LOGOUT */}
				</Box>
			</VStack>

			<Box
				zIndex="1"
				p={4}
				pb={isMobileScreen ? '80px' : 4}
				bg={ProjectConfig.ARCA_COLORS.LIGHT_GREY_02}
				flexGrow={1}
				h="100%"
				minH="100vh">
				{getActiveComponent(activeTab)}
			</Box>

			{/* Menú inferior para móviles */}
			{isMobileScreen && (
				<MobileBottomNav
					currentTabs={currentTabs}
					activeTab={activeTab}
					setActiveTabAndNavigate={setActiveTabAndNavigate}
					currentUser={currentUser}
					handleLogout={handleLogout}
					viewType={viewType}
					userAccountType={userAccountType}
					setViewType={setViewType}
					translate={translate}
					navigateToDefaultTab={navigateToDefaultTab}
					isFreelance={isFreelance}
				/>
			)}

			{/* Botón de contacto flotante - ajustar posición en móviles */}
			{isMobileScreen ? (
				<DraggableMobileChatButton onClick={handleChatClick} />
			) : (
				<Button
					onClick={handleChatClick}
					position="fixed"
					bottom="20px"
					right="20px"
					zIndex="999"
					width="60px"
					height="60px"
					borderRadius="50%"
					bg={ProjectConfig.ARCA_COLORS.PINK_500}
					color="white"
					_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_600 }}
					display="flex"
					justifyContent="center"
					alignItems="center"
					boxShadow="lg">
					<AiOutlineMessage size="24px" />
				</Button>
			)}

			<AnnounceNewFeatureModal
				featureFlagName={announceModalParams?.featureFlagName}
				isOpen={announceModalParams?.isOpen}
				onClose={() => setAnnounceModalParams({ ...announceModalParams, isOpen: false })}
				mainRoleForFeature={announceModalParams?.mainRoleForFeature}
				title={announceModalParams.title}
				description={announceModalParams.description}
				imageSrc={announceModalParams?.imageSrc}
				path={announceModalParams?.path}
				link={announceModalParams?.link}
			/>

			<ContactSupportModal
				isOpen={isChatOpen}
				onClose={handleChatClick}
				isMobileScreen={isMobileScreen || false}
				translate={translate}
				onOpenAIAssistant={handleOpenAIAssistant}
			/>

			{/* Modal del asistente IA */}
			<AIAssistantModal
				isOpen={isAIAssistantOpen}
				onClose={() => setIsAIAssistantOpen(false)}
				userId={userId}
			/>
		</Flex>
	);
};

export default Dashboard;
