import {
	Box,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Heading,
	Link,
	Text,
	Stack,
	UnorderedList,
	ListItem
} from '@chakra-ui/react';
import ProjectConfig from '../../../constants';
import InfoIconClick from '../infoIconClick';
import { useNavigate } from 'react-router';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import { useContext, useState, useEffect } from 'react';

interface BenUserCardProps {
	heading?: any;
	cardBody: any;
	infoIconVisible: boolean;
	infoIconText: string;
	isClickable?: boolean;
	linkText?: string;
	width?: string | object;
	minHeight?: string;
	height?: string;
	minW?: string;
	cardBodyTextSize?: any;
	textSize?: string | object;
	path?: string;
	link?: string;
	actionsCount?: number;
	netAssetValue?: number;
	boundsCount?: number;
	boundsAssetValue?: number;
}

const BenUserCard: React.FC<BenUserCardProps> = ({
	heading,
	cardBody,
	infoIconVisible,
	infoIconText,
	actionsCount,
	netAssetValue,
	boundsCount,
	boundsAssetValue,
	linkText,
	link,
	isClickable,
	width,
	minHeight = 'auto',
	minW = '150px',
	cardBodyTextSize = { base: '24px', sm: '26px', md: '28px', xl: '32px' },
	textSize,
	path
}: BenUserCardProps) => {
	const navigate = useNavigate();
	const [isTouched, setIsTouched] = useState(false);
	const [touchTimer, setTouchTimer] = useState<NodeJS.Timeout | null>(null);

	const {
		state: { translate }
	} = useContext(I18nContext);

	// Cleanup function for the touch timer
	const clearTouchTimer = () => {
		if (touchTimer) {
			clearTimeout(touchTimer);
			setTouchTimer(null);
		}
	};

	// Set a new touch timer
	const startTouchTimer = () => {
		clearTouchTimer(); // Clear any existing timer first
		const timer = setTimeout(() => {
			setIsTouched(false);
		}, 2000);
		setTouchTimer(timer);
	};

	// Cleanup on unmount
	useEffect(() => {
		return () => {
			clearTouchTimer();
		};
	}, []);

	// When isTouched changes, manage the timer
	useEffect(() => {
		if (isTouched) {
			startTouchTimer();
		}
	}, [isTouched]);

	const handleCardClick = () => {
		if (isClickable) {
			setIsTouched(true);
			if (path) {
				navigate(path, { replace: true });
			} else if (link) {
				window.location.href = link;
			}
		}
	};

	const handleTouchStart = () => {
		if (isClickable) {
			setIsTouched(true);
		}
	};

	const handleTouchEnd = () => {
		// Don't reset isTouched immediately, let the timer handle it
		// This ensures the card stays green for 2 seconds after touch
	};

	return (
		<Card
			display="flex"
			borderRadius="16px"
			minW={minW}
			minHeight={minHeight}
			backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
			width={width}
			onClick={handleCardClick}
			onTouchStart={handleTouchStart}
			onTouchEnd={handleTouchEnd}
			onTouchCancel={handleTouchEnd}
			cursor={isClickable ? 'pointer' : 'default'}
			borderColor={ProjectConfig.ARCA_COLORS.GREEN_200}
			_hover={{
				bg: isClickable ? ProjectConfig.ARCA_COLORS.GREEN_200 : ProjectConfig.ARCA_COLORS.WHITE,
				transition: isClickable ? 'transform 0.3s ease, background-color 0.3s ease-in-out' : 'none',
				transform: isClickable ? 'scale(1.03)' : 'none'
			}}
			bg={
				isTouched && isClickable
					? ProjectConfig.ARCA_COLORS.GREEN_200
					: ProjectConfig.ARCA_COLORS.WHITE
			}>
			<CardHeader
				display="flex"
				alignItems="start"
				justifyContent="space-between"
				p="24px 24px 0px 24px">
				<Heading fontFamily={ProjectConfig.FONTS.INTER} fontSize="16px" fontWeight="400">
					{heading}
				</Heading>
				<Box fontSize="20px">
					<InfoIconClick isVisible={infoIconVisible} text={infoIconText} />
				</Box>
			</CardHeader>
			<CardBody
				fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
				fontWeight="900"
				display="flex"
				flexDirection="column"
				alignItems="flex-start"
				justifyContent="flex-start"
				p="0px 15px 25px 20px"
				mx="3px">
				<Box>
					{typeof cardBody === 'string' ? (
						<Text fontSize={textSize || cardBodyTextSize}>{cardBody}</Text>
					) : (
						cardBody
					)}
				</Box>
				<Stack spacing={0}>
					{actionsCount !== undefined &&
						!isNaN(actionsCount) &&
						netAssetValue !== undefined &&
						!isNaN(netAssetValue) && (
							<>
								<Text fontFamily={ProjectConfig.FONTS.INTER} fontSize="14px" fontWeight="400">
									<Text as="span" textDecoration="underline">
										{translate('totalParticipationsActions')}:
									</Text>{' '}
									<br />
									{actionsCount}
								</Text>
								<Text fontFamily={ProjectConfig.FONTS.INTER} fontSize="14px" fontWeight="400">
									<Text as="span" textDecoration="underline">
										{translate('netAssetValue')}:
									</Text>{' '}
									<br />
									{netAssetValue}€
								</Text>
							</>
						)}
					{boundsCount !== undefined &&
						boundsCount !== 0 &&
						!isNaN(boundsCount) &&
						boundsAssetValue !== undefined &&
						!isNaN(boundsAssetValue) && (
							<>
								<Text fontFamily={ProjectConfig.FONTS.INTER} fontSize="14px" fontWeight="400">
									<Text as="span" textDecoration="underline">
										{translate('totalParticipationsBounds')}:
									</Text>{' '}
									{boundsCount}
								</Text>
								<Text fontFamily={ProjectConfig.FONTS.INTER} fontSize="14px" fontWeight="400">
									<Text as="span" textDecoration="underline">
										{translate('netAssetValue')}:
									</Text>{' '}
									{boundsAssetValue}€
								</Text>
							</>
						)}
				</Stack>
			</CardBody>
			{link && linkText && (
				<CardFooter>{link && <Link onClick={() => navigate(link)}>{linkText}</Link>}</CardFooter>
			)}
		</Card>
	);
};

export default BenUserCard;
