import { EmployeeContributionTypeEnum } from '../companies/interfaces/CompanyEppConfig';

const userCompleteOnboarding = async (
	token: string,
	contributionType: EmployeeContributionTypeEnum,
	pensionContribution: number | string,
	gender: string,
	name: string,
	firstSurname: string,
	lastSurname: string,
	nif: string,
	email: string,
	password: string,
	birthdate: string,
	closingPayrollDay?: number,
	agencyEmail?: string,
	fileData?: string
) => {
	const response = await fetch(`${process.env.REACT_APP_BASE_API}/users/complete-onboarding`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify({
			contributionType,
			pensionContribution: Number(pensionContribution),
			password,
			gender,
			name,
			firstSurname,
			lastSurname,
			nif,
			email,
			birthdate,
			closingPayrollDay,
			agencyEmail,
			fileData
		})
	});

	return response.json();
};

export default userCompleteOnboarding;
