import React from 'react';
import { Select } from '@chakra-ui/react';
import ProjectConfig from '../constants';

interface SelectCurrentYearProps {
	selectedYear: number;
	setSelectedYear: (year: number) => void;
}

const SelectCurrentYear: React.FC<SelectCurrentYearProps & { startYear?: number }> = ({
	selectedYear,
	setSelectedYear,
	startYear = new Date().getFullYear()
}) => {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	// Generar años desde el año inicial hasta el año actual
	const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => i + startYear);

	return (
		<Select
			borderRadius="20px"
			borderColor={ProjectConfig.ARCA_COLORS.GREEN_600}
			color={ProjectConfig.ARCA_COLORS.GREEN_600}
			variant="outline"
			fontWeight={'bold'}
			width={100}
			value={selectedYear}
			onChange={(e) => setSelectedYear(Number(e.target.value))}>
			{years.map((year) => (
				<option key={year} value={year}>
					{year}
				</option>
			))}
		</Select>
	);
};

export default SelectCurrentYear;
