import React, { useContext, useState, useEffect, lazy, Suspense } from 'react';
import { Box, Text, Stack, SimpleGrid, Image, Collapse, Card } from '@chakra-ui/react';
import ArcaSlider from '../Sliders/ArcaSlider';
import ArcaPrimaryButton from '../Buttons/ArcaPrimaryButton';
import ProjectConfig from '../../constants';
// Importamos las funciones reutilizables
import { calculateFutureValue } from '../../utils/calculateFutureValue';
import { calculateFuturePrice } from '../../utils/calculateFuturePrice';
import { formatArcaNumber } from '../../utils/formatArcaNumbers';
import { I18nContext } from '../../context/translations/TranslationsContext.context';
import LoadingViewSpinner from '../../components/ChakraUi/Spinners/LoadingViewSpinner';

// Import dynamically in useEffect
const LineChart = lazy(() =>
	import('react-chartjs-2').then((module) => ({ default: module.Line }))
);

interface InflationSimulatorProps {
	currentAge: number;
}

const InflationSimulator: React.FC<InflationSimulatorProps> = ({ currentAge }) => {
	const retirementAge = 67;
	const yearsToRetirement = retirementAge - currentAge;

	// Estados editables
	const [currentAmount, setCurrentAmount] = useState<number>(0);
	const [inflationRate, setInflationRate] = useState<number>(2.5);
	const [annualAddition, setAnnualAddition] = useState<number>(0);
	const [historicalReturn, setHistoricalReturn] = useState<number>(5);

	const [calculated, setCalculated] = useState<boolean>(false);
	const [chartInitialized, setChartInitialized] = useState<boolean>(false);

	useEffect(() => {
		// Register Chart.js components within useEffect
		const initializeChart = async () => {
			const {
				Chart,
				CategoryScale,
				LinearScale,
				PointElement,
				LineElement,
				Title,
				Tooltip,
				Legend
			} = await import('chart.js');

			Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

			setChartInitialized(true);
		};

		initializeChart();
	}, []);

	// Calculamos los valores acumulados nominales para cada año
	const nominalFutureValues = Array.from({ length: yearsToRetirement + 1 }, (_, index) =>
		calculateFutureValue(currentAmount, index, annualAddition / 12, historicalReturn)
	);

	// Calculamos los valores reales (ajustados por inflación) para cada año
	const inflationAdjustedValues = nominalFutureValues.map(
		(value, index) => value / Math.pow(1 + inflationRate / 100, index)
	);

	// Actualizamos las etiquetas para que muestren la edad correspondiente a cada año
	const labels = Array.from({ length: yearsToRetirement + 1 }, (_, index) =>
		(currentAge + index).toString()
	);

	const monthlyContribution = annualAddition / 12;

	// Cálculos reutilizando las funciones exportadas:
	// Valor nominal con crecimiento de inversión
	const nominalFutureValue = calculateFutureValue(
		currentAmount,
		yearsToRetirement,
		monthlyContribution,
		historicalReturn
	);

	// Ajustamos el valor nominal a términos reales (valor presente) usando la tasa de inflación
	const inflationAdjustedValue =
		nominalFutureValue / Math.pow(1 + inflationRate / 100, yearsToRetirement);

	// Diferencia que habría que compensar para tener el mismo poder adquisitivo
	const additionalAmountNeeded = nominalFutureValue - inflationAdjustedValue;

	// Actualizamos el gráfico para mostrar ambas evoluciones:
	const chartData = {
		labels,
		datasets: [
			{
				label: 'Valor nominal plan de pensiones (Inversión)',
				data: nominalFutureValues,
				borderColor: ProjectConfig.ARCA_COLORS.GREEN_300,
				backgroundColor: ProjectConfig.ARCA_COLORS.GREEN_300,
				fill: false
			},
			{
				label: 'Valor real plan de pensiones (Ajustado por Inflación)',
				data: inflationAdjustedValues,
				borderColor: ProjectConfig.ARCA_COLORS.PINK_300,
				backgroundColor: ProjectConfig.ARCA_COLORS.PINK_300,
				fill: false
			}
		]
	};

	const chartOptions = {
		layout: {
			padding: {
				right: 40,
				bottom: 30
			}
		},
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			datalabels: {
				clip: false,
				offset: -40,
				display: function (context: any) {
					const dataLabel = context.chart.data.labels[context.dataIndex];
					const lastIndex = context.dataset.data.length - 1;
					const targetAge = currentAge + 10;
					return context.dataIndex === lastIndex || dataLabel === targetAge.toString();
				},
				formatter: function (value: number) {
					return value > 0 ? formatArcaNumber(value.toFixed(0)) : '';
				}
			},
			legend: {
				position: 'top' as const
			},
			title: {
				display: true,
				text: `Evolución desde los ${currentAge} hasta los ${retirementAge}`
			},
			tooltip: {
				mode: 'index' as const,
				intersect: false,
				callbacks: {
					title: (tooltipItems: any) => {
						// Agrega "años" al label del eje X en el tooltip
						return tooltipItems[0].label + ' años';
					},
					label: (context: any) => {
						// Se obtiene el label de la serie y se formatea el valor sin decimales y con el símbolo €
						const datasetLabel = context.dataset.label || '';
						const value = Number(context.parsed.y).toFixed(0);
						return datasetLabel ? `${datasetLabel}: ${value} €` : `${value} €`;
					}
				}
			}
		}
	};

	// Precios actuales de commodities
	const milkToday = 1.0;
	const beerToday = 1.5;
	const gasolineToday = 1.4;

	// Cálculo del precio futuro de cada commodity usando calculateFuturePrice
	const milkAtRetirement = calculateFuturePrice(milkToday, yearsToRetirement, inflationRate);
	const beerAtRetirement = calculateFuturePrice(beerToday, yearsToRetirement, inflationRate);
	const gasolineAtRetirement = calculateFuturePrice(
		gasolineToday,
		yearsToRetirement,
		inflationRate
	);

	// Get the translation function from your context
	const {
		state: { translate }
	} = useContext(I18nContext);

	// Alternar entre ver la explicación y mostrar resultados
	const handleCalculate = () => {
		setCalculated(!calculated);
	};

	return (
		<Box p="4" width="100%" mx="auto">
			{/* Sección de explicación de la inflación, animada con Collapse */}
			<Collapse in={!calculated} animateOpacity>
				<>
					<Text fontSize="xl" fontWeight="bold" mb="4">
						{translate('simulator.inflation.title')}
					</Text>
					<Box mb="4">
						<Text fontSize="md" fontWeight="bold" mb="2">
							{translate('simulator.inflation.whatIs')}
						</Text>
						<Text fontSize="sm">{translate('simulator.inflation.explanation')}</Text>
						<Text fontSize="sm" mt="2">
							{translate('simulator.inflation.example')}
						</Text>
					</Box>
					{/* Sección de imágenes y otros contenidos */}
					<Box textAlign="center" mb="4">
						<SimpleGrid columns={3} spacing="4">
							<Image
								src="/imgs/simulators/cerveza.jpg"
								alt={translate('simulator.inflation.image.altBeer')}
								mx="auto"
								maxW={{ base: '75px', md: '100px' }}
								height="auto"
							/>
							<Image
								src="/imgs/simulators/cerveza.jpg"
								alt={translate('simulator.inflation.image.altBeer')}
								mx="auto"
								maxW={{ base: '75px', md: '100px' }}
								height="auto"
							/>
							<Image
								src="/imgs/simulators/cerveza.jpg"
								alt={translate('simulator.inflation.image.altBeer')}
								mx="auto"
								maxW={{ base: '75px', md: '100px' }}
								height="auto"
							/>
						</SimpleGrid>
						<Box position="relative" my="4">
							<Box as="hr" borderColor="black" />
							<SimpleGrid columns={3} spacing="4" position="absolute" top="-6px" left={0} right={0}>
								<Box
									w="12px"
									h="12px"
									bg="black"
									borderRadius="full"
									border="3px solid"
									borderColor={ProjectConfig.ARCA_COLORS.GREEN_300}
									mx="auto"
								/>
								<Box
									w="12px"
									h="12px"
									bg="black"
									borderRadius="full"
									border="3px solid"
									borderColor={ProjectConfig.ARCA_COLORS.GREEN_300}
									mx="auto"
								/>
								<Box
									w="12px"
									h="12px"
									bg="black"
									borderRadius="full"
									border="3px solid"
									borderColor={ProjectConfig.ARCA_COLORS.GREEN_300}
									mx="auto"
								/>
							</SimpleGrid>
						</Box>
						<SimpleGrid columns={3} spacing="4">
							<Card bg="red.100" p="2" borderRadius="md" shadow="md" size="sm">
								<Text textAlign="center" fontWeight="bold">
									2024: 1€
								</Text>
							</Card>
							<Card bg="red.100" p="2" borderRadius="md" shadow="md" size="sm">
								<Text textAlign="center" fontWeight="bold">
									2025: 1,10€ (+10%)
								</Text>
							</Card>
							<Card bg="red.100" p="2" borderRadius="md" shadow="md" size="sm">
								<Text textAlign="center" fontWeight="bold">
									2026: 1,21€ (+10%)
								</Text>
							</Card>
						</SimpleGrid>
					</Box>
				</>
			</Collapse>

			{/* Controles: se muestran siempre */}
			<Box>
				<Stack spacing="4">
					<SimpleGrid columns={3} spacing="8">
						<Text mb="1">
							{translate('simulator.inflation.currentAgeLabel')} <strong>{currentAge}</strong>
						</Text>
						<Text mb="1">
							{translate('simulator.inflation.retirementAgeLabel')} <strong>{retirementAge}</strong>
						</Text>
						<Text mb="1">
							{translate('simulator.inflation.yearsToRetirementLabel')}{' '}
							<strong>{yearsToRetirement}</strong>
						</Text>
					</SimpleGrid>
					<SimpleGrid columns={2} spacing="8">
						<Box>
							<Text mb="1">{translate('simulator.inflation.slider.privatePensionLabel')}</Text>
							<ArcaSlider
								value={currentAmount}
								onChange={setCurrentAmount}
								min={0}
								max={10000}
								step={50}
								unit="€"
							/>
							<Text>
								{translate('simulator.inflation.slider.valueLabel')}{' '}
								<Text
									as="span"
									mt={4}
									fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
									fontSize="xl"
									fontWeight="bold">
									{currentAmount} €
								</Text>
							</Text>
						</Box>
						<Box>
							<Text mb="1">{translate('simulator.inflation.slider.annualInflationLabel')}</Text>
							<ArcaSlider
								value={inflationRate}
								onChange={setInflationRate}
								min={-15}
								max={15}
								step={0.1}
								unit="%"
							/>
							<Text>
								{translate('simulator.inflation.slider.valueLabel')}{' '}
								<Text
									as="span"
									mt={4}
									fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
									fontSize="xl"
									fontWeight="bold">
									{inflationRate} %
								</Text>
							</Text>
						</Box>
						<Box>
							<Text mb="1">
								{translate('simulator.inflation.slider.additionalAnnualAdditionLabel')}
							</Text>
							<ArcaSlider
								value={annualAddition}
								onChange={setAnnualAddition}
								min={0}
								max={5000}
								step={50}
								unit="€"
							/>
							<Text>
								{translate('simulator.inflation.slider.valueLabel')}{' '}
								<Text
									as="span"
									mt={4}
									fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
									fontSize="xl"
									fontWeight="bold">
									{annualAddition} €
								</Text>
							</Text>
						</Box>
						<Box>
							<Text mb="1">{translate('simulator.inflation.slider.historicalReturnLabel')}</Text>
							<Text
								as="span"
								mt={4}
								fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
								fontSize="xl"
								fontWeight="bold">
								{historicalReturn} %
							</Text>
						</Box>
					</SimpleGrid>
					{/* Botón para alternar entre ver explicación y mostrar resultados */}
					<ArcaPrimaryButton onClick={handleCalculate}>
						{calculated
							? translate('simulator.inflation.button.seeExplanation')
							: translate('simulator.inflation.button.calculate')}
					</ArcaPrimaryButton>
				</Stack>
			</Box>

			{/* Resultados y gráfica: se muestran al pulsar el botón */}
			{calculated && (
				<Box mt="6">
					<Box>
						<Text fontWeight="bold" mb="2">
							{translate('simulator.inflation.resultsTitle')}
						</Text>
						<Text>
							{translate('simulator.inflation.results.nominalPrefix')} {historicalReturn}%:{' '}
							<Text
								as="span"
								mt={4}
								fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
								fontSize="xl"
								fontWeight="bold">
								{formatArcaNumber(nominalFutureValue.toFixed(2))}€
							</Text>
						</Text>
						<Text mt="2">
							{translate('simulator.inflation.results.inflationAdjustedPrefix')} {inflationRate}%:{' '}
							<Text
								as="span"
								mt={4}
								fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
								fontSize="lg"
								fontWeight="bold">
								{formatArcaNumber(inflationAdjustedValue.toFixed(2))}€
							</Text>
						</Text>
						<Text mt="2">
							{translate('simulator.inflation.results.additionalAmountPrefix')}{' '}
							<Text
								as="span"
								mt={4}
								fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
								fontSize="lg"
								fontWeight="bold">
								{formatArcaNumber(additionalAmountNeeded.toFixed(2))}€
							</Text>
						</Text>
					</Box>
					<Box mt="6">
						<Text fontWeight="bold" mb="2">
							{translate('simulator.inflation.evolutionTitle')}
						</Text>
						{/* Chart container with Suspense for dynamic loading */}
						<Box maxW="600px" height="400px" mx="auto">
							{chartInitialized ? (
								<Suspense fallback={<LoadingViewSpinner />}>
									<LineChart data={chartData} options={chartOptions} />
								</Suspense>
							) : (
								<LoadingViewSpinner />
							)}
						</Box>
					</Box>
				</Box>
			)}

			{/* Sección de comparativa de precios */}
			{calculated && (
				<Box mt="6" textAlign="center">
					<Text fontWeight="bold" mb="2">
						{translate('simulator.inflation.priceComparisonTitle')}
					</Text>
					<Text>
						{`${translate('simulator.inflation.priceComparison.milk')}: hoy ${milkToday.toFixed(2)}€ vs. a los ${retirementAge} ${translate('simulator.inflation.priceComparison.yearsUnit')}: ${milkAtRetirement.toFixed(2)}€`}
					</Text>
					<Text>
						{`${translate('simulator.inflation.priceComparison.beer')}: hoy ${beerToday.toFixed(2)}€ vs. a los ${retirementAge} ${translate('simulator.inflation.priceComparison.yearsUnit')}: ${beerAtRetirement.toFixed(2)}€`}
					</Text>
					<Text>
						{`${translate('simulator.inflation.priceComparison.gasoline')}: hoy ${gasolineToday.toFixed(2)}€ vs. a los ${retirementAge} ${translate('simulator.inflation.priceComparison.yearsUnit')}: ${gasolineAtRetirement.toFixed(2)}€`}
					</Text>
				</Box>
			)}
		</Box>
	);
};

export default InflationSimulator;
