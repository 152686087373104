import { Box, Link, Text, Flex, Image, Stack } from '@chakra-ui/react';

interface ConfidenceBoosterProps {
	haveLifeCyclesPlan: boolean;
}

const ConfidenceBoosterComponent: React.FC<ConfidenceBoosterProps> = ({ haveLifeCyclesPlan }) => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center" px={4}>
			<Text fontSize={['18px', '20px', '22px']} fontWeight="500" mb={4} textAlign="center">
				Entidades colaboradoras
			</Text>

			<Stack
				direction={['column', 'column', 'column']}
				spacing={[4, 5, 6]}
				align="center"
				justify="center"
				my={[3, 4, 5]}
				w="100%">
				<Image
					src="/imgs/cecabank-logo.png"
					alt="Cecabank"
					maxW={['150px', '140px', '180px']}
					h="auto"
					w="100%"
					objectFit="contain"
				/>

				<Image
					src="/imgs/caser-helvetia.png"
					alt="Caser Helvetia"
					maxW={['130px', '120px', '150px']}
					h="auto"
					w="auto"
					objectFit="contain"
				/>
			</Stack>

			<Text fontSize={['14px', '15px', '16px']} maxWidth="600px" textAlign="center" mb={3}>
				Tu plan de pensiones está gestionado por Arca y Caser Pensiones, asesorado por Indexa
				Capital y depositado en Cecabank
			</Text>

			<Text fontSize={['14px', '15px', '16px']} maxWidth="600px" textAlign="center" mb={3}>
				Puedes buscar más detalles en{' '}
				<Link
					href="https://dgsfp.mineco.gob.es/"
					textDecoration="underline"
					target="_blank"
					rel="noopener noreferrer">
					https://dgsfp.mineco.gob.es/
				</Link>
				, en el apartado de "Registros públicos" y buscar por "Planes de Pensiones" o "Fondos de
				Pensiones" con los siguientes datos:
			</Text>

			<Text
				fontSize={['14px', '15px', '16px']}
				maxWidth="600px"
				textAlign="center"
				textDecoration="underline"
				mb={2}>
				Acciones: Fondo F1831 Plan N5138
			</Text>

			{haveLifeCyclesPlan && (
				<Text
					fontSize={['14px', '15px', '16px']}
					maxWidth="600px"
					textAlign="center"
					textDecoration="underline"
					mb={2}>
					Bonos: Fondo F1832 Plan N5137
				</Text>
			)}

			<Link
				href="https://www.bde.es/webbe/es/estadisticas/otras-clasificaciones/clasificacion-entidades/listas-instituciones-financieras/listas-fondos-pensiones-pais/lista-pf-es.html#"
				textDecoration="underline"
				target="_blank"
				rel="noopener noreferrer"
				fontSize={['14px', '15px', '16px']}
				textAlign="center"
				mt={2}>
				Listado de fondos de pensiones de España (Banco de España)
			</Link>
		</Box>
	);
};

export default ConfidenceBoosterComponent;
