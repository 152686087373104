import React, { useContext, useState } from 'react';
import { Box, Text, Spinner } from '@chakra-ui/react';
import ArcaPrimaryButton from '../Buttons/ArcaPrimaryButton';
import { triggerMetricsWaveEvent } from '../../metricswave';
import { MetricswaveEvents, MetricswaveEventsAndParams } from '../../metricswave/MetricswaveEnums';
import { ArcaContext } from '../../context/ArcaContext.context';

const PayrollSimulator: React.FC = () => {
	const { userId } = useContext(ArcaContext);
	const [isLoading, setIsLoading] = useState(false);

	const handleClick = () => {
		setIsLoading(true);
		triggerMetricsWaveEvent(
			MetricswaveEventsAndParams[MetricswaveEvents.SIMULATORS_PAYROLL_SOON].event,
			userId ?? '',
			{}
		);
		// Simulamos un retardo para mostrar el loader (1 segundo)
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	return (
		<Box p="4">
			<Text fontSize="xl" fontWeight="bold" mb="2">
				Simulador de Nóminas
			</Text>
			<Text mb="4">Esta funcionalidad estará disponible pronto.</Text>
			<ArcaPrimaryButton onClick={handleClick} isLoading={isLoading}>
				{isLoading ? <Spinner size="sm" color="white" /> : 'Avísame cuando esté disponible'}
			</ArcaPrimaryButton>
		</Box>
	);
};

export default PayrollSimulator;
