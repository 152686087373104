import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
	FormControl,
	FormLabel,
	Box,
	Tag,
	TagLabel,
	TagCloseButton,
	Text
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import { useContext, useState } from 'react';
import ProjectConfig from '../../../constants';
import ArcaPrimaryButton from '../../Buttons/ArcaPrimaryButton';
import ArcaSecondaryButton from '../../Buttons/ArcaSecondaryButton';

interface GenericModalProps {
	isOpen: boolean;
	onClose: () => void;
	message: string;
	isError: boolean;
	useLink?: boolean;
	link?: string;
	onAction?: (inputString?: string) => void;
	onActionNotDisabled?: boolean;
	closeModalButton?: boolean;
	modalTitle?: string;
	modalSubtitle?: string;
	inputString?: boolean;
	maxWidth?: string;
}

const GenericModal: React.FC<GenericModalProps> = ({
	isOpen,
	onClose,
	message,
	isError,
	useLink,
	link,
	onAction,
	onActionNotDisabled,
	closeModalButton,
	modalTitle,
	modalSubtitle,
	inputString,
	maxWidth
}: GenericModalProps) => {
	const {
		state: { translate }
	} = useContext(I18nContext);
	const [stringInput, setStringInput] = useState('');
	const [stringsByComma, setStringsByComma] = useState<string[]>([]);

	const navigate = useNavigate();
	const title =
		modalTitle ||
		(isError
			? `${translate('general.genericModal.errorMessage')}`
			: `${translate('general.genericModal.successMessage')}`);
	const subtitle = modalSubtitle || message;

	const handleNavigate = () => {
		navigate(`${link}`);
	};

	const handleAction = () => {
		if (onAction) {
			onAction(stringsByComma.join(', '));
			setStringsByComma([]);
			setStringInput('');
			onClose();
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, keyPressed?: string) => {
		const value = e.target.value;
		setStringInput(value);

		if (/[,\s\n]/.test(value) || keyPressed === 'Enter') {
			const newStrings = value
				.split(/[,\s\n]+/)
				.filter((string) => string.trim() !== '' && !stringsByComma.includes(string.trim()));
			setStringsByComma((prevStrings) => [...prevStrings, ...newStrings]);
			setStringInput('');
		}
	};

	const handleStringRemove = (stringToRemove: string) => {
		setStringsByComma((prevStrings) => prevStrings.filter((string) => string !== stringToRemove));
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent {...(maxWidth ? { maxW: maxWidth } : {})}>
				{/* Ajustar el ancho del modal */}
				<ModalHeader textAlign="center">{title}</ModalHeader>
				<ModalBody>{message}</ModalBody>
				{useLink && (
					<ModalBody>
						<ArcaPrimaryButton onClick={handleNavigate}>
							{translate('general.genericModal.goTo')}
						</ArcaPrimaryButton>
					</ModalBody>
				)}
				{inputString && (
					<ModalBody>
						<FormControl>
							<FormLabel>{subtitle}</FormLabel>
							<Input
								placeholder="example1@example.com, example2@example.com"
								value={stringInput}
								onChange={handleInputChange}
								onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
										handleInputChange(e as any, e.key);
									}
								}}
							/>
							<Box mt={2}>
								{stringsByComma.map((string) => (
									<Tag
										key={string}
										borderRadius="full"
										variant="solid"
										color={ProjectConfig.ARCA_COLORS.BLACK}
										bg={ProjectConfig.ARCA_COLORS.PINK_200}
										mr={1}
										mt={1}
										size="sm">
										<TagLabel>{string}</TagLabel>
										<TagCloseButton onClick={() => handleStringRemove(string)} />
									</Tag>
								))}
							</Box>
							<Text mt={2} align="right" fontWeight="bold">
								{translate('admin.staff.totalEmails')}:{stringsByComma.length}
							</Text>
						</FormControl>
					</ModalBody>
				)}
				<ModalFooter gap={2}>
					{closeModalButton && (
						<ArcaSecondaryButton
							color={isError ? ProjectConfig.ARCA_COLORS.WHITE : ProjectConfig.ARCA_COLORS.WHITE}
							bg={isError ? ProjectConfig.ARCA_COLORS.RED_500 : ProjectConfig.ARCA_COLORS.PINK_200}
							onClick={onClose}>
							{onActionNotDisabled
								? translate('general.genericModal.closeThinkAgain')
								: translate('general.genericModal.close')}
						</ArcaSecondaryButton>
					)}
					{onAction && (
						<ArcaPrimaryButton
							onClick={handleAction}
							isDisabled={onActionNotDisabled ? false : stringsByComma.length === 0}>
							{onActionNotDisabled
								? translate('general.genericModal.confirmCancelAdhesion')
								: translate('general.genericModal.accept')}
						</ArcaPrimaryButton>
					)}
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default GenericModal;
