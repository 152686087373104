import React from 'react';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, Skeleton, Box } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import ProjectConfig from '../../constants';
import { ContributionFromEnum } from '../../api/users/interfaces/ContributionData';
import Pagination from '../ChakraUi/Pagination';
import { ContributionData } from '../../api/calculators/getCalculatorsUserAportations';
import { CurrentUserI } from '../../api/users/interfaces/Users ';

interface DesktopContributionsTableProps {
	rows: any[];
	isFreelance: boolean;
	isPageLoading: boolean;
	expandedRows: Record<string, boolean>;
	toggleRow: (month: string) => void;
	currentPage: number;
	totalPages: number;
	handlePageChange: (page: number) => void;
	translate: (key: string) => string;
	formatArcaNumber: (num: number) => string;
	currentUser: CurrentUserI;
}

const DesktopContributionsTable: React.FC<DesktopContributionsTableProps> = ({
	rows,
	isFreelance,
	isPageLoading,
	expandedRows,
	toggleRow,
	currentPage,
	totalPages,
	handlePageChange,
	translate,
	formatArcaNumber,
	currentUser
}) => {
	// Column size adjustments to prevent horizontal scroll
	const dateColumnWidth = '100px';
	const valueColumnWidth = '110px';
	const statusColumnWidth = '130px';

	return (
		<Table size="sm">
			<Thead>
				<Tr>
					<Th
						color={ProjectConfig.ARCA_COLORS.GREEN_800}
						fontSize="14px"
						borderBottomWidth="2px"
						borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
						textAlign="center"
						width={dateColumnWidth}>
						{translate('user.myContributions.contributionsTableMonth')}
					</Th>
					{!isFreelance && (
						<Th
							color={ProjectConfig.ARCA_COLORS.GREEN_800}
							fontSize="14px"
							borderBottomWidth="2px"
							borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
							textAlign="center"
							width={valueColumnWidth}>
							{currentUser?.user?.companyName}
							{translate('user.myContributions.contributionsTablePromoterContributions')}
						</Th>
					)}
					<Th
						color={ProjectConfig.ARCA_COLORS.GREEN_800}
						fontSize="14px"
						borderBottomWidth="2px"
						borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
						textAlign="center"
						width={valueColumnWidth}>
						{currentUser?.user?.name}
						{translate('user.myContributions.contributionsTableParticipantContributions')}
					</Th>
					{!isFreelance && (
						<Th
							color={ProjectConfig.ARCA_COLORS.GREEN_800}
							fontSize="14px"
							borderBottomWidth="2px"
							borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
							textAlign="center"
							width={valueColumnWidth}>
							{translate('user.myContributions.contributionsTableTotalAmount')}
						</Th>
					)}
					<Th
						color={ProjectConfig.ARCA_COLORS.GREEN_800}
						fontSize="14px"
						borderBottomWidth="2px"
						borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
						textAlign="center"
						width={statusColumnWidth}>
						{translate('user.myContributions.contributionsTableStatusContribution')}
					</Th>
					<Th
						color={ProjectConfig.ARCA_COLORS.GREEN_800}
						fontSize="14px"
						borderBottomWidth="2px"
						borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
						textAlign="center"
						width={valueColumnWidth}>
						{translate('user.myContributions.liquidativeValue')}
					</Th>
					<Th
						color={ProjectConfig.ARCA_COLORS.GREEN_800}
						fontSize="14px"
						borderBottomWidth="2px"
						borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
						textAlign="center"
						width={valueColumnWidth}>
						{translate('user.myContributions.participations')}
					</Th>
				</Tr>
			</Thead>

			<Tbody>
				{isPageLoading
					? Array.from({ length: 5 }).map((_, index) => (
							<Tr key={index}>
								<Td py="8px" borderColor="gray.300" textAlign="center">
									<Skeleton height="20px" />
								</Td>
								{!isFreelance && (
									<Td py="8px" borderColor="gray.300" textAlign="center">
										<Skeleton height="20px" />
									</Td>
								)}
								<Td py="8px" borderColor="gray.300" textAlign="center">
									<Skeleton height="20px" />
								</Td>
								{!isFreelance && (
									<Td py="8px" borderColor="gray.300" textAlign="center">
										<Skeleton height="20px" />
									</Td>
								)}
								<Td py="8px" borderColor="gray.300" textAlign="center">
									<Skeleton height="20px" />
								</Td>
								<Td py="8px" borderColor="gray.300" textAlign="center">
									<Skeleton height="20px" />
								</Td>
								<Td py="8px" borderColor="gray.300" textAlign="center">
									<Skeleton height="20px" />
								</Td>
							</Tr>
						))
					: rows.map((row, index) => (
							<React.Fragment key={index}>
								<Tr>
									<Td py="8px" borderColor="gray.300" textAlign="center">
										<span style={{ marginRight: '5px' }}>{row.month}</span>
										<Box
											as="button"
											w="24px"
											h="24px"
											display="flex"
											justifyContent="center"
											alignItems="center"
											onClick={() => toggleRow(row.month)}
											aria-label={expandedRows[row.month] ? 'Collapse' : 'Expand'}
											bg={
												expandedRows[row.month]
													? ProjectConfig.ARCA_COLORS.PINK_500
													: ProjectConfig.ARCA_COLORS.PINK_300
											}
											color="white"
											borderRadius="50%"
											border="none"
											cursor="pointer"
											p={0}
											_hover={{
												bg: expandedRows[row.month]
													? ProjectConfig.ARCA_COLORS.PINK_600
													: ProjectConfig.ARCA_COLORS.PINK_400,
												transform: 'scale(1.05)'
											}}
											transition="all 0.2s">
											{expandedRows[row.month] ? (
												<ChevronDownIcon w="14px" h="14px" />
											) : (
												<ChevronRightIcon w="14px" h="14px" />
											)}
										</Box>
									</Td>
									{!isFreelance && (
										<Td py="8px" borderColor="gray.300" textAlign="center">
											{row.companyContribution}
										</Td>
									)}
									<Td py="8px" borderColor="gray.300" textAlign="center">
										{row.participantContribution}
									</Td>
									{!isFreelance && (
										<Td py="8px" borderColor="gray.300" textAlign="center">
											{row.totalContribution}
										</Td>
									)}
									<Td py="8px" borderColor="gray.300" textAlign="center">
										{row.contributionStatus}
									</Td>
									<Td py="8px" borderColor="gray.300" textAlign="center">
										{'-'}
									</Td>
									<Td py="8px" borderColor="gray.300" textAlign="center">
										{'-'}
									</Td>
								</Tr>
								{/* Renderizar filas adicionales para cada aportación */}
								{expandedRows[row.month] &&
									row.companyOrParticipantContributions.map(
										(contribution: ContributionData, idx: number) => (
											<Tr key={`contribution-${index}-${idx}`} bg="gray.100">
												<Td py="8px" borderColor="gray.300" textAlign="center" fontSize="xs">
													{contribution.dateContribution
														? new Date(contribution.dateContribution).toLocaleDateString()
														: 'N/A'}
												</Td>
												<Td py="8px" borderColor="gray.300" textAlign="center" fontSize="xs">
													{contribution.typeContribution === ContributionFromEnum.COMPANY
														? `${formatArcaNumber(contribution.contributionAmount)} €`
														: '-'}
												</Td>
												<Td py="8px" borderColor="gray.300" textAlign="center" fontSize="xs">
													{contribution.typeContribution === ContributionFromEnum.PARTICIPANT
														? `${formatArcaNumber(contribution.contributionAmount)} €`
														: '-'}
												</Td>
												<Td py="8px" borderColor="gray.300" textAlign="center" fontSize="xs">
													{contribution.contributionAmount
														? `${formatArcaNumber(contribution.contributionAmount)} €`
														: '-'}
												</Td>
												<Td py="8px" borderColor="gray.300" textAlign="center" fontSize="xs">
													{contribution.statusContribution
														? translate(
																`user.myContributions.contributionsTableStatus${contribution.statusContribution}`
															)
														: 'N/A'}
												</Td>
												<Td py="8px" borderColor="gray.300" textAlign="center" fontSize="xs">
													{contribution.liquidationValue} €
												</Td>
												<Td py="8px" borderColor="gray.300" textAlign="center" fontSize="xs">
													{contribution.sharesBought}
												</Td>
											</Tr>
										)
									)}
							</React.Fragment>
						))}
			</Tbody>

			<Tfoot>
				<Tr>
					<Td colSpan={9} textAlign="center" pb="5px">
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</Td>
				</Tr>
			</Tfoot>
		</Table>
	);
};

export default DesktopContributionsTable;
