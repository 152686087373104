import React from 'react';
import { Box, Flex, Text, VStack, Skeleton, Divider } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import GrossSalaryInputComponent from '../GrossSalaryInputComponent';
import ProjectConfig from '../../constants';

interface User {
	id: string;
	email: string;
	status: string;
	completedOnboarding: boolean;
	completedProfile: boolean;
	grossAnnualSalary?: number;
}

interface UserWithIcon extends User {
	statusIcon: string;
}

interface UserInputCalculation {
	user: UserWithIcon;
	totalContributionsByParticipant: number;
	totalContributionsByCompany: number;
	totalEconomicsRightsGenerated: number;
	savingsByContingencesReduction: number;
	nextParticipantContribution: number;
	nextPromoterContribution: number;
}

interface MobileStaffTableProps {
	usersInputCalculations: UserInputCalculation[];
	isPageLoading: boolean;
	expandedRows: { [key: string]: boolean };
	toggleRow: (userId: string) => void;
	formatArcaNumber: (number: number) => string;
	selectedYear: number;
	translate: (key: string) => string;
	handleSalaryInform: () => void;
	token: string;
}

const MobileStaffTable: React.FC<MobileStaffTableProps> = ({
	usersInputCalculations,
	isPageLoading,
	expandedRows,
	toggleRow,
	formatArcaNumber,
	selectedYear,
	translate,
	handleSalaryInform,
	token
}) => {
	if (isPageLoading) {
		return (
			<VStack spacing={4} width="100%">
				{Array.from({ length: 5 }).map((_, index) => (
					<Skeleton key={index} height="100px" width="100%" borderRadius="md" />
				))}
			</VStack>
		);
	}

	return (
		<VStack spacing={4} width="100%" align="stretch">
			{usersInputCalculations.map((userInputCalculation, index) => {
				const userId = userInputCalculation?.user?.id;
				const isExpanded = expandedRows[userId] || false;
				const userActiveAndOnboard =
					userInputCalculation?.user?.status === 'active' &&
					userInputCalculation?.user?.completedOnboarding &&
					userInputCalculation?.user?.completedProfile;

				return (
					<Box
						key={index}
						borderWidth="1px"
						borderRadius="md"
						p={3}
						borderColor="gray.200"
						bg="white">
						<Flex justify="space-between" align="center">
							<VStack align="start" spacing={1}>
								<Text fontWeight="bold" fontSize="sm">
									{userInputCalculation?.user?.email?.length > 24
										? `${userInputCalculation.user.email.substring(0, 24)}...`
										: userInputCalculation?.user?.email}
								</Text>
								<Flex align="center">
									<Text fontSize="xs" color="gray.600" mr={2}>
										{translate('admin.staff.userAdherentAndContributing')}:
									</Text>
									{userInputCalculation?.user?.statusIcon}
								</Flex>
							</VStack>
							<Box
								as="button"
								w="24px"
								h="24px"
								display="flex"
								justifyContent="center"
								alignItems="center"
								onClick={() => toggleRow(userId)}
								aria-label={isExpanded ? 'Collapse' : 'Expand'}
								bg={
									isExpanded
										? ProjectConfig.ARCA_COLORS.PINK_500
										: ProjectConfig.ARCA_COLORS.PINK_300
								}
								color="white"
								borderRadius="50%"
								border="none"
								cursor="pointer"
								p={0}
								_hover={{
									bg: isExpanded
										? ProjectConfig.ARCA_COLORS.PINK_600
										: ProjectConfig.ARCA_COLORS.PINK_400,
									transform: 'scale(1.05)'
								}}
								transition="all 0.2s">
								{isExpanded ? (
									<ChevronDownIcon w="14px" h="14px" />
								) : (
									<ChevronRightIcon w="14px" h="14px" />
								)}
							</Box>
						</Flex>

						{isExpanded && (
							<Box mt={4}>
								<Divider mb={3} />

								<VStack spacing={3} align="stretch">
									<Flex justify="space-between">
										<Text fontSize="xs" color="gray.600">
											{translate('admin.staff.totalAported') + ' ' + selectedYear}:
										</Text>
										<Text fontSize="sm" fontWeight="medium">
											{`${formatArcaNumber(userInputCalculation.totalContributionsByParticipant)} €`}
										</Text>
									</Flex>

									<Flex justify="space-between">
										<Text fontSize="xs" color="gray.600">
											{translate('admin.staff.totalContributed') + ' ' + selectedYear}:
										</Text>
										<Text fontSize="sm" fontWeight="medium">
											{`${formatArcaNumber(userInputCalculation.totalContributionsByCompany)} €`}
										</Text>
									</Flex>

									<Flex justify="space-between">
										<Text fontSize="xs" color="gray.600">
											{translate('admin.staff.generatedEconomicalRights')}:
										</Text>
										<Text fontSize="sm" fontWeight="medium">
											{`${formatArcaNumber(userInputCalculation.totalEconomicsRightsGenerated)} €`}
										</Text>
									</Flex>

									<Flex justify="space-between">
										<Text fontSize="xs" color="gray.600">
											{translate('admin.staff.generatedSalaryByReduction') + ' ' + selectedYear}:
										</Text>
										<Text fontSize="sm" fontWeight="medium">
											{`${formatArcaNumber(userInputCalculation.savingsByContingencesReduction)} €`}
										</Text>
									</Flex>

									<Flex justify="space-between">
										<Text fontSize="xs" color="gray.600">
											{translate('admin.staff.nextParticipantContribution')}:
										</Text>
										<Text fontSize="sm" fontWeight="medium">
											{userActiveAndOnboard
												? `${formatArcaNumber(userInputCalculation?.nextParticipantContribution)} €`
												: '-'}
										</Text>
									</Flex>

									<Flex justify="space-between">
										<Text fontSize="xs" color="gray.600">
											{translate('admin.staff.nextPromoterContribution')}:
										</Text>
										<Text fontSize="sm" fontWeight="medium">
											{userActiveAndOnboard
												? `${formatArcaNumber(Number(userInputCalculation?.nextPromoterContribution))} €`
												: '-'}
										</Text>
									</Flex>

									<Flex justify="space-between" align="center">
										<Text fontSize="xs" color="gray.600">
											{translate('admin.staff.annualSalary')}:
										</Text>
										{userInputCalculation?.user?.grossAnnualSalary ? (
											<Text fontSize="sm" fontWeight="medium">
												{`${formatArcaNumber(userInputCalculation?.user?.grossAnnualSalary)} €`}
											</Text>
										) : (
											<Box width="150px">
												<GrossSalaryInputComponent
													token={token}
													onInform={() => {
														handleSalaryInform();
													}}
													userId={userInputCalculation?.user?.id}
												/>
											</Box>
										)}
									</Flex>
								</VStack>
							</Box>
						)}
					</Box>
				);
			})}
		</VStack>
	);
};

export default MobileStaffTable;
