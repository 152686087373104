import React from 'react';
import { Box, VStack, HStack, Text, Heading, Divider, Grid, GridItem } from '@chakra-ui/react';
import { ChevronRightIcon, EmailIcon, InfoIcon, WarningIcon } from '@chakra-ui/icons';
import ArcaPrimaryButton from './Buttons/ArcaPrimaryButton';
import ArcaSecondaryButton from './Buttons/ArcaSecondaryButton';
import ArcaGhostButton from './Buttons/ArcaGhostButton';
import ArcaLinkButton from './Buttons/ArcaLinkButton';
import ArcaDestructiveButton from './Buttons/ArcaDestructiveButton';
import ProjectConfig from '../constants';

const ButtonShowcase: React.FC = () => {
	const dummyAction = () => console.log('Button clicked');

	return (
		<Box p={8} bg="gray.50" minH="100vh">
			<Heading mb={8} size="xl" textAlign="center">
				Arca Button Components Showcase
			</Heading>

			{/* Primary Buttons */}
			<Box mb={10}>
				<Heading size="md" mb={4}>
					Primary Buttons
				</Heading>
				<Divider mb={6} />

				<Grid templateColumns="repeat(3, 1fr)" gap={6}>
					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Default Primary</Text>
							<ArcaPrimaryButton onClick={dummyAction}>Default Primary</ArcaPrimaryButton>
						</VStack>
					</GridItem>

					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Green Primary</Text>
							<ArcaPrimaryButton
								onClick={dummyAction}
								color={ProjectConfig.ARCA_COLORS.WHITE}
								backgroundColor={ProjectConfig.ARCA_COLORS.GREEN_500}
								_hover={{
									bg: ProjectConfig.ARCA_COLORS.GREEN_600,
									color: ProjectConfig.ARCA_COLORS.WHITE
								}}>
								Green Primary
							</ArcaPrimaryButton>
						</VStack>
					</GridItem>

					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">With Icon</Text>
							<ArcaPrimaryButton
								onClick={dummyAction}
								icon="/imgs/ICONO_RRSS.png" // Replace with actual icon path
							>
								With Icon
							</ArcaPrimaryButton>
						</VStack>
					</GridItem>
				</Grid>
			</Box>

			{/* Secondary Buttons */}
			<Box mb={10}>
				<Heading size="md" mb={4}>
					Secondary Buttons
				</Heading>
				<Divider mb={6} />

				<Grid templateColumns="repeat(3, 1fr)" gap={6}>
					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Default Secondary</Text>
							<ArcaSecondaryButton onClick={dummyAction}>Default Secondary</ArcaSecondaryButton>
						</VStack>
					</GridItem>

					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Green Secondary</Text>
							<ArcaSecondaryButton
								onClick={dummyAction}
								color={ProjectConfig.ARCA_COLORS.GREEN_600}
								backgroundColor={ProjectConfig.ARCA_COLORS.GREEN_100}
								_hover={{
									bg: ProjectConfig.ARCA_COLORS.GREEN_200,
									color: ProjectConfig.ARCA_COLORS.GREEN_800
								}}>
								Green Secondary
							</ArcaSecondaryButton>
						</VStack>
					</GridItem>

					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Dark Secondary</Text>
							<ArcaSecondaryButton
								onClick={dummyAction}
								color={ProjectConfig.ARCA_COLORS.WHITE}
								backgroundColor={ProjectConfig.ARCA_COLORS.BLACK}
								_hover={{
									bg: 'gray.700',
									color: ProjectConfig.ARCA_COLORS.WHITE
								}}>
								Dark Secondary
							</ArcaSecondaryButton>
						</VStack>
					</GridItem>
				</Grid>
			</Box>

			{/* Ghost Buttons */}
			<Box mb={10}>
				<Heading size="md" mb={4}>
					Ghost Buttons
				</Heading>
				<Divider mb={6} />

				<Grid templateColumns="repeat(3, 1fr)" gap={6}>
					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Default Ghost</Text>
							<ArcaGhostButton onClick={dummyAction}>Default Ghost</ArcaGhostButton>
						</VStack>
					</GridItem>

					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Green Ghost</Text>
							<ArcaGhostButton
								onClick={dummyAction}
								color={ProjectConfig.ARCA_COLORS.GREEN_600}
								_hover={{
									bg: ProjectConfig.ARCA_COLORS.GREEN_100,
									color: ProjectConfig.ARCA_COLORS.GREEN_700
								}}
								_active={{
									bg: ProjectConfig.ARCA_COLORS.GREEN_200,
									color: ProjectConfig.ARCA_COLORS.GREEN_800
								}}>
								Green Ghost
							</ArcaGhostButton>
						</VStack>
					</GridItem>

					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">With Icon</Text>
							<ArcaGhostButton onClick={dummyAction} icon={<InfoIcon />} isLoading={false}>
								With Icon
							</ArcaGhostButton>
						</VStack>
					</GridItem>
				</Grid>
			</Box>

			{/* Link Buttons */}
			<Box mb={10}>
				<Heading size="md" mb={4}>
					Link Buttons
				</Heading>
				<Divider mb={6} />

				<Grid templateColumns="repeat(3, 1fr)" gap={6}>
					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Default Link</Text>
							<ArcaLinkButton onClick={dummyAction}>Default Link</ArcaLinkButton>
						</VStack>
					</GridItem>

					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Green Link</Text>
							<ArcaLinkButton
								onClick={dummyAction}
								color={ProjectConfig.ARCA_COLORS.GREEN_600}
								_hover={{
									textDecoration: 'underline',
									backgroundColor: 'transparent'
								}}>
								Green Link
							</ArcaLinkButton>
						</VStack>
					</GridItem>

					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">External Link</Text>
							<ArcaLinkButton
								href="https://example.com"
								isExternal={true}
								icon={<ChevronRightIcon />}>
								External Link
							</ArcaLinkButton>
						</VStack>
					</GridItem>
				</Grid>
			</Box>

			{/* Destructive Buttons */}
			<Box mb={10}>
				<Heading size="md" mb={4}>
					Destructive Buttons
				</Heading>
				<Divider mb={6} />

				<Grid templateColumns="repeat(3, 1fr)" gap={6}>
					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Default Destructive</Text>
							<ArcaDestructiveButton onClick={dummyAction}>
								Default Destructive
							</ArcaDestructiveButton>
						</VStack>
					</GridItem>

					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">With Loading State</Text>
							<ArcaDestructiveButton onClick={dummyAction} isLoading={true}>
								Loading State
							</ArcaDestructiveButton>
						</VStack>
					</GridItem>

					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">With Icon</Text>
							<ArcaDestructiveButton onClick={dummyAction} icon={<WarningIcon />}>
								With Icon
							</ArcaDestructiveButton>
						</VStack>
					</GridItem>
				</Grid>
			</Box>

			{/* Additional States */}
			<Box>
				<Heading size="md" mb={4}>
					Button States
				</Heading>
				<Divider mb={6} />

				<Grid templateColumns="repeat(4, 1fr)" gap={6}>
					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Disabled</Text>
							<ArcaPrimaryButton isDisabled={true}>Disabled</ArcaPrimaryButton>
						</VStack>
					</GridItem>

					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Loading</Text>
							<ArcaGhostButton isLoading={true}>Loading</ArcaGhostButton>
						</VStack>
					</GridItem>

					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Small Size</Text>
							<ArcaSecondaryButton size="sm">Small Size</ArcaSecondaryButton>
						</VStack>
					</GridItem>

					<GridItem>
						<VStack align="stretch" spacing={3} p={4} bg="white" borderRadius="md" boxShadow="sm">
							<Text fontWeight="bold">Large Size</Text>
							<ArcaLinkButton size="lg">Large Size</ArcaLinkButton>
						</VStack>
					</GridItem>
				</Grid>
			</Box>
		</Box>
	);
};

export default ButtonShowcase;
