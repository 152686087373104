import { GenericBackendResponse } from '../genericBackendResponse';

export interface FeatureFlagStatusOutputDto {
	isActive: boolean;
	userHasSeenFeature: boolean;
}

export type CheckFeatureFlagByNameResponse = GenericBackendResponse<FeatureFlagStatusOutputDto>;

const checkFeatureFlagByName = async (
	token: string,
	companyId: string,
	userId: string,
	featureFlagName: string
): Promise<CheckFeatureFlagByNameResponse> => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/feature-flags/check/${featureFlagName}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify({ companyId, userId, featureFlagName })
		}
	);

	if (!response.ok) {
		throw new Error('Network response was not ok');
	}

	const result: CheckFeatureFlagByNameResponse = await response.json();
	return result;
};

export default checkFeatureFlagByName;
