/**
 * Calcula el precio futuro de un producto teniendo en cuenta la inflación anual compuesta.
 *
 * @param currentPrice - Precio actual del producto.
 * @param yearsToRetirement - Número de años hasta el momento considerado (por ejemplo, hasta la jubilación).
 * @param inflationRate - Tasa de inflación anual en porcentaje (por ejemplo, 1 para 1%).
 * @returns El precio futuro del producto.
 *
 * Ejemplo:
 * // Para un producto que cuesta actualmente 1€ y considerando una inflación del 1% anual, en 41 años costará aproximadamente 1.50€
 * const futurePrice = calculateFuturePrice(1, 41, 1);
 * console.log(futurePrice); // ~1.5
 */
export const calculateFuturePrice = (
	currentPrice: number,
	yearsToRetirement: number,
	inflationRate: number
): number => {
	const yearlyInflationDecimal = inflationRate / 100;
	return currentPrice * (1 + yearlyInflationDecimal) ** yearsToRetirement;
};
