import React from 'react';
import { Box, Flex, Text, Grid } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ContributionFromEnum } from '../../api/users/interfaces/ContributionData';
import Pagination from '../ChakraUi/Pagination';
import { ContributionData } from '../../api/calculators/getCalculatorsUserAportations';
import ProjectConfig from '../../constants';
import { CurrentUserI } from '../../api/users/interfaces/Users ';

interface MobileContributionsTableProps {
	rows: any[];
	isFreelance: boolean;
	expandedRows: Record<string, boolean>;
	toggleRow: (month: string) => void;
	currentPage: number;
	totalPages: number;
	handlePageChange: (page: number) => void;
	translate: (key: string) => string;
	formatArcaNumber: (num: number) => string;
	currentUser: CurrentUserI;
}

const MobileContributionsTable: React.FC<MobileContributionsTableProps> = ({
	rows,
	isFreelance,
	expandedRows,
	toggleRow,
	currentPage,
	totalPages,
	handlePageChange,
	translate,
	formatArcaNumber,
	currentUser
}) => {
	return (
		<Box p={3}>
			{rows.map((row, index) => (
				<Box
					key={index}
					mb={3}
					p={3}
					borderWidth="1px"
					borderRadius="md"
					borderColor="gray.300"
					bg="white">
					<Flex justifyContent="space-between" alignItems="center" mb={2}>
						<Text fontWeight="bold">{row.month}</Text>
						<Box
							as="button"
							w="24px"
							h="24px"
							display="flex"
							justifyContent="center"
							alignItems="center"
							onClick={() => toggleRow(row.month)}
							aria-label={expandedRows[row.month] ? 'Collapse' : 'Expand'}
							bg={
								expandedRows[row.month]
									? ProjectConfig.ARCA_COLORS.PINK_500
									: ProjectConfig.ARCA_COLORS.PINK_300
							}
							color="white"
							borderRadius="50%"
							border="none"
							cursor="pointer"
							p={0}
							_hover={{
								bg: expandedRows[row.month]
									? ProjectConfig.ARCA_COLORS.PINK_600
									: ProjectConfig.ARCA_COLORS.PINK_400,
								transform: 'scale(1.05)'
							}}
							transition="all 0.2s">
							{expandedRows[row.month] ? (
								<ChevronDownIcon w="14px" h="14px" />
							) : (
								<ChevronRightIcon w="14px" h="14px" />
							)}
						</Box>
					</Flex>

					<Grid templateColumns="1fr 1fr" gap={2} fontSize="sm">
						<Text fontWeight="semibold">
							{translate('user.myContributions.contributionsTableStatusContribution')}:
						</Text>
						<Text>{row.contributionStatus}</Text>

						{!isFreelance && (
							<>
								<Text fontWeight="semibold">
									{currentUser?.user?.companyName}
									{translate('user.myContributions.contributionsTablePromoterContributions')}:
								</Text>
								<Text>{row.companyContribution}</Text>
							</>
						)}

						<Text fontWeight="semibold">
							{currentUser?.user?.name}
							{translate('user.myContributions.contributionsTableParticipantContributions')}:
						</Text>
						<Text>{row.participantContribution}</Text>

						{!isFreelance && (
							<>
								<Text fontWeight="semibold">
									{translate('user.myContributions.contributionsTableTotalAmount')}:
								</Text>
								<Text>{row.totalContribution}</Text>
							</>
						)}
					</Grid>

					{/* Expanded detail view for mobile */}
					{expandedRows[row.month] && (
						<Box mt={3} pt={3} borderTopWidth="1px" borderColor="gray.300">
							{row.companyOrParticipantContributions.map(
								(contribution: ContributionData, idx: number) => (
									<Box key={idx} mb={4} pl={2} p={3} borderRadius="md" bg="gray.50">
										<Text fontSize="sm" fontWeight="medium" mb={2}>
											{contribution.typeContribution === ContributionFromEnum.COMPANY
												? `${currentUser?.user?.companyName} ${translate('user.myContributions.contributionsTablePromoterContributions')}`
												: `${currentUser?.user?.name} ${translate('user.myContributions.contributionsTableParticipantContributions')}`}
										</Text>

										<Grid templateColumns="1fr 1fr" gap={2} fontSize="xs" color="gray.700">
											<Text fontWeight="semibold">{translate('user.myContributions.amount')}:</Text>
											<Text>{formatArcaNumber(contribution.contributionAmount)} €</Text>

											<Text fontWeight="semibold">{translate('user.myContributions.date')}:</Text>
											<Text>{new Date(contribution?.dateContribution).toLocaleDateString()}</Text>

											<Text fontWeight="semibold">
												{translate('user.myContributions.liquidativeValue')}:
											</Text>
											<Text>
												{contribution.liquidationValue
													? `${formatArcaNumber(contribution.liquidationValue)} €`
													: '-'}
											</Text>

											<Text fontWeight="semibold">
												{translate('user.myContributions.participations')}:
											</Text>
											<Text>{contribution.sharesBought || '-'}</Text>
										</Grid>
									</Box>
								)
							)}
						</Box>
					)}
				</Box>
			))}

			<Box mt={4}>
				<Pagination
					currentPage={currentPage}
					totalPages={totalPages}
					onPageChange={handlePageChange}
				/>
			</Box>
		</Box>
	);
};

export default MobileContributionsTable;
