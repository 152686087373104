import {
	Box,
	Card,
	CardBody,
	Divider,
	Flex,
	Skeleton,
	Stack,
	Text,
	VStack
} from '@chakra-ui/react';
import React from 'react';
import ProjectConfig from '../../constants';
import Pagination from '../ChakraUi/Pagination';
import { SingleUserContributionData } from '../../api/users/interfaces/ContributionData';

interface MobileAdminContributionsTableProps {
	contributionItems: SingleUserContributionData[];
	isPageLoading: boolean;
	currentPage: number;
	totalPages: number;
	handlePageChange: (page: number) => void;
	translate: (key: string) => string;
	formatArcaNumber: (num: number) => string;
	formatDate: (dateString: string) => string;
}

const MobileAdminContributionsTable: React.FC<MobileAdminContributionsTableProps> = ({
	contributionItems,
	isPageLoading,
	currentPage,
	totalPages,
	handlePageChange,
	translate,
	formatArcaNumber,
	formatDate
}) => {
	return (
		<Box p="4">
			<VStack spacing={4} align="stretch" width="100%">
				{isPageLoading
					? Array.from({ length: 3 }).map((_, index) => (
							<Box key={index} width="100%">
								<Skeleton height="150px" borderRadius="md" />
							</Box>
						))
					: contributionItems?.map((item, index) => (
							<Card key={index} variant="outline" width="100%">
								<CardBody p={3}>
									<Stack spacing={2}>
										<Flex justify="space-between">
											<Text
												fontWeight="bold"
												fontSize="sm"
												color={ProjectConfig.ARCA_COLORS.GREEN_800}>
												{translate('admin.contributions.contributionsTableAmount')}:
											</Text>
											<Text fontSize="sm">{`${formatArcaNumber(item.contributionAmount)} €`}</Text>
										</Flex>

										<Divider />

										<Flex justify="space-between">
											<Text
												fontWeight="bold"
												fontSize="sm"
												color={ProjectConfig.ARCA_COLORS.GREEN_800}>
												{translate('admin.contributions.contributionsTableDate')}:
											</Text>
											<Text fontSize="sm">{formatDate(item.dateContribution)}</Text>
										</Flex>

										<Divider />

										<Flex justify="space-between">
											<Text
												fontWeight="bold"
												fontSize="sm"
												color={ProjectConfig.ARCA_COLORS.GREEN_800}>
												{translate('admin.contributions.contributionsTableWhoContributes')}:
											</Text>
											<Text fontSize="sm">
												{translate(
													`user.myContributions.contributionsTableType${item.typeContribution}`
												)}
											</Text>
										</Flex>

										<Divider />

										<Flex justify="space-between">
											<Text
												fontWeight="bold"
												fontSize="sm"
												color={ProjectConfig.ARCA_COLORS.GREEN_800}>
												{translate('admin.contributions.contributionsTableContributionStatus')}:
											</Text>
											<Text fontSize="sm">
												{translate(
													`user.myContributions.contributionsTableStatus${item.statusContribution}`
												)}
											</Text>
										</Flex>

										<Divider />

										<Flex justify="space-between">
											<Text
												fontWeight="bold"
												fontSize="sm"
												color={ProjectConfig.ARCA_COLORS.GREEN_800}>
												{translate('admin.contributions.contributionsTableNetValue')}:
											</Text>
											<Text fontSize="sm">{`${item.liquidationValue} €`}</Text>
										</Flex>

										<Divider />

										<Flex justify="space-between">
											<Text
												fontWeight="bold"
												fontSize="sm"
												color={ProjectConfig.ARCA_COLORS.GREEN_800}>
												{translate('admin.contributions.contributionsTableShares')}:
											</Text>
											<Text fontSize="sm">{item.sharesBought}</Text>
										</Flex>
									</Stack>
								</CardBody>
							</Card>
						))}
			</VStack>

			<Box mt={6} textAlign="center">
				<Pagination
					currentPage={currentPage}
					totalPages={totalPages}
					onPageChange={handlePageChange}
				/>
			</Box>
		</Box>
	);
};

export default MobileAdminContributionsTable;
