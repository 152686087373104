import React, { useState, useRef, useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Box,
	Flex,
	Input,
	Text,
	Avatar,
	Spinner,
	IconButton,
	useBreakpointValue
} from '@chakra-ui/react';
import { FaPaperPlane } from 'react-icons/fa';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import ProjectConfig from '../../constants';
import { triggerMetricsWaveEvent } from '../../metricswave';
import { MetricswaveEvents, MetricswaveEventsAndParams } from '../../metricswave/MetricswaveEnums';
import { Global } from '@emotion/react';

interface Message {
	id: string;
	text: string;
	sender: 'user' | 'bot';
	timestamp: Date;
	isTyping?: boolean;
}

interface AIAssistantModalProps {
	isOpen: boolean;
	onClose: () => void;
	userId?: string;
}

const AIAssistantModal: React.FC<AIAssistantModalProps> = ({ isOpen, onClose, userId }) => {
	const [messages, setMessages] = useState<Message[]>([
		{
			id: '1',
			text: 'Hola, soy Noel·IA, tu asistente personal. ¿En qué puedo ayudarte?',
			sender: 'bot',
			timestamp: new Date()
		}
	]);
	const [inputValue, setInputValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isMinimized, setIsMinimized] = useState(false);
	const messagesEndRef = useRef<HTMLDivElement>(null);
	const modalContentRef = useRef<HTMLDivElement>(null);
	const isMobileScreen = useBreakpointValue({ base: true, md: false });
	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	// Scroll al final de los mensajes cuando hay nuevos
	useEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [messages]);

	// Función para ajustar altura automáticamente
	const autoResizeTextArea = () => {
		const textArea = textAreaRef.current;
		if (textArea) {
			textArea.style.height = 'auto';
			const newHeight = Math.min(textArea.scrollHeight, 100); // Máximo 100px
			textArea.style.height = `${newHeight}px`;
		}
	};

	// Efecto para redimensionar el textarea cuando cambia el input
	useEffect(() => {
		autoResizeTextArea();
	}, [inputValue]);

	// Event listener para detectar clics fuera del modal y minimizarlo
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				isOpen &&
				!isMinimized &&
				modalContentRef.current &&
				!modalContentRef.current.contains(event.target as Node)
			) {
				setIsMinimized(true);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isOpen, isMinimized]);

	// Función para enviar mensaje al chatbot
	const sendMessage = async () => {
		if (!inputValue.trim()) return;

		// Registrar evento de métricas
		if (userId) {
			triggerMetricsWaveEvent(
				MetricswaveEventsAndParams[MetricswaveEvents.AI_ASSISTANT_MESSAGE_SENT]?.event || '',
				userId,
				{ message: inputValue }
			);
		}

		// Agregar mensaje del usuario
		const userMessage: Message = {
			id: Date.now().toString(),
			text: inputValue,
			sender: 'user',
			timestamp: new Date()
		};

		setMessages((prevMessages) => [...prevMessages, userMessage]);
		setInputValue('');
		setIsLoading(true);

		// Agregar mensaje de "escribiendo..." temporal
		const typingMsgId = `typing-${Date.now()}`;
		setMessages((prevMessages) => [
			...prevMessages,
			{
				id: typingMsgId,
				text: 'Noel·IA está escribiendo...',
				sender: 'bot',
				timestamp: new Date(),
				isTyping: true // Usamos este flag para aplicar estilos especiales
			}
		]);

		try {
			// Llamada al backend
			const response = await fetch(`${process.env.REACT_APP_BASE_API}/latitude/prompt`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('token')}`
				},
				body: JSON.stringify({ message: inputValue })
			});

			if (!response.ok) {
				throw new Error('Error en la respuesta del servidor');
			}

			const result = await response.json();

			// Eliminar el mensaje de "escribiendo..."
			setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== typingMsgId));

			// Agregar respuesta del bot
			const botMessage: Message = {
				id: (Date.now() + 1).toString(),
				text:
					typeof result?.data?.response === 'string'
						? result.data.response
						: JSON.stringify(result?.data?.response) ||
							'Lo siento, no pude procesar tu solicitud. Por favor, inténtalo de nuevo.',
				sender: 'bot',
				timestamp: new Date()
			};

			setMessages((prevMessages) => [...prevMessages, botMessage]);

			// Si estaba minimizado, expandir para mostrar la respuesta
			if (isMinimized) {
				setIsMinimized(false);
			}
		} catch (error) {
			console.error('Error al comunicarse con el asistente IA:', error);

			// Eliminar el mensaje de "escribiendo..."
			setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== typingMsgId));

			// Mensaje de error
			const errorMessage: Message = {
				id: (Date.now() + 1).toString(),
				text: 'Ha ocurrido un error al comunicarse con el asistente. Por favor, inténtalo de nuevo más tarde.',
				sender: 'bot',
				timestamp: new Date()
			};

			setMessages((prevMessages) => [...prevMessages, errorMessage]);
		} finally {
			setIsLoading(false);
		}
	};

	// Enviar mensaje al presionar Enter
	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && !isLoading) {
			sendMessage();
		}
	};

	// Función para alternar entre minimizado y expandido
	const toggleMinimized = () => {
		if (isMobileScreen) {
			onClose(); // Cerrar modal completamente en móvil
		} else {
			setIsMinimized(!isMinimized);
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={isMobileScreen ? 'full' : 'md'}
			motionPreset="none"
			closeOnOverlayClick={false}
			blockScrollOnMount={!isMinimized}>
			{!isMinimized && (
				<ModalOverlay
					bg="rgba(0, 0, 0, 0.05)"
					backdropFilter="auto"
					backdropBlur="0px"
					pointerEvents="auto"
				/>
			)}
			<ModalContent
				ref={modalContentRef}
				h={isMinimized ? '60px' : isMobileScreen ? '100%' : '500px'}
				w={isMinimized ? (isMobileScreen ? 'auto' : '300px') : isMobileScreen ? '100%' : '400px'}
				maxW={isMinimized ? (isMobileScreen ? '90%' : '300px') : isMobileScreen ? '100%' : '450px'}
				position="fixed"
				bottom={isMobileScreen ? '0' : '20px'}
				right={isMobileScreen ? '0' : '20px'}
				mb={0}
				mr={0}
				pointerEvents="auto"
				bg={isMinimized ? ProjectConfig.ARCA_COLORS.PINK_500 : 'white'}
				boxShadow="0 4px 12px rgba(0, 0, 0, 0.15)"
				transition="all 0.3s ease"
				overflowY="hidden"
				borderRadius={isMinimized ? 'md' : isMobileScreen ? '0' : 'md'}
				borderTopRadius="md">
				<ModalHeader
					bg={ProjectConfig.ARCA_COLORS.PINK_500}
					color="white"
					borderTopRadius="md"
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					cursor="pointer"
					onClick={toggleMinimized}
					px={4}
					py={isMinimized ? 3 : 4}
					h={isMinimized ? '60px' : 'auto'}>
					<Text fontSize={isMinimized ? 'sm' : 'md'}>Noel·IA - Asistente Inteligente</Text>

					{isMinimized && (
						<IconButton
							aria-label="Expandir"
							icon={<AiOutlinePlusCircle size="18px" />}
							onClick={(e) => {
								e.stopPropagation();
								toggleMinimized();
							}}
							size="sm"
							variant="ghost"
							color="white"
							_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_600 }}
							_active={{ bg: ProjectConfig.ARCA_COLORS.PINK_700 }}
							borderRadius="full"
							p={0}
							width="32px"
							height="32px"
							minWidth="32px"
						/>
					)}
				</ModalHeader>

				{!isMinimized && (
					<Box position="absolute" top="8px" right="8px" display="flex" zIndex="1">
						<IconButton
							aria-label="Minimizar"
							icon={<AiOutlineMinusCircle size="18px" />}
							onClick={(e) => {
								e.stopPropagation();
								// En móvil, siempre cerramos el modal completamente
								if (isMobileScreen) {
									onClose();
								} else {
									toggleMinimized();
								}
							}}
							size="sm"
							variant="ghost"
							color="white"
							_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_600 }}
							_active={{ bg: ProjectConfig.ARCA_COLORS.PINK_700 }}
							borderRadius="full"
							p={0}
							width="32px"
							height="32px"
							minWidth="32px"
							transition="all 0.2s ease"
							mr={2}
						/>
						<ModalCloseButton
							color="white"
							_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_600 }}
							_active={{ bg: ProjectConfig.ARCA_COLORS.PINK_700 }}
							borderRadius="full"
							position="static"
						/>
					</Box>
				)}

				{!isMinimized && (
					<ModalBody p={0} display="flex" flexDirection="column" overflowY="hidden">
						{/* Contenedor de mensajes */}
						<Box
							flex="1"
							overflowY="auto"
							p={4}
							bg={ProjectConfig.ARCA_COLORS.LIGHT_GREY_02}
							height={isMobileScreen ? 'calc(100vh - 180px)' : '350px'}
							css={{
								'&::-webkit-scrollbar': {
									width: '8px'
								},
								'&::-webkit-scrollbar-track': {
									background: '#f1f1f1'
								},
								'&::-webkit-scrollbar-thumb': {
									background: '#888',
									borderRadius: '4px'
								}
							}}>
							{messages.map((message) => (
								<Flex
									key={message.id}
									mb={4}
									flexDirection={message.sender === 'user' ? 'row-reverse' : 'row'}
									alignItems="flex-start">
									{message.sender === 'bot' && (
										<Avatar
											size="sm"
											src="/imgs/ai-assistant-icon.png"
											bg={ProjectConfig.ARCA_COLORS.PINK_200}
											mr={2}
										/>
									)}

									<Box
										maxW="70%"
										p={3}
										borderRadius="lg"
										bg={message.sender === 'user' ? ProjectConfig.ARCA_COLORS.PINK_500 : 'white'}
										color={message.sender === 'user' ? 'white' : 'black'}
										boxShadow="sm"
										overflowWrap="break-word"
										wordBreak="break-word">
										{message.isTyping ? (
											<Flex align="center">
												<Text mr={2}>Noel·IA está escribiendo</Text>
												<Box className="typing-animation">
													<span className="dot"></span>
													<span className="dot"></span>
													<span className="dot"></span>
												</Box>
											</Flex>
										) : (
											<Text fontSize={isMobileScreen ? 'sm' : 'md'} whiteSpace="pre-wrap">
												{message.text}
											</Text>
										)}
									</Box>

									{message.sender === 'user' && (
										<Avatar size="sm" ml={2} src="/imgs/user-profile.png" />
									)}
								</Flex>
							))}
							<div ref={messagesEndRef} />
						</Box>

						{/* Input para escribir mensajes */}
						<Flex
							p={3}
							borderTop="1px solid"
							borderColor="gray.200"
							alignItems="center"
							width="100%"
							position="relative">
							<Input
								flex="1"
								placeholder="Escribe tu mensaje aquí..."
								value={inputValue}
								onChange={(e) => setInputValue(e.target.value)}
								onKeyDown={(e) => {
									if (e.key === 'Enter' && !e.shiftKey) {
										e.preventDefault();
										handleKeyDown(e);
									}
								}}
								isDisabled={isLoading}
								pr="40px"
								as="textarea"
								rows={1}
								resize="none"
								overflow="auto"
								minHeight="40px"
								maxHeight="100px"
								sx={{
									'&::-webkit-scrollbar': { width: '8px' },
									'&::-webkit-scrollbar-track': { background: '#f1f1f1' },
									'&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '4px' }
								}}
								ref={textAreaRef}
							/>
							<IconButton
								position="absolute"
								right="12px"
								bottom="12px"
								aria-label="Enviar mensaje"
								icon={isLoading ? <Spinner size="sm" /> : <FaPaperPlane />}
								onClick={sendMessage}
								isDisabled={isLoading || !inputValue.trim()}
								colorScheme="pink"
								size="sm"
								width="32px"
								height="32px"
								minWidth="32px"
								borderRadius="full"
								bg={inputValue.trim() ? ProjectConfig.ARCA_COLORS.PINK_500 : 'gray.200'}
								color={inputValue.trim() ? 'white' : 'gray.400'}
								_hover={{
									bg: inputValue.trim() ? ProjectConfig.ARCA_COLORS.PINK_600 : 'gray.300',
									transform: inputValue.trim() ? 'scale(1.05)' : 'none'
								}}
								_active={{
									bg: ProjectConfig.ARCA_COLORS.PINK_700,
									transform: 'scale(0.95)'
								}}
								boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
								transition="all 0.2s ease"
							/>
						</Flex>
					</ModalBody>
				)}

				{/* Estilos para la animación de escritura */}
				<Global
					styles={`
						.typing-animation {
							display: inline-flex;
							align-items: center;
						}

						.dot {
							height: 6px;
							width: 6px;
							margin: 0 2px;
							background-color: ${ProjectConfig.ARCA_COLORS.PINK_500};
							border-radius: 50%;
							opacity: 0.6;
							animation: pulse 1.2s infinite;
						}

						.dot:nth-child(2) {
							animation-delay: 0.3s;
						}

						.dot:nth-child(3) {
							animation-delay: 0.6s;
						}

						@keyframes pulse {
							0%,
							100% {
								opacity: 0.2;
							}
							50% {
								opacity: 1;
							}
						}
					`}
				/>
			</ModalContent>
		</Modal>
	);
};

export default AIAssistantModal;
