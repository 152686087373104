import React, { useContext } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalCloseButton,
	Image,
	Text,
	Box
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import ProjectConfig from '../../../constants';
import ArcaPrimaryButton from '../../Buttons/ArcaPrimaryButton';
import { ArcaContext } from '../../../context/ArcaContext.context';
import toggleFeatureFlagByName from '../../../api/feature-flags/toggleFeatureFlagByName';

interface AnnounceNewFeatureModalProps {
	featureFlagName: string;
	isOpen: boolean;
	onClose: () => void;
	mainRoleForFeature: 'USER' | 'ADMIN';
	title?: string;
	description?: string;
	imageSrc?: string;
	buttonText?: string;
	path?: string;
	link?: string;
}

const AnnounceNewFeatureModal: React.FC<AnnounceNewFeatureModalProps> = ({
	featureFlagName,
	isOpen,
	onClose,
	mainRoleForFeature,
	title,
	description = '',
	imageSrc = null,
	buttonText = 'OK',
	path = null,
	link = null
}) => {
	const navigate = useNavigate();
	const { token, companyId, userId } = useContext(ArcaContext);

	const computedTitle = title
		? title
		: mainRoleForFeature === 'ADMIN'
			? 'Nueva funcionalidad para administradores'
			: 'Nueva funcionalidad para usuarios';

	const handleButtonClick = async () => {
		// Toggle the feature flag when the button is pressed
		await toggleFeatureFlagByName(token, companyId, userId, featureFlagName);

		if (path) {
			navigate(path, { replace: true });
		} else if (link) {
			window.location.href = link;
		}
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader>{computedTitle}</ModalHeader>
				<ModalBody>
					{imageSrc && (
						<Box mb={4} textAlign="center">
							<Image src={imageSrc} alt="Announcement image" maxH="200px" mx="auto" />
						</Box>
					)}
					{description && (
						<Text fontSize="16px" fontFamily={ProjectConfig.FONTS.INTER} fontWeight="400">
							{description}
						</Text>
					)}
				</ModalBody>
				{buttonText && (
					<ModalFooter>
						<ArcaPrimaryButton onClick={handleButtonClick}>
							{path ? '¡Quiero verla!' : buttonText}
						</ArcaPrimaryButton>
					</ModalFooter>
				)}
			</ModalContent>
		</Modal>
	);
};

export default AnnounceNewFeatureModal;
