import React from 'react';
import { Button, ButtonProps, Spinner, As } from '@chakra-ui/react';
import ProjectConfig from '../../constants';

interface ArcaLinkButtonProps extends ButtonProps {
	color?: string;
	_hover?: object;
	_active?: object;
	_disabled?: object;
	onClick?: () => void;
	children: React.ReactNode;
	icon?: string | React.ReactNode;
	isLoading?: boolean;
	href?: string;
	isExternal?: boolean;
}

const ArcaLinkButton: React.FC<ArcaLinkButtonProps> = ({
	color = ProjectConfig.ARCA_COLORS.PINK_500,
	_hover = {
		color: ProjectConfig.ARCA_COLORS.PINK_600,
		textDecoration: 'underline',
		backgroundColor: 'transparent'
	},
	_active = {
		color: ProjectConfig.ARCA_COLORS.PINK_800,
		textDecoration: 'underline',
		backgroundColor: 'transparent'
	},
	_disabled = {
		color: ProjectConfig.ARCA_COLORS.PINK_100,
		cursor: 'not-allowed',
		opacity: 0.7,
		backgroundColor: 'transparent'
	},
	onClick,
	children,
	icon,
	isLoading = false,
	href,
	isExternal = false,
	...props
}) => {
	const buttonProps = {
		as: href ? ('a' as As) : ('button' as As),
		href: href,
		target: isExternal ? '_blank' : undefined,
		rel: isExternal ? 'noopener noreferrer' : undefined,
		...props
	};

	return (
		<Button
			variant="link"
			color={color}
			backgroundColor="transparent"
			_hover={_hover}
			_active={_active}
			_disabled={_disabled}
			onClick={onClick}
			p="0"
			height="auto"
			fontWeight="normal"
			isDisabled={isLoading || props.isDisabled}
			{...buttonProps}>
			{isLoading ? <Spinner size="sm" mr="2" /> : null}
			{children}
			{icon && typeof icon === 'string' ? (
				<img src={icon} alt="icon" style={{ marginLeft: '10px', width: '20px', height: '20px' }} />
			) : icon ? (
				<span style={{ marginLeft: '5px' }}>{icon}</span>
			) : null}
		</Button>
	);
};

export default ArcaLinkButton;
