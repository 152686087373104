import { Input } from '@chakra-ui/react';
import React, { useState } from 'react';
import ProjectConfig from '../constants';

const FileUpload: React.FC<{ onFileUpload: (file: File) => void; borderColor: string }> = ({
	onFileUpload,
	borderColor
}) => {
	const [error, setError] = useState<string>('');

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			// Validar tipo de archivo
			const validTypes = ['text/csv']; // Tipos permitidos
			if (!validTypes.includes(file.type)) {
				setError('Tipo de archivo no permitido. Solo se permiten CSV.');
				return;
			}

			// Validar tamaño de archivo (por ejemplo, 5 MB)
			const maxSizeInMB = 5;
			if (file.size > maxSizeInMB * 1024 * 1024) {
				setError(`El archivo debe ser menor a ${maxSizeInMB} MB.`);
				return;
			}

			setError('');
			onFileUpload(file);
		}
	};

	return (
		<div>
			<Input
				name="grossSalaryFile"
				type="file"
				accept=".csv, .xls, .xlsx, .ods"
				onChange={handleFileChange}
				borderColor={borderColor}
				_focus={{ borderColor: ProjectConfig.ARCA_COLORS.GREEN_500 }}
			/>
			{error && <p style={{ color: 'red' }}>{error}</p>}
		</div>
	);
};

export default FileUpload;
