import React, { useContext } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Text,
	Box,
	Image,
	Link,
	Button,
	Flex,
	CloseButton
} from '@chakra-ui/react';
import ProjectConfig from '../../../constants';
import { formatArcaNumber } from '../../../utils/formatArcaNumbers';
import toggleFeatureFlagByName from '../../../api/feature-flags/toggleFeatureFlagByName';
import { ArcaContext } from '../../../context/ArcaContext.context';
import { triggerMetricsWaveEvent } from '../../../metricswave';
import {
	MetricswaveEvents,
	MetricswaveEventsAndParams
} from '../../../metricswave/MetricswaveEnums';
import { useNavigate } from 'react-router';

interface TaxdownBenefitModalProps {
	isOpen: boolean;
	onClose: () => void;
	annualTaxBenefit: number;
	featureFlagName: string;
	translate: (key: string) => string;
}

const TaxdownBenefitModal: React.FC<TaxdownBenefitModalProps> = ({
	isOpen,
	onClose,
	annualTaxBenefit = 0,
	featureFlagName,
	translate
}) => {
	const navigate = useNavigate();
	const { token, companyId, userId } = useContext(ArcaContext);

	// Extract common event
	const TAXDOWN_PARTNERSHIP_EVENT =
		MetricswaveEventsAndParams[MetricswaveEvents.TAXDOWN_PARTNERSHIP_CLICK].event;

	// Helper function to trigger metrics
	const triggerTaxdownMetric = (action: string) => {
		triggerMetricsWaveEvent(TAXDOWN_PARTNERSHIP_EVENT, userId, { action });
	};

	const handleDoNotShowAgain = async () => {
		// Toggle the feature flag to prevent showing this modal again
		await toggleFeatureFlagByName(token, companyId, userId, featureFlagName);
		// Track the event when user clicks "do not show again"
		triggerTaxdownMetric('do_not_show_again');
		onClose();
	};

	const handleTaxDownClick = () => {
		// Track the event when the user clicks on "Accede aquí"
		triggerTaxdownMetric('open_link');
	};

	const handleClose = () => {
		// Track the event when the user closes the modal
		triggerTaxdownMetric('close_modal');
		onClose();
	};

	const handleNavigateToContributions = () => {
		navigate('/dashboard/my-contributions');
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={handleClose} size="4xl">
			<ModalOverlay bg="rgba(0, 0, 0, 0.7)" backdropFilter="blur(5px)" />
			<ModalContent
				p={{ base: 4, md: 6 }}
				borderRadius="xl"
				maxW={{ base: '95%', md: '900px', lg: '1100px' }}>
				<CloseButton
					position="absolute"
					right="12px"
					top="12px"
					size="lg"
					zIndex={10}
					onClick={handleClose}
					color={ProjectConfig.ARCA_COLORS.PINK_700}
					_hover={{ bg: 'transparent', color: ProjectConfig.ARCA_COLORS.PINK_900 }}
				/>

				<ModalHeader
					textAlign="center"
					fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }}
					fontWeight="bold"
					color={ProjectConfig.ARCA_COLORS.PINK_700}
					mb={2}
					p={0}>
					Novedad: Presume de fiscalidad
				</ModalHeader>

				<ModalBody p={{ base: 2, md: 3 }}>
					<Flex direction="column" align="center" justify="center">
						<Text mb={4} fontSize={{ base: 'md', md: 'lg' }} textAlign="center" maxW="800px">
							La campaña de la renta es más llevadera cuando disfrutas de la fiscalidad que te da tu
							plan de pensiones de empleo
						</Text>

						<Flex direction="column" align="center" mb={4}>
							<Text fontSize={{ base: 'md', md: 'lg' }} textAlign="center" mb={1}>
								Por ser usuario de Arca tienes un
							</Text>

							<Text
								fontWeight="bold"
								fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
								mb={1}
								textAlign="center"
								color={ProjectConfig.ARCA_COLORS.GREEN_600}>
								20% de descuento
							</Text>

							<Text fontSize={{ base: 'md', md: 'lg' }} mb={4} textAlign="center" maxW="800px">
								en nuestro partner{' '}
								<Text as="span" fontWeight="bold">
									TaxDown
								</Text>{' '}
								para que puedes hacer tu declaración maximizando tus bonificaciones y deducciones 💸
							</Text>
						</Flex>

						<Link
							href="https://taxdown.es/landings_partners/tu-declaracion-de-la-renta-bien-hecha-gracias-a-arca-digital/?utm_source=arca_digital_clientes&utm_medium=partnership&utm_campaign=clientes/"
							isExternal
							_hover={{ textDecoration: 'none' }}
							mb={5}
							onClick={handleTaxDownClick}>
							<Button
								colorScheme="green"
								size="lg"
								px={8}
								py={5}
								bg={ProjectConfig.ARCA_COLORS.GREEN_600}
								color="white"
								fontSize={{ base: 'md', md: 'lg' }}
								fontWeight="bold"
								_hover={{ bg: ProjectConfig.ARCA_COLORS.GREEN_700, transform: 'scale(1.05)' }}
								transition="all 0.2s ease"
								boxShadow="0px 4px 12px rgba(0, 0, 0, 0.2)">
								Accede aquí a tu descuento
							</Button>
						</Link>

						<Box
							p={4}
							borderRadius="lg"
							bg={ProjectConfig.ARCA_COLORS.GREEN_100}
							borderLeft="6px solid"
							borderColor={ProjectConfig.ARCA_COLORS.GREEN_500}
							mb={4}
							maxW="900px"
							w="100%">
							<Text fontWeight="medium" fontSize={{ base: 'md', md: 'lg' }} textAlign="center">
								💸 Con tus contribuciones actuales, el año que viene no pagarás IRPF por{' '}
								<Text as="span" fontWeight="bold" fontSize={{ base: 'xl', md: '2xl' }}>
									{formatArcaNumber(annualTaxBenefit)} €
								</Text>{' '}
								en tu declaración de la renta. Puedes mejorar esto modificando tu aportación en el{' '}
								<Text
									as="span"
									color={ProjectConfig.ARCA_COLORS.PINK_700}
									cursor="pointer"
									textDecoration="underline"
									onClick={handleNavigateToContributions}
									_hover={{ color: ProjectConfig.ARCA_COLORS.PINK_900 }}>
									apartado Aportaciones
								</Text>
							</Text>
						</Box>

						<Flex justify="center" my={3}>
							<Image
								src="/imgs/taxdown/ARCA-TAWDOWN-RENTA.png"
								alt="Beneficio fiscal"
								maxW={{ base: '250px', md: '300px', lg: '380px' }}
								w="100%"
								mx="auto"
								fallbackSrc="https://via.placeholder.com/380x230?text=Beneficio+Fiscal"
								borderRadius="md"
								boxShadow="md"
							/>
						</Flex>
					</Flex>
				</ModalBody>

				<ModalFooter justifyContent="center" pt={0} pb={2}>
					<Button
						onClick={handleDoNotShowAgain}
						variant="outline"
						colorScheme="pink"
						color={ProjectConfig.ARCA_COLORS.PINK_600}
						borderColor={ProjectConfig.ARCA_COLORS.PINK_400}
						size="md"
						_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_50 }}>
						No volver a mostrar
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default TaxdownBenefitModal;
