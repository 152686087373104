// 8daaff64-9c3b-4af2-ad2b-4655431673e3
export enum METRICS_WAVE_FUNNEL_PAYFIT_FEATURE {
	CLICK_EXPORT_PAYFIT_BUTTON = 'CLICK_EXPORT_PAYFIT_BUTTON',
	CLICK_PAYFIT_HHRR_SOFTWARE_SELECTED = 'CLICK_PAYFIT_HHRR_SOFTWARE_SELECTED',
	CLICK_PAYFIT_FILE_UPLOAD = 'CLICK_PAYFIT_FILE_UPLOAD',
	CLICK_PAYFIT_FILE_AUTOFILL = 'CLICK_PAYFIT_FILE_AUTOFILL',
	CLICK_PAYFIT_FILE_DOWNLOAD = 'CLICK_PAYFIT_FILE_DOWNLOAD'
}

export enum MetricswaveEvents {
	NEW_HHRR_SOFTWARE_NOT_AVAILABLE = 'NEW_HHRR_SOFTWARE_NOT_AVAILABLE',
	SIMULATORS_PENSION_SHOW_CHART = 'SIMULATORS_PENSION_SHOW_CHART',
	SIMULATORS_PAYROLL_SOON = 'SIMULATORS_PAYROLL_SOON',
	AI_ASSISTANT_OPENED = 'AI_ASSISTANT_OPENED',
	AI_ASSISTANT_MESSAGE_SENT = 'AI_ASSISTANT_MESSAGE_SENT',
	TAXDOWN_PARTNERSHIP_CLICK = 'TAXDOWN_PARTNERSHIP_CLICK'
}

export const MetricswaveEventsAndParams = {
	[MetricswaveEvents.NEW_HHRR_SOFTWARE_NOT_AVAILABLE]: {
		event: 'b6aeabca-17c3-470a-87c3-4bbf6668f563',
		params: {
			softwareHHRRSelected: 'string'
		}
	},
	[MetricswaveEvents.SIMULATORS_PENSION_SHOW_CHART]: {
		event: '0b34a48b-5a6c-44c0-b21d-37e945a73ebb',
		params: {}
	},
	[MetricswaveEvents.SIMULATORS_PAYROLL_SOON]: {
		event: '7f0f2a1d-1963-40e5-b05e-4ba3fbd8b7a6',
		params: {}
	},
	[MetricswaveEvents.AI_ASSISTANT_OPENED]: {
		event: 'uuid-for-assistant-opened',
		params: []
	},
	[MetricswaveEvents.AI_ASSISTANT_MESSAGE_SENT]: {
		event: 'uuid-for-message-sent',
		params: ['message']
	},
	[MetricswaveEvents.TAXDOWN_PARTNERSHIP_CLICK]: {
		event: '278447f6-21b1-4e1b-b0e7-213716f3d76e',
		params: {
			action: 'string'
		}
	}
};

export type MetricswaveEvent = keyof typeof MetricswaveEvents;
