import ButtonShowcase from '../components/ButtonShowcase';
import OnboardingUser from '../components/OnboardingUser';
import UserInvitation from '../components/UserInvitation';
import Dashboard from '../views/Dashboard';
import ForgetPassword from '../views/ForgetPassword';
import Login from '../views/Login';
import Maintenance from '../views/Maintenance';
import NotFoundPage from '../views/NotFoundPage';

interface ConfigRoutesI {
	path: string;
	component: React.ReactNode;
}

export const redirectRoutes: ConfigRoutesI[] = [
	{
		path: '/',
		component: <Login />
	}
];

export const publicRoutes: ConfigRoutesI[] = [
	{
		path: '*',
		component: <NotFoundPage />
	},
	{
		path: '/login',
		component: <Login />
	},
	{
		path: '/forget-password',
		component: <ForgetPassword />
	},
	{
		path: '/token',
		component: (
			<Login token={new URLSearchParams(window.location.search).get('token') || undefined} />
		)
	},
	{
		path: '/accept-user-invitation',
		component: <UserInvitation />
	},
	{
		path: '/maintenance',
		component: <Maintenance />
	},
	{
		path: '/dev-mode/ui-components',
		component: (
			<>{process.env.REACT_APP_BASE_API?.includes('dev') ? <ButtonShowcase /> : <NotFoundPage />}</>
		)
	}
];

export const privateRoutes: ConfigRoutesI[] = [
	{
		path: '*',
		component: <NotFoundPage />
	},
	{
		path: '/dashboard/:tab',
		component: <Dashboard />
	},
	{
		path: '/onboarding-user',
		component: <OnboardingUser />
	}
];
