import React from 'react';
import { Button, ButtonProps, Spinner } from '@chakra-ui/react';
import ProjectConfig from '../../constants';

interface ArcaGhostButtonProps extends ButtonProps {
	color?: string;
	backgroundColor?: string;
	_hover?: object;
	_active?: object;
	_disabled?: object;
	onClick?: () => void;
	children: React.ReactNode;
	icon?: string | React.ReactNode;
	isLoading?: boolean;
}

const ArcaGhostButton: React.FC<ArcaGhostButtonProps> = ({
	color = ProjectConfig.ARCA_COLORS.PINK_400,
	backgroundColor = 'transparent',
	_hover = {
		bg: ProjectConfig.ARCA_COLORS.PINK_100,
		color: ProjectConfig.ARCA_COLORS.PINK_400
	},
	_active = {
		bg: ProjectConfig.ARCA_COLORS.PINK_200,
		color: ProjectConfig.ARCA_COLORS.PINK_400
	},
	_disabled = {
		bg: 'transparent',
		color: ProjectConfig.ARCA_COLORS.PINK_300,
		cursor: 'not-allowed',
		opacity: 0.7
	},
	onClick,
	children,
	icon,
	isLoading = false,
	...props
}) => {
	return (
		<Button
			variant="ghost"
			color={color}
			backgroundColor={backgroundColor}
			_hover={_hover}
			_active={_active}
			_disabled={_disabled}
			onClick={onClick}
			w="fit-content"
			px="30px"
			mx="auto"
			borderRadius="8px"
			isDisabled={isLoading || props.isDisabled}
			{...props}>
			{isLoading ? <Spinner size="sm" mr="2" /> : null}
			{children}
			{icon && typeof icon === 'string' ? (
				<img src={icon} alt="icon" style={{ marginLeft: '10px', width: '35px', height: '35px' }} />
			) : icon ? (
				<span style={{ marginLeft: '10px' }}>{icon}</span>
			) : null}
		</Button>
	);
};

export default ArcaGhostButton;
