import React, { useContext, useState } from 'react';
import { Box, Input, InputGroup, Text } from '@chakra-ui/react';
import forgetPassword from '../api/users/forgetPassword';
import ProjectConfig from '../constants';
import { I18nContext } from '../context/translations/TranslationsContext.context';
import GenericToast, { ToastStatusEnum } from '../components/ChakraUi/Toasts/GenericToast';
import ArcaPrimaryButton from '../components/Buttons/ArcaPrimaryButton';
import ArcaSecondaryButton from '../components/Buttons/ArcaSecondaryButton';

import { useNavigate } from 'react-router';

const ForgetPassword = () => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toastInfo, setToastInfo] = useState({
		title: '',
		description: '',
		status: ToastStatusEnum.INFO
	});

	const handleRecoverPassword = async (event: React.FormEvent) => {
		event.preventDefault();
		if (!email) {
			setEmailError(true);
			setToastInfo({
				title: 'Error',
				description: 'El correo electrónico es obligatorio.',
				status: ToastStatusEnum.ERROR
			});
			setShowToast(true);
			return;
		} else {
			setEmailError(false);
		}

		setIsLoading(true);
		try {
			const response = await forgetPassword(email);
			if (response.success) {
				setToastInfo({
					title: 'Éxito',
					description: 'Se ha enviado un correo para recuperar la contraseña.',
					status: ToastStatusEnum.SUCCESS
				});
			} else {
				setToastInfo({
					title: 'Error',
					description: 'Error al intentar recuperar la contraseña. Inténtalo de nuevo.',
					status: ToastStatusEnum.ERROR
				});
			}
		} catch (error) {
			console.error('Error:', error);
			setToastInfo({
				title: 'Error',
				description: 'Hubo un problema al intentar recuperar la contraseña.',
				status: ToastStatusEnum.ERROR
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="login-form">
			<div className="container">
				<img src="/imgs/LOGO_VERDE_2.svg" alt="Logo Arca" />
				<form onSubmit={handleRecoverPassword}>
					<InputGroup mt={2}>
						<Input
							name="email"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							borderColor={
								emailError
									? ProjectConfig.ARCA_COLORS.RED_500
									: email
										? ProjectConfig.ARCA_COLORS.GREEN_500
										: ProjectConfig.ARCA_COLORS.LIGHT_GREY_01
							}
							_focus={{ borderColor: ProjectConfig.ARCA_COLORS.GREEN_500 }}
							placeholder={translate('general.login.email')}
						/>
					</InputGroup>
					{emailError && (
						<Text color="red.500" mt={2}>
							{toastInfo.description}
						</Text>
					)}
					<Box width="300px" mt={4}>
						<ArcaPrimaryButton
							type="submit"
							isLoading={isLoading}
							width="100%"
							backgroundColor={ProjectConfig.ARCA_COLORS.LIGHT_GREY_02}
							_hover={{
								color: ProjectConfig.ARCA_COLORS.WHITE,
								background: ProjectConfig.ARCA_COLORS.GREEN_900
							}}>
							{translate('general.login.recoverPassword')}
						</ArcaPrimaryButton>
					</Box>
					<Box mt={2}>
						<ArcaSecondaryButton
							onClick={() => navigate('/login')}
							variant="link"
							width="auto"
							backgroundColor="transparent"
							_hover={{
								background: 'transparent',
								textDecoration: 'none'
							}}>
							{translate('general.login.haveAccount')}
						</ArcaSecondaryButton>
					</Box>
				</form>
				{showToast && (
					<GenericToast
						title={toastInfo.title}
						description={toastInfo.description}
						status={toastInfo.status}
						showToast={showToast}
					/>
				)}
			</div>
		</div>
	);
};

export default ForgetPassword;
