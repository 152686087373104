import React, { useState } from 'react';
import ProjectConfig from '../constants';
import updateUserSalary from '../api/users/updateUserSalary';
import ArcaGhostButton from './Buttons/ArcaGhostButton';
import { Box } from '@chakra-ui/react';

interface GrossSalaryInputComponentProps {
	token: string;
	onInform: (salary: number) => void;
	userId: string;
}

const GrossSalaryInputComponent: React.FC<GrossSalaryInputComponentProps> = ({
	token,
	onInform,
	userId
}) => {
	const [showInput, setShowInput] = useState<boolean>(false);
	const [salary, setSalary] = useState<number | ''>('');

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSalary(value ? Number(value) : '');
	};

	const handleSubmit = async () => {
		if (salary !== '') {
			console.log('salary of userId', userId, salary);
			try {
				await updateUserSalary(token, userId, salary);
				onInform(salary);
				setShowInput(false);
			} catch (error) {
				console.error('Error updating salary:', error);
			}
		}
	};

	return (
		<div>
			{!showInput ? (
				<ArcaGhostButton
					onClick={() => setShowInput(true)}
					size="sm"
					fontSize="12px"
					padding="2px 4px"
					w="auto"
					px="8px"
					py="2px"
					mx="0">
					Informar dato salario
				</ArcaGhostButton>
			) : (
				<Box display="flex" alignItems="center">
					<input
						type="number"
						min="0"
						placeholder="Ingrese salario"
						value={salary}
						onChange={handleInputChange}
						style={{
							width: '60%',
							fontSize: '12px',
							padding: '2px 4px',
							border: `1px solid ${ProjectConfig.ARCA_COLORS.LIGHT_GREY_01}`,
							borderRadius: '2px'
						}}
					/>
					<ArcaGhostButton
						onClick={handleSubmit}
						size="sm"
						ml="4px"
						fontSize="12px"
						padding="2px 4px"
						h="auto"
						minW="25px"
						w="25px"
						mx="0"
						px="0">
						✅
					</ArcaGhostButton>
					<ArcaGhostButton
						onClick={() => setShowInput(false)}
						size="sm"
						ml="4px"
						fontSize="12px"
						padding="2px 4px"
						h="auto"
						minW="25px"
						w="25px"
						color={ProjectConfig.ARCA_COLORS.BLACK}
						backgroundColor={ProjectConfig.ARCA_COLORS.LIGHT_GREY_01}
						border="none"
						mx="0"
						px="0"
						_hover={{
							bg: ProjectConfig.ARCA_COLORS.LIGHT_GREY_01,
							color: ProjectConfig.ARCA_COLORS.BLACK
						}}>
						❌
					</ArcaGhostButton>
				</Box>
			)}
		</div>
	);
};

export default GrossSalaryInputComponent;
