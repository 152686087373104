import { Box, Grid, GridItem, ListItem, SimpleGrid, Text, UnorderedList } from '@chakra-ui/react';
import React, { useContext, useEffect, useState, lazy, Suspense } from 'react';
import BenUserCard from '../../../components/ChakraUi/Cards/BenUserCard';
import { ArcaContext } from '../../../context/ArcaContext.context';
import { BarData, ContributionFromEnum } from '../../../api/users/interfaces/ContributionData';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import ProjectConfig from '../../../constants';
import LoadingViewSpinner from '../../../components/ChakraUi/Spinners/LoadingViewSpinner';
import {
	formatArcaNumber,
	formatArcaNumberCalculeCents,
	formatNetAssetValue,
	formatNumberToDate,
	formatNumParticipations
} from '../../../utils/formatArcaNumbers';
import getAdhesionDetails from '../../../api/users/getAdhesionDetails';
import { UserAdhesionDetailsI } from '../../../api/users/interfaces/UserAdhesionDetailsI';
import GenericToast, { ToastStatusEnum } from '../../../components/ChakraUi/Toasts/GenericToast';
import getCalculatorsUserAportations, {
	PaginatedMonthContributions,
	PaginationParams
} from '../../../api/calculators/getCalculatorsUserAportations';
import SectionTitle from '../../../components/Titles/SectionTitle';
import { GenericPaginationOutput } from '../../../api/generic-dtos/genericPaginationOutput';
import SelectCurrentYear from '../../../components/SelectCurrentYear';
import ConfidenceBoosterLiteComponent from '../../../components/ConfidenceBoosterLiteComponent';
import TaxdownBenefitModal from '../../../components/ChakraUi/Modals/TaxdownBenefitModal';
import checkFeatureFlagByName from '../../../api/feature-flags/checkFeatureFlagByName';

// Import dynamically in a useEffect
const BarChart = lazy(() => import('react-chartjs-2').then((module) => ({ default: module.Bar })));

interface UserMyBenefitTabProps {
	userName: string;
	isFreelance: boolean;
	startYear?: number;
}

const UserMyBenefit: React.FC<UserMyBenefitTabProps> = ({
	userName,
	isFreelance,
	startYear
}: UserMyBenefitTabProps) => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toastInfo, setToastInfo] = useState<any>({
		title: '',
		description: '',
		status: ''
	});
	const [loadingChart, setLoadingChart] = useState<boolean>(true);
	const [loadingAdhesionDetails, setLoadingAdhesionDetails] = useState<boolean>(true);
	const [showTaxBenefitModal, setShowTaxBenefitModal] = useState<boolean>(false);
	const [annualTaxBenefit, setAnnualTaxBenefit] = useState<number>(0);
	const [currentYearContributions, setCurrentYearContributions] = useState<number>(0);

	const [userAportationsByType, setUserAportationsByType] =
		useState<GenericPaginationOutput<PaginatedMonthContributions>>();

	const [adhesionDetails, setAdhesionDetails] = useState<UserAdhesionDetailsI[]>([
		{
			userId: '',
			companyId: '',
			day: 0,
			time: 0,
			operation: '',
			productCode: 0,
			vestedRights: 0,
			numParticipations: 0,
			totalContributions: 0,
			netAssetValue: 0,
			userContributions: 0,
			companyContributions: 0,
			officeCode: 0,
			data: {
				IRBO: '',
				PIRB: '',
				USCO: 0,
				USTM: 0,
				TIPM: 0,
				MENS: '',
				NAPI: 0,
				CAAB: 0,
				PPPA: 0,
				PLIQ: 0,
				IPRE: 0,
				CAPR: 0,
				NOPE: 0,
				ACCI: 0,
				ECAJ: 0,
				USTF: 0
			}
		}
	]);

	const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());

	interface Item {
		month: string;
		performanceEuros: number;
		performancePercentage: number;
		contributions: Contribution[];
	}
	interface Contribution {
		id: string;
		createdAt: string;
		updatedAt: string;
		companyId: string;
		userId: string;
		contributionAmount: number;
		typeContribution: ContributionFromEnum;
		dateContribution: string;
		statusContribution: string;
	}

	const { token, companyId, userId } = useContext(ArcaContext);

	useEffect(() => {
		const fetchAdhesionDetails = async () => {
			setLoadingAdhesionDetails(true);
			try {
				const adhesionRes = await getAdhesionDetails(token);
				const adhesionDetailsData = adhesionRes.data;

				if (Array.isArray(adhesionDetailsData)) {
					setAdhesionDetails(adhesionDetailsData);
				}

				if (!adhesionRes.success || adhesionRes.statusCode === 500) {
					setToastInfo({
						title: `${translate('user.myBenefit.toastTitle')}`,
						description: `${translate('user.myBenefit.toastDescription')}`,
						status: ToastStatusEnum.ERROR
					});
					setShowToast(true);
				}
			} catch (error) {
				console.error('Error fetching adhesion details:', error);
			} finally {
				setLoadingAdhesionDetails(false);
			}
		};

		fetchAdhesionDetails();
	}, [token]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const limitSelected = 12;
				const params: PaginationParams = {
					page: currentPage,
					limit: limitSelected,
					year: selectedYear
				};
				setLoadingChart(true);
				const contributionsRes: GenericPaginationOutput<PaginatedMonthContributions> =
					await getCalculatorsUserAportations(token, params);

				setUserAportationsByType(contributionsRes);
				setTotalPages(contributionsRes.totalPages);

				// Calculate current year contributions only if we're fetching current year data
				const currentYear = new Date().getFullYear();
				if (selectedYear === currentYear) {
					let totalParticipant = 0;
					let totalCompany = 0;
					let lastParticipantContribution = 0;
					let lastCompanyContribution = 0;

					contributionsRes.items?.forEach((item) => {
						item.contributions.forEach((contribution) => {
							if (contribution.typeContribution === ContributionFromEnum.PARTICIPANT) {
								totalParticipant += contribution.contributionAmount;
								lastParticipantContribution = contribution.contributionAmount;
							} else if (contribution.typeContribution === ContributionFromEnum.COMPANY) {
								totalCompany += contribution.contributionAmount;
								lastCompanyContribution = contribution.contributionAmount;
							}
						});
					});

					// Calculate remaining months until December (including current month)
					const currentDate = new Date();
					const currentMonth = currentDate.getMonth(); // 0-11
					const remainingMonths = 12 - currentMonth;

					// Project future contributions
					const monthlyProjection = lastParticipantContribution + lastCompanyContribution;
					const projectedContributions = monthlyProjection * remainingMonths;

					const totalContributions = totalParticipant + totalCompany + projectedContributions;
					setCurrentYearContributions(totalContributions);
				}

				setLoadingChart(false);
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setLoadingChart(false);
			}
		};

		fetchData();
	}, [token, currentPage, selectedYear]);

	// Add new useEffect for tax benefit modal
	useEffect(() => {
		const checkTaxBenefitFlag = async () => {
			if (token && userId && currentYearContributions > 0) {
				try {
					const checkFeatureFlag = await checkFeatureFlagByName(
						token,
						companyId,
						userId,
						'taxdown-benefit-modal-feature'
					);

					const { isActive, userHasSeenFeature } = checkFeatureFlag?.data;

					if (isActive && !userHasSeenFeature) {
						setAnnualTaxBenefit(currentYearContributions);
						setShowTaxBenefitModal(true);
					}
				} catch (error) {
					console.error('Error checking tax benefit modal feature:', error);
				}
			}
		};

		checkTaxBenefitFlag();
	}, [token, companyId, userId, currentYearContributions]);

	// Preparing the data for the dynamic chart
	const userAportationsByTypeItems = userAportationsByType?.items;

	const currentDate = new Date();
	const yearNow = currentDate.getFullYear();
	const currentYearSameAsSelectedYear = yearNow === selectedYear;

	const currentYear = selectedYear;
	const currentMonth = currentYearSameAsSelectedYear ? currentDate.getMonth() + 1 : 12;

	const monthNames = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre'
	];

	const allMonths = Array.from(
		{ length: currentMonth },
		(_, i) => `${currentYear}-${String(i + 1).padStart(2, '0')}`
	);

	const arrPerformanceLabels: string[] = [];
	const acumulativeParticipantData: number[] = [];
	const acumulativeCompanyData: number[] = [];
	// const planPerformance: number[] = [];

	let participantTotal = 0;
	let companyTotal = 0;
	// let performanceTotal = 0;

	const contributionsByMonth = new Map<string, Item>();
	userAportationsByTypeItems?.forEach((item) => {
		contributionsByMonth.set(item.month, item);
	});

	allMonths.forEach((month) => {
		const monthIndex = parseInt(month.split('-')[1], 10) - 1;

		const item = contributionsByMonth.get(month);
		// let monthlyPerformance = 0;

		if (item) {
			item.contributions.forEach((contribution) => {
				if (contribution.typeContribution === ContributionFromEnum.PARTICIPANT) {
					participantTotal += contribution.contributionAmount;
				} else if (
					contribution.typeContribution ===
					ContributionFromEnum.CANCELLMENT_PARTICIPANT_SAVINGS_EXTRAORDINARY
				) {
					participantTotal += contribution.contributionAmount;
				} else if (contribution.typeContribution === ContributionFromEnum.COMPANY) {
					companyTotal += contribution.contributionAmount;
				} else if (
					contribution.typeContribution === ContributionFromEnum.CANCELLMENT_PROMOTER_SAVINGS
				) {
					companyTotal += contribution.contributionAmount;
				}
			});

			// performanceTotal += item.performanceEuros; // Acumulando el rendimiento en euros
			// monthlyPerformance = item.performancePercentage; // Rendimiento mensual en porcentaje
		}

		arrPerformanceLabels.push(`${monthNames[monthIndex]}`);
		acumulativeParticipantData.push(item ? participantTotal : 0);
		acumulativeCompanyData.push(item ? companyTotal : 0);
		// planPerformance.push(parseFloat(performanceTotal.toFixed(2))); // Guardando el rendimiento acumulado con 2 decimales
	});
	const barData: BarData = {
		labels: arrPerformanceLabels,
		datasets: []
	};

	if (!isFreelance) {
		barData.datasets.push({
			label: translate('user.myBenefit.chartLabelCompanyContributions'),
			data: acumulativeCompanyData,
			borderColor: ProjectConfig.ARCA_COLORS.GREEN_400,
			backgroundColor: ProjectConfig.ARCA_COLORS.GREEN_500,
			hoverBackgroundColor: ProjectConfig.ARCA_COLORS.GREEN_400,
			borderWidth: 2,
			borderRadius: 16,
			barPercentage: 0.8,
			categoryPercentage: 0.8
		});
	}

	barData.datasets.push({
		label: translate('user.myBenefit.chartLabelMyContributions'),
		data: acumulativeParticipantData,
		borderColor: ProjectConfig.ARCA_COLORS.GREEN_600,
		backgroundColor: ProjectConfig.ARCA_COLORS.GREEN_700,
		hoverBackgroundColor: ProjectConfig.ARCA_COLORS.GREEN_600,
		borderWidth: 2,
		borderRadius: 16,
		barPercentage: 0.8,
		categoryPercentage: 0.8
	});
	const barOptions = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				stacked: true,
				ticks: {
					callback: function (value: any, index: number) {
						// Solo muestra el nombre del mes, sin el porcentaje
						const label = arrPerformanceLabels[index];
						return label.split(' ')[0]; // Retorna solo el nombre del mes
					},
					// Responsive font size and rotation
					font: {
						size: function () {
							return window.innerWidth < 768 ? 8 : 12;
						}
					},
					// Rotate labels on mobile to prevent overlap
					maxRotation: window.innerWidth < 768 ? 45 : 0,
					minRotation: window.innerWidth < 768 ? 45 : 0
				}
			},
			y: {
				stacked: true,
				ticks: {
					font: {
						size: function () {
							return window.innerWidth < 768 ? 10 : 12;
						}
					}
				}
			}
		},
		plugins: {
			datalabels: {
				display: false
			},
			legend: {
				position: 'bottom' as const,
				labels: {
					// Smaller font size for legend on mobile
					font: {
						size: window.innerWidth < 768 ? 10 : 12
					},
					boxWidth: window.innerWidth < 768 ? 10 : 15
				}
			},
			tooltip: {
				callbacks: {
					title: function (tooltipItems: any) {
						const tooltipItem = tooltipItems[0];
						return arrPerformanceLabels[tooltipItem.dataIndex]; // Muestra el mes con el porcentaje en el hover
					},
					label: function (tooltipItem: any) {
						const label = tooltipItem.dataset.label || '';
						const value = tooltipItem.raw || 0;
						return `${label}: ${value}€`; // Muestra el valor en euros
					}
				}
			},
			title: {
				display: false
			}
		}
	};

	// Preparing data for myBenefit´s cards with PPE information
	const sumVestedRightsItems = adhesionDetails
		? adhesionDetails.map((item) => item.vestedRights).reduce((acc, curr) => acc + curr, 0)
		: 0;
	const sumTotalContributionsItems =
		adhesionDetails && adhesionDetails[0] ? adhesionDetails[0].totalContributions : 0;
	const sumCompanyContributionsItems = adhesionDetails
		? adhesionDetails.map((item) => item.companyContributions).reduce((acc, curr) => acc + curr, 0)
		: 0;
	const sumUserContributionsItems = adhesionDetails
		? adhesionDetails.map((item) => item.userContributions).reduce((acc, curr) => acc + curr, 0)
		: 0;

	// Las aportaciones se hacen en el producto de bonos. Filtramos con este criterio
	const actionsAdhesionDetails =
		adhesionDetails.length > 1
			? adhesionDetails.find(
					(item) => item.userContributions === 0 && item.companyContributions === 0
				)
			: adhesionDetails[0];

	const bondsAdhesionDetails =
		adhesionDetails.length > 1
			? adhesionDetails.find(
					(item) => item.userContributions !== 0 || item.companyContributions !== 0
				)
			: { numParticipations: 0, netAssetValue: 0 };

	const totalSavedWithNumParticipationsMultipliedByNetAssetValue = adhesionDetails
		? adhesionDetails
				.map((item) => (item.numParticipations / 1000000) * (item.netAssetValue / 1000000000))
				.reduce((acc, curr) => acc + curr, 0)
		: 0;
	const totalSaved =
		sumVestedRightsItems > 0
			? sumVestedRightsItems / 100
			: totalSavedWithNumParticipationsMultipliedByNetAssetValue;

	const { formattedCompanyContributions, formattedUserContributions } =
		formatArcaNumberCalculeCents(
			totalSaved,
			sumCompanyContributionsItems,
			sumUserContributionsItems
		);

	const formattedCompanyContributionsInNumber = Number(
		formattedCompanyContributions.replace(',', '.')
	);
	const formattedUserContributionsInNumber = Number(formattedUserContributions.replace(',', '.'));

	const sumTotalContributionsItemsFormatted = sumTotalContributionsItems / 100;

	const totalContributions =
		sumTotalContributionsItemsFormatted > 0
			? sumTotalContributionsItemsFormatted
			: formattedCompanyContributionsInNumber + formattedUserContributionsInNumber;

	// Calcular totalPerformanceGenerated, asegurando que no haya divisiones por cero
	const totalPerformanceGenerated =
		totalSaved > 0 && totalContributions > 0 ? totalSaved - totalContributions : 0;

	const totalPerformanceGeneratedPercentage = (totalPerformanceGenerated / totalSaved) * 100;

	const plantStatusList = (
		<UnorderedList listStyleType="none" ml="-1px" fontSize="18px">
			{/* Solo mostramos las contribuciones de la empresa si no es freelancer */}
			{!isFreelance && (
				<ListItem>
					<Text as="span" fontSize="16px" fontFamily={ProjectConfig.FONTS.INTER} fontWeight="400">
						{`${translate('user.myBenefit.totalCompanyContributions')}`}
					</Text>
					<Text as="span" fontSize="28px">
						{formattedCompanyContributions ?? 0}€
					</Text>
				</ListItem>
			)}
			<ListItem>
				<Text as="span" fontSize="16px" fontFamily={ProjectConfig.FONTS.INTER} fontWeight="400">
					{`${translate('user.myBenefit.totalParticipantContributions')}`}
				</Text>
				<Text as="span" fontSize="28px">
					{formattedUserContributions ?? 0}€
				</Text>
			</ListItem>
		</UnorderedList>
	);

	return (
		<div>
			{loadingAdhesionDetails ? (
				<Box h="80dvh" display="flex" justifyContent="center" alignItems="center">
					<LoadingViewSpinner />
				</Box>
			) : (
				<div>
					{showToast && (
						<GenericToast
							title={toastInfo?.title}
							description={toastInfo?.description}
							status={toastInfo?.status}
							showToast={showToast}
							duration={6000}
						/>
					)}

					<Box
						display={{ base: 'block', md: 'flex' }}
						justifyContent="space-between"
						alignItems="center"
						mb={{ base: 4, md: 0 }}>
						<Text
							fontSize={{ base: '24px', md: '48px' }}
							fontWeight="bold"
							mb={{ base: 2, md: 0 }}
							fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}>
							{translate('admin.home.hello')}
							<Text as="span" color={ProjectConfig.ARCA_COLORS.GREEN_500}>
								{userName}
							</Text>
						</Text>

						<Text
							fontSize={{ base: '16px' }}
							fontWeight="bold"
							p={{ base: '10px 15px', md: '4' }}
							borderRadius="8px"
							bg={{ base: 'white', md: 'transparent' }}
							boxShadow={{ base: 'sm', md: 'none' }}
							width={{ base: 'fit-content', md: 'auto' }}
							mx={{ base: 'auto', md: 0 }}
							mt={{ base: 2, md: 0 }}>
							{translate('user.myBenefit.updateDate')}{' '}
							<Text as="span" color={ProjectConfig.ARCA_COLORS.GREEN_500}>
								{adhesionDetails.length > 0
									? formatNumberToDate(adhesionDetails[0].day)
									: translate('user.myBenefit.pendingFirstContribution')}
							</Text>
						</Text>
					</Box>

					<SectionTitle text={translate('user.myBenefit.companyData')} />

					<Grid
						templateAreas={`"slo00 slo00 slo00 slo00 slo00"
										"slo07 slo07 slo07 slo07 slo07"
										"eBooster eBooster eBooster eBooster eBooster"`}
						gridTemplateRows={'minmax(200px,auto) 400px'}
						gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr'}
						h="minmax(90vh, auto)"
						color="blackAlpha.700">
						<GridItem area={'slo00'}>
							<SimpleGrid spacing="16px" columns={{ base: 1, lg: 5 }} minChildWidth="200px" h="90%">
								<BenUserCard
									heading={translate('user.myBenefit.totalContributions')}
									cardBody={
										<Text as="span" fontSize="32px" color={ProjectConfig.ARCA_COLORS.GREEN_600}>
											{`${formatArcaNumber(totalSaved)}€`}
										</Text>
									}
									infoIconVisible={true}
									infoIconText={translate('user.myBenefit.totalContributionsIconText')}
									actionsCount={formatNumParticipations(
										actionsAdhesionDetails?.numParticipations || 0
									)}
									netAssetValue={formatNetAssetValue(actionsAdhesionDetails?.netAssetValue || 0)}
									boundsCount={formatNumParticipations(
										bondsAdhesionDetails?.numParticipations || 0
									)}
									boundsAssetValue={formatNetAssetValue(bondsAdhesionDetails?.netAssetValue || 0)}
								/>

								<BenUserCard
									heading={`${translate('user.myBenefit.contributionsInformation')} `}
									cardBody={plantStatusList}
									infoIconVisible={true}
									infoIconText={translate('user.myBenefit.totalSavingsIconText')}
									isClickable={true}
									path="/dashboard/my-contributions"
								/>

								<BenUserCard
									heading={`${translate('user.myBenefit.totalPerformanceGenerated')}`}
									cardBody={
										<>
											<Text as="span" fontSize="24px">
												{`${formatArcaNumber(totalPerformanceGeneratedPercentage)}%`}
											</Text>
											<br />
											{`${formatArcaNumber(totalPerformanceGenerated)}€`}
										</>
									}
									infoIconVisible={true}
									infoIconText={translate('user.myBenefit.updatedPerformanceIconText')}
								/>
							</SimpleGrid>
						</GridItem>

						<GridItem area={'slo07'}>
							<SectionTitle text={translate('user.myBenefit.tableTitle')} />
							{loadingChart ? (
								<Box h="380px" display="flex" justifyContent="center" alignItems="center">
									<LoadingViewSpinner height="100px" width="100px" />
								</Box>
							) : (
								<Box
									w="100%"
									h={{ base: '400px', md: '400px' }}
									backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
									borderRadius="12px"
									textAlign="end"
									p={{ base: '10px 10px 110px 10px', md: '16px 24px 64px 24px' }}>
									<Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
										<SelectCurrentYear
											selectedYear={selectedYear}
											setSelectedYear={setSelectedYear}
											startYear={startYear}
										/>
									</Box>

									<Box w="100%" h="100%" overflow="hidden" mb={{ base: 4, md: 0 }}>
										<Suspense fallback={<LoadingViewSpinner height="100px" width="100px" />}>
											<BarChart options={barOptions} data={barData} />
										</Suspense>
									</Box>
								</Box>
							)}
						</GridItem>

						<GridItem area={'eBooster'} bg={ProjectConfig.ARCA_COLORS.WHITE}>
							<ConfidenceBoosterLiteComponent />
						</GridItem>
					</Grid>

					{showTaxBenefitModal && (
						<TaxdownBenefitModal
							isOpen={showTaxBenefitModal}
							onClose={() => {
								setShowTaxBenefitModal(false);
							}}
							annualTaxBenefit={annualTaxBenefit}
							featureFlagName="taxdown-benefit-modal-feature"
							translate={translate}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default UserMyBenefit;
