/**
 * Enum for filtering users by their onboarding status
 */
export enum UserOnboardingStatusFilter {
	CONFIRMED_CONTRIBUTING = 'CONFIRMED_CONTRIBUTING',
	PENDING_REGISTRATION = 'PENDING_REGISTRATION',
	BENEFIT_REJECTED = 'BENEFIT_REJECTED'
}

const findParticipantsContributionData = async (
	token: string,
	page: number,
	limit: number,
	year: number,
	statusFilter?: UserOnboardingStatusFilter | null
) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/calculators/participants-contributions`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify({ page, limit, year, statusFilter })
		}
	);

	return response.json();
};

export default findParticipantsContributionData;
