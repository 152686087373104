import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Button,
	Box
} from '@chakra-ui/react';
import ProjectConfig from '../../constants';

interface ContactSupportModalProps {
	isOpen: boolean;
	onClose: () => void;
	isMobileScreen: boolean;
	translate: (key: string) => string;
	onOpenAIAssistant?: () => void;
}

const ContactSupportModal: React.FC<ContactSupportModalProps> = ({
	isOpen,
	onClose,
	isMobileScreen,
	translate,
	onOpenAIAssistant
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size={isMobileScreen ? 'full' : 'md'}>
			<ModalOverlay />
			<ModalContent
				position="fixed"
				bottom={isMobileScreen ? '0' : '120px'}
				right={isMobileScreen ? '0' : '20px'}
				left={isMobileScreen ? '0' : 'auto'}
				margin={isMobileScreen ? '0' : 'auto'}
				borderRadius={isMobileScreen ? '0' : 'md'}
				borderTopRadius={isMobileScreen ? 'md' : undefined}
				borderBottomRadius={isMobileScreen ? '0' : undefined}
				maxW={isMobileScreen ? '100%' : 'md'}
				maxH={isMobileScreen ? '50vh' : undefined}>
				<ModalHeader display="flex" justifyContent="space-between" alignItems="center">
					{translate('general.chat.title')}
					<ModalCloseButton position="static" ml={2} />
				</ModalHeader>
				<ModalBody>
					<Box mb={4}>{translate('general.chat.message')}</Box>
					<Box display="flex" flexDirection="column" gap={3}>
						<Button
							onClick={onOpenAIAssistant}
							width="100%"
							colorScheme="blue"
							size={isMobileScreen ? 'lg' : 'md'}
							py={isMobileScreen ? 6 : 4}
							display="flex"
							alignItems="center">
							<Box mr={2} display="flex" alignItems="center">
								<Box
									width="24px"
									height="24px"
									borderRadius="50%"
									overflow="hidden"
									display="flex"
									justifyContent="center"
									alignItems="center"></Box>
							</Box>
							🤖 {translate('general.chat.ai') || 'Prueba nuestro asistente Noel·IA'}
						</Button>
						<Button
							as="a"
							href={`mailto:${ProjectConfig.ARCA_CONTACT.EMAIL}`}
							width="100%"
							colorScheme="pink"
							size={isMobileScreen ? 'lg' : 'md'}
							py={isMobileScreen ? 6 : 4}>
							✉️ {translate('general.chat.contact')}
						</Button>
						<Button
							as="a"
							href={`tel:${ProjectConfig.ARCA_CONTACT.PHONE}`}
							width="100%"
							colorScheme="green"
							size={isMobileScreen ? 'lg' : 'md'}
							py={isMobileScreen ? 6 : 4}>
							📞 {translate('general.chat.call') + ' ' + ProjectConfig.ARCA_CONTACT.PHONE}
						</Button>
						<Button
							as="a"
							href={`https://api.whatsapp.com/send/?phone=${ProjectConfig.ARCA_CONTACT.PHONE_FOR_WHATSAPP_LINK}&text&type=phone_number&app_absent=0`}
							target="_blank"
							rel="noopener noreferrer"
							width="100%"
							colorScheme="whatsapp"
							size={isMobileScreen ? 'lg' : 'md'}
							py={isMobileScreen ? 6 : 4}
							display="flex"
							alignItems="center">
							<Box mr={2} display="flex" alignItems="center">
								<Box
									width="24px"
									height="24px"
									borderRadius="50%"
									overflow="hidden"
									display="flex"
									justifyContent="center"
									alignItems="center">
									<img
										src="/imgs/whatsapp-icon.png"
										alt="WhatsApp"
										width="100%"
										height="100%"
										style={{ objectFit: 'cover' }}
									/>
								</Box>
							</Box>
							{translate('general.chat.whatsapp') || 'Contactar por WhatsApp'}
						</Button>
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ContactSupportModal;
