import React, { useState, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import {
	Box,
	Text,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Spinner,
	Flex
} from '@chakra-ui/react';
import ArcaSlider from '../Sliders/ArcaSlider';
import ArcaPrimaryButton from '../Buttons/ArcaPrimaryButton';
import ProjectConfig from '../../constants';
import { ChartOptions, Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import SectionTitle from '../Titles/SectionTitle';
import { formatArcaNumber } from '../../utils/formatArcaNumbers';
import { triggerMetricsWaveEvent } from '../../metricswave';
import { MetricswaveEvents, MetricswaveEventsAndParams } from '../../metricswave/MetricswaveEnums';
import { ArcaContext } from '../../context/ArcaContext.context';
import getCurrentUser from '../../api/users/getCurrentUser';
import { CurrentUserI } from '../../api/users/interfaces/Users ';
import getCompanyInfoEppConfig from '../../api/companies/getCompanyInfoEppConfig';
import { CompanyInfoI } from '../../api/companies/interfaces/CompanyInfo';
import {
	CompanyEppConfigI,
	EmployerContributionTypeEnum
} from '../../api/companies/interfaces/CompanyEppConfig';
import getOptionByName from '../../api/generic-api/getOptionByName';
import { calculateFutureValue } from '../../utils/calculateFutureValue';

interface CompanyInfoAndEppConfigI {
	company: CompanyInfoI;
	companyEppConfig: CompanyEppConfigI;
}

// Eliminar el registro global
ChartJS.register(...registerables);

interface FinancialPlannerPensionSimulatorProps {
	currentAge: number; // Si currentAge es null o 0 se mostrará un slider para seleccionar la edad.
}

const FinancialPlannerPensionSimulator: React.FC<FinancialPlannerPensionSimulatorProps> = ({
	currentAge
}) => {
	const { token, companyId } = useContext(ArcaContext);

	// Si currentAge es null o 0 se usará el estado interno para determinar la edad seleccionada.
	const [selectedAge, setSelectedAge] = useState<number>(
		currentAge && currentAge > 0 ? currentAge : 30
	);
	const effectiveAge = currentAge && currentAge > 0 ? currentAge : selectedAge;

	const [currentUser, setCurrentUser] = useState<CurrentUserI | null>(null);
	const [currentCompanyAndEppConfig, setCurrentCompanyAndEppConfig] =
		useState<CompanyInfoAndEppConfigI>();

	// Estado para la aportación del empleado
	const [employeeContribution, setEmployeeContribution] = useState<number>(50);
	const [minContributionSlider, setMinContributionSlider] = useState<number>(0);
	const [companyContribution, setCompanyContribution] = useState<number>(50);
	const [historicalReturn, setHistoricalReturn] = useState<number>(5);

	// Estados para controlar el tamaño de la gráfica
	const [chartWidth, setChartWidth] = useState<number>(800);
	const [chartHeight, setChartHeight] = useState<number>(400);

	const retirementAge = 67;
	// Se calcula el número de años de ahorro desde la edad efectiva hasta la jubilación.
	const yearsSaving = retirementAge - effectiveAge;
	// En la simulación, la aportación mensual total (empresa + empleado) se utiliza para el ahorro con capitalización compuesta
	const totalMonthlyContribution = companyContribution + employeeContribution;

	// Calcula el valor futuro acumulado de forma simple (sin capitalización compuesta)
	const calculateSimpleSavings = (years: number, monthlyContribution: number) => {
		return monthlyContribution * 12 * years;
	};

	// Valor final acumulado a los 67 usando capitalización compuesta
	const finalCompoundValue = calculateFutureValue(
		0,
		yearsSaving,
		totalMonthlyContribution,
		historicalReturn
	);
	// Valor final acumulado a los 67 usando capitalización simple
	const finalSimpleValue = calculateSimpleSavings(yearsSaving, employeeContribution);
	// Ahorro simple mensual necesario para alcanzar ese valor, sin intereses
	const requiredSimpleMonthly = finalCompoundValue / (yearsSaving * 12);

	// Generamos las etiquetas de la gráfica: desde la edad efectiva hasta los 67 (ambos inclusive)
	const chartLabels = Array.from(
		{ length: retirementAge - effectiveAge + 1 },
		(_, i) => effectiveAge + i
	);

	// Generamos los datos para cada punto.
	const compoundData = chartLabels.map((age) =>
		calculateFutureValue(0, age - effectiveAge, totalMonthlyContribution, historicalReturn)
	);
	const simpleData = chartLabels.map((age) =>
		calculateSimpleSavings(age - effectiveAge, employeeContribution)
	);

	const chartData = {
		labels: chartLabels,
		datasets: [
			{
				label: 'Plan de pensiones de empleo (interés compuesto)',
				data: compoundData,
				borderColor: ProjectConfig.ARCA_COLORS.GREEN_600,
				backgroundColor: ProjectConfig.ARCA_COLORS.GREEN_600,
				pointRadius: 3,
				pointHoverRadius: 12,
				pointHoverBackgroundColor: ProjectConfig.ARCA_COLORS.GREEN_800,
				pointHoverBorderColor: ProjectConfig.ARCA_COLORS.WHITE,
				pointHoverBorderWidth: 2,
				fill: false
			},
			{
				label: 'Debajo del colchón (Ahorro Simple)',
				data: simpleData,
				borderColor: ProjectConfig.ARCA_COLORS.RED_500,
				backgroundColor: ProjectConfig.ARCA_COLORS.RED_500,
				pointRadius: 3,
				pointHoverRadius: 12,
				pointHoverBackgroundColor: ProjectConfig.ARCA_COLORS.RED_800,
				pointHoverBorderColor: ProjectConfig.ARCA_COLORS.WHITE,
				pointHoverBorderWidth: 2,
				fill: false
			}
		]
	};

	const options: ChartOptions<'line'> = {
		scales: {
			x: {
				grid: {
					display: false
				}
			},
			y: {
				beginAtZero: true
			}
		},
		interaction: {
			mode: 'index' as const,
			intersect: false
		},
		plugins: {
			tooltip: {
				mode: 'index' as const,
				intersect: false,
				callbacks: {
					// Se añade "Años" al label del eje X en el título
					title: (tooltipItems) => {
						const label = tooltipItems[0].label;
						return label + ' años';
					},
					// Se añade "€" al valor numérico de cada dato
					label: (tooltipItem) => {
						const datasetLabel = tooltipItem.dataset.label || '';
						const value = formatArcaNumber(tooltipItem.parsed.y);
						return datasetLabel ? `${datasetLabel}: ${value} €` : `${value} €`;
					}
				}
			},
			datalabels: {
				clip: false,
				offset: -45,
				display: function (context: any) {
					const dataLabel = context.chart.data.labels[context.dataIndex];
					const lastIndex = context.dataset.data.length - 1;
					const targetAge = effectiveAge + 10;
					return context.dataIndex === lastIndex || dataLabel === targetAge;
				},
				formatter: function (value: number) {
					return value > 0 ? `${formatArcaNumber(value)} €` : '';
				},
				anchor: 'end',
				align: 'right',
				color: '#000',
				font: { weight: 'bold' }
			}
		},
		elements: {
			point: {
				radius: 3,
				hoverRadius: 4,
				borderWidth: 1
			}
		}
	};

	// Estados para controlar la visualización del gráfico y el spinner de carga
	const [showChart, setShowChart] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [testSliderValue, setTestSliderValue] = useState<number>(100);

	// Efecto para obtener los datos del usuario y la configuración de la empresa
	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				// Fetch user and company data in parallel
				const [userResponse, companyResponse] = await Promise.all([
					getCurrentUser(token),
					getCompanyInfoEppConfig(token, companyId)
				]);

				const currentUserData = userResponse.data as CurrentUserI;
				const companyData = companyResponse.data as CompanyInfoAndEppConfigI;

				setCurrentUser(currentUserData);
				setCurrentCompanyAndEppConfig(companyData);

				// Extract company config values
				const {
					employerContributionType,
					employerContributionAmount,
					employeeContributionRequired,
					employeeContributionMinimum
				} = companyData.companyEppConfig;

				// Calculate company contribution
				const companyContributionAmount =
					employerContributionType === EmployerContributionTypeEnum.FIXED_AMOUNT
						? Number(employerContributionAmount)
						: ((Number(employerContributionAmount) / 100) *
								(currentUserData?.user?.grossAnnualSalary ?? 0)) /
							12;
				setCompanyContribution(companyContributionAmount);

				// Set minimum contribution if required
				const minContribution = employeeContributionRequired ? employeeContributionMinimum : 0;
				setMinContributionSlider(Number(minContribution));

				// Set employee contribution (use user's configured amount if it's above minimum, otherwise use minimum)
				const userConfiguredContribution =
					Number(currentUserData?.userEppConfig?.data?.pensionContribution) ?? 50;
				const finalContribution = Math.max(userConfiguredContribution, minContribution);
				// Redondear al múltiplo de 10 más cercano
				const roundedContribution = Math.round(finalContribution / 10) * 10;
				setEmployeeContribution(roundedContribution);
			} catch (error) {
				console.error('Error fetching initial data:', error);
			}
		};

		fetchInitialData();
	}, [token, companyId]);

	// Efecto para obtener el rendimiento histórico (se ejecuta solo cuando el token cambia)
	useEffect(() => {
		const getHistoricalProductReturn = async () => {
			const optionResponse = await getOptionByName(token, 'historical-product-return');
			const historicalProductReturn = Number(JSON.parse(optionResponse.data?.value));
			setHistoricalReturn(historicalProductReturn);
		};
		getHistoricalProductReturn();
	}, [token]);

	// Manejador del botón "Calcular gráfico"
	const handleCalculateChartClick = () => {
		setIsLoading(true);
		triggerMetricsWaveEvent(
			MetricswaveEventsAndParams[MetricswaveEvents.SIMULATORS_PENSION_SHOW_CHART].event,
			currentUser?.user?.id ?? '',
			{}
		);
		setTimeout(() => {
			setIsLoading(false);
			setShowChart(true);
		}, 2000);
	};

	return (
		<Box height="100%">
			<SectionTitle text="Simulador financiero plan de pensiones" />

			{/* Si currentAge es null o 0, mostramos un slider para seleccionar la edad */}
			{(!currentAge || currentAge === 0) && (
				<Box width={{ base: '95%', sm: '85%', md: '70%', lg: '50%' }} mx="auto" mt={2} mb={4}>
					<Text>Selecciona tu edad actual:</Text>
					<ArcaSlider
						value={selectedAge}
						min={18}
						max={66}
						step={1}
						onChange={setSelectedAge}
						unit="años"
					/>
				</Box>
			)}

			<Box width={{ base: '95%', sm: '85%', md: '70%', lg: '50%' }} mx="auto" mt={2} mb={4}>
				<Text>
					Tu edad actual es:{' '}
					<Text
						as="span"
						mt={4}
						fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
						fontSize="xl"
						fontWeight="bold">
						{effectiveAge}
					</Text>
					, la edad de jubilación es:{' '}
					<Text
						as="span"
						mt={4}
						fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
						fontSize="xl"
						fontWeight="bold">
						{retirementAge}
					</Text>
				</Text>
				<Text>
					Aportación mensual de la empresa:{' '}
					<Text
						as="span"
						mt={4}
						fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
						fontSize="xl"
						fontWeight="bold">
						{formatArcaNumber(companyContribution)} €
					</Text>
				</Text>
				<Text>
					Tu aportación mensual de empleado:{' '}
					<Text
						as="span"
						mt={4}
						fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
						fontSize="xl"
						fontWeight="bold">
						{formatArcaNumber(employeeContribution)} €
					</Text>
				</Text>
				<ArcaSlider
					value={employeeContribution}
					min={minContributionSlider}
					max={500}
					step={10}
					onChange={(value) => {
						const roundedValue = Math.round(value / 10) * 10; // Asegura que el valor sea múltiplo de 10
						setEmployeeContribution(roundedValue);
					}}
				/>
				{showChart && !isLoading && (
					<>
						<Text mt={2} fontSize="sm" color="gray.600">
							Entrando al plan podrías alcanzar{' '}
							<Text
								as="span"
								mt={4}
								fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
								fontSize="xl"
								fontWeight="bold">
								{formatArcaNumber(finalCompoundValue)} €
							</Text>{' '}
						</Text>
						<Text mt={2} fontSize="sm" color="gray.600">
							Por tu cuenta, rechazando el apoyo de la empresa, sólo ahorrarás{' '}
							<Text
								as="span"
								mt={4}
								fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
								fontSize="xl"
								fontWeight="bold">
								{formatArcaNumber(finalSimpleValue)} €
							</Text>{' '}
							en el mismo tiempo
						</Text>{' '}
					</>
				)}
			</Box>

			<Box display="flex" justifyContent="center" mb={4}>
				{!showChart && !isLoading && (
					<ArcaPrimaryButton onClick={handleCalculateChartClick}>
						Calcular gráfico
					</ArcaPrimaryButton>
				)}
				{isLoading && (
					<Spinner
						size="lg"
						thickness="7px"
						speed="1s"
						emptyColor={ProjectConfig.ARCA_COLORS.GREEN_400}
						color={ProjectConfig.ARCA_COLORS.GREEN_800}
					/>
				)}
			</Box>

			<Box mb={10}>
				{showChart && (
					<>
						<Box width="100%" px={2}>
							<Line
								data={chartData}
								options={{
									...options,
									responsive: true,
									maintainAspectRatio: true,
									aspectRatio: window.innerWidth < 768 ? 1 : 2
								}}
								plugins={[ChartDataLabels]}
							/>
						</Box>
						<Text fontSize="sm" color="gray.600" px={2}>
							* Los datos corresponden con una simulación con un rendimiento medio histórico en los
							últimos años del {historicalReturn}% anual.
						</Text>
						<Text fontSize="sm" color="gray.600" px={2}>
							* Rentabilidades pasadas no garantizan rentabilidades futuras.
						</Text>
					</>
				)}
			</Box>
			<Box overflowX="auto" maxW="100%" mt={6} mx="auto" mb={8}>
				{/* Título con el nombre de la empresa sobre la tabla */}
				<Text fontSize={{ base: 'md', md: 'lg' }} fontWeight="medium" mb={3} px={2}>
					Ventajas del Plan de Pensiones de {currentCompanyAndEppConfig?.company.name}
				</Text>

				<Table variant="simple" size={{ base: 'sm', md: 'md' }} style={{ width: '100%' }}>
					<Thead>
						<Tr>
							<Th width={{ base: '15px', md: '30px' }} textAlign="center" px={{ base: 0, md: 2 }}>
								<Text fontWeight="bold">#</Text>
							</Th>
							<Th width={{ base: '65px', md: '110px' }} px={{ base: 0, md: 2 }}>
								<Text fontWeight="bold" fontSize={{ base: 'xs', md: 'sm' }}>
									Ventajas
								</Text>
							</Th>
							<Th px={{ base: 1, md: 3 }}>
								<Text fontWeight="bold" fontSize={{ base: 'xs', md: 'sm' }}>
									Descripción
								</Text>
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							<Td textAlign="center" px={{ base: 0, md: 2 }}>
								1
							</Td>
							<Td px={{ base: 0, md: 2 }}>
								<Flex alignItems="center">
									<Text fontSize={{ base: 'md', md: 'lg' }} mr={{ base: 1, md: 2 }}>
										💪🏽
									</Text>
									<Text
										fontWeight="bold"
										fontSize={{ base: 'xs', md: 'sm' }}
										style={{ hyphens: 'auto' }}>
										Aportaciones de tu empresa
									</Text>
								</Flex>
							</Td>
							<Td
								fontSize={{ base: 'xs', md: 'sm' }}
								px={{ base: 1, md: 3 }}
								style={{ hyphens: 'auto', overflowWrap: 'break-word' }}>
								Las contribuciones de tu empresa aumentan significativamente tus ahorros.
							</Td>
						</Tr>
						<Tr>
							<Td textAlign="center" px={{ base: 0, md: 2 }}>
								2
							</Td>
							<Td px={{ base: 0, md: 2 }}>
								<Flex alignItems="center">
									<Text fontSize={{ base: 'md', md: 'lg' }} mr={{ base: 1, md: 2 }}>
										💰
									</Text>
									<Text
										fontWeight="bold"
										fontSize={{ base: 'xs', md: 'sm' }}
										style={{ hyphens: 'auto' }}>
										El ahorro trabaja por ti
									</Text>
								</Flex>
							</Td>
							<Td
								fontSize={{ base: 'xs', md: 'sm' }}
								px={{ base: 1, md: 3 }}
								style={{ hyphens: 'auto', overflowWrap: 'break-word' }}>
								El dinero en el plan va generando rendimiento a lo largo del tiempo.
							</Td>
						</Tr>
						<Tr>
							<Td textAlign="center" px={{ base: 0, md: 2 }}>
								3
							</Td>
							<Td px={{ base: 0, md: 2 }}>
								<Flex alignItems="center">
									<Text fontSize={{ base: 'md', md: 'lg' }} mr={{ base: 1, md: 2 }}>
										💸
									</Text>
									<Text
										fontWeight="bold"
										fontSize={{ base: 'xs', md: 'sm' }}
										style={{ hyphens: 'auto' }}>
										No tributas IRPF
									</Text>
								</Flex>
							</Td>
							<Td
								fontSize={{ base: 'xs', md: 'sm' }}
								px={{ base: 1, md: 3 }}
								style={{ hyphens: 'auto', overflowWrap: 'break-word' }}>
								Aprovechas ventajas fiscales al no tributar por tus aportaciones.
							</Td>
						</Tr>
						<Tr>
							<Td textAlign="center" px={{ base: 0, md: 2 }}>
								4
							</Td>
							<Td px={{ base: 0, md: 2 }}>
								<Flex alignItems="center">
									<Text fontSize={{ base: 'md', md: 'lg' }} mr={{ base: 1, md: 2 }}>
										📊
									</Text>
									<Text
										fontWeight="bold"
										fontSize={{ base: 'xs', md: 'sm' }}
										style={{ hyphens: 'auto' }}>
										La magia del interés compuesto
									</Text>
								</Flex>
							</Td>
							<Td
								fontSize={{ base: 'xs', md: 'sm' }}
								px={{ base: 1, md: 3 }}
								style={{ hyphens: 'auto', overflowWrap: 'break-word' }}>
								Decía Albert Einstein: "El interés compuesto es la octava maravilla del mundo...
								Aquel que lo entiende y lo utiliza, gana dinero; aquel que no, lo paga".
							</Td>
						</Tr>
					</Tbody>
				</Table>
			</Box>
		</Box>
	);
};

export default FinancialPlannerPensionSimulator;
