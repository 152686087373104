import { useEffect } from 'react';

export const trackVisit = (userId: string | null) => {
	if (window.metricswave !== undefined && typeof window.metricswave.setUser === 'function') {
		if (process.env.NODE_ENV === 'production') {
			window.metricswave.setUser(userId);
		}
	}
};

export const useTrackVisit = (userId: string | null): void => {
	useEffect(() => {
		trackVisit(userId);
	}, [userId]);
};
