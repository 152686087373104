export enum ContributionPeriodicityEnum {
	MONTHLY = 'MONTHLY'
}

export enum EmployerContributionTypeEnum {
	FIXED_AMOUNT = 'FIXED_AMOUNT',
	GROSS_SALARY_PERCENTAGE = 'GROSS_SALARY_PERCENTAGE'
}

export enum EmployeeContributionTypeEnum {
	FIXED = 'FIXED',
	PERCENTAGE = 'PERCENTAGE'
}

export interface CaserPlanDataI {
	PLAN: number;
	FONDO: number;
	SUBPLAN: number;
}

export interface CompanyEppConfigI {
	id: string;
	createdAt: string;
	updatedAt: string;
	companyId: string;
	promoterCode: number;
	newPlan: boolean;
	transferPlan: boolean;
	contributionDay: number;
	contributionPeriodicity: ContributionPeriodicityEnum;
	uniformConditions: boolean;
	salaryBracket: any;
	seniorityBracket: any;
	documents: any;
	employerContributionType: EmployerContributionTypeEnum;
	employerContributionAmount: number;
	employeeContributionRequired: boolean;
	employeeContributionType: EmployeeContributionTypeEnum;
	employeeContributionMinimum: number;
	voluntaryEmployeeContributionsAllowed: boolean;
	caserPlanData: CaserPlanDataI;
	caserPlanSelected: string;
	linkRegulation: string;
	linkAnnex: string;
}

const EMPTY_COMPANY_EPP_CONFIG: CompanyEppConfigI = {
	id: '',
	createdAt: '',
	updatedAt: '',
	companyId: '',
	promoterCode: 0,
	newPlan: false,
	transferPlan: false,
	contributionDay: 20,
	contributionPeriodicity: ContributionPeriodicityEnum.MONTHLY,
	uniformConditions: false,
	salaryBracket: null,
	seniorityBracket: null,
	documents: null,
	employerContributionType: EmployerContributionTypeEnum.FIXED_AMOUNT,
	employerContributionAmount: 0,
	employeeContributionRequired: false,
	employeeContributionType: EmployeeContributionTypeEnum.FIXED,
	employeeContributionMinimum: 0,
	voluntaryEmployeeContributionsAllowed: false,
	caserPlanData: {
		PLAN: 0,
		FONDO: 0,
		SUBPLAN: 0
	},
	caserPlanSelected: '',
	linkRegulation: '',
	linkAnnex: ''
};

export default EMPTY_COMPANY_EPP_CONFIG;
