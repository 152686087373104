import React from 'react';
import { Button, ButtonProps, Spinner } from '@chakra-ui/react';
import ProjectConfig from '../../constants';

interface ArcaDestructiveButtonProps extends ButtonProps {
	color?: string;
	backgroundColor?: string;
	_hover?: object;
	_active?: object;
	_disabled?: object;
	onClick?: () => void;
	children: React.ReactNode;
	icon?: string | React.ReactNode;
	isLoading?: boolean;
}

const ArcaDestructiveButton: React.FC<ArcaDestructiveButtonProps> = ({
	color = '#FFFFFF',
	backgroundColor = '#FF6161',
	_hover = {
		bg: '#FF1A1A',
		color: '#FFFFFF'
	},
	_active = {
		bg: '#E60000',
		color: '#FFFFFF'
	},
	_disabled = {
		bg: '#FFD1D1',
		color: '#FFFFFF',
		cursor: 'not-allowed',
		opacity: 0.7
	},
	onClick,
	children,
	icon,
	isLoading = false,
	...props
}) => {
	return (
		<Button
			color={color}
			backgroundColor={backgroundColor}
			_hover={_hover}
			_active={_active}
			_disabled={_disabled}
			onClick={onClick}
			w="fit-content"
			px="30px"
			mx="auto"
			borderRadius="8px"
			isDisabled={isLoading || props.isDisabled}
			{...props}>
			{isLoading ? <Spinner size="sm" mr="2" /> : null}
			{children}
			{icon && typeof icon === 'string' ? (
				<img src={icon} alt="icon" style={{ marginLeft: '10px', width: '35px', height: '35px' }} />
			) : icon ? (
				<span style={{ marginLeft: '10px' }}>{icon}</span>
			) : null}
		</Button>
	);
};

export default ArcaDestructiveButton;
