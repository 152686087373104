import React, { useContext, useEffect, useState } from 'react';
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Box,
	Tfoot,
	Skeleton,
	useDisclosure,
	Stack,
	Text,
	useBreakpointValue,
	Select,
	HStack,
	RadioGroup,
	Radio,
	Flex
} from '@chakra-ui/react';
import { ArcaContext } from '../../../context/ArcaContext.context';
import inviteEmployees from '../../../api/companies/inviteEmployees';
import GenericToast, { ToastStatusEnum } from '../../../components/ChakraUi/Toasts/GenericToast';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import ProjectConfig from '../../../constants';
import LoadingViewSpinner from '../../../components/ChakraUi/Spinners/LoadingViewSpinner';
import Pagination from '../../../components/ChakraUi/Pagination';
import findParticipantsContributionData, {
	UserOnboardingStatusFilter
} from '../../../api/calculators/findParticipantsContributionData';
import GenericModal from '../../../components/ChakraUi/Modals/GenericModal';
import { formatArcaNumber } from '../../../utils/formatArcaNumbers';
import SectionTitle from '../../../components/Titles/SectionTitle';
import ArcaPrimaryButton from '../../../components/Buttons/ArcaPrimaryButton';
import exportStaffTable from '../../../api/emails/exportStaffTable';
import GrossSalaryInputComponent from '../../../components/GrossSalaryInputComponent';
import SelectCurrentYear from '../../../components/SelectCurrentYear';
import PayfitFileModal from '../../../components/ChakraUi/Modals/PayfitFileModal';
import checkFeatureFlagByName from '../../../api/feature-flags/checkFeatureFlagByName';
import { triggerMetricsWaveEvent } from '../../../metricswave';
import { METRICS_WAVE_FUNNEL_PAYFIT_FEATURE } from '../../../metricswave/MetricswaveEnums';
import MobileStaffTable from '../../../components/tables/MobileStaffTable';

interface PlantillaTabProps {
	startYear?: number;
}

const PlantillaTab: React.FC<PlantillaTabProps> = ({ startYear }) => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	const [initialLoading, setInitialLoading] = useState<boolean>(true);
	const [isInviteLoading, setIsInviteLoading] = useState<boolean>(false);
	const [isExportLoading, setIsExportLoading] = useState<boolean>(false);
	const [showExportPayfitModal, setShowExportPayfitModal] = useState<boolean>(false);
	const [usersInputCalculations, setUsersInputCalculations] = useState<any[]>([]);
	const [managementAccount, setManagementAccount] = useState<any>(null);
	const [showExportPayfitButton, setShowExportPayfitButton] = useState<boolean>(false);
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toastInfo, setToastInfo] = useState<any>({
		title: '',
		description: '',
		status: ''
	});
	const { isOpen: isOpenGeneric, onOpen: onOpenGeneric, onClose: onCloseGeneric } = useDisclosure();

	const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
	const { token, companyId, userId } = useContext(ArcaContext);

	const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
	const isMobile = useBreakpointValue({ base: true, md: false });

	const [statusFilter, setStatusFilter] = useState<UserOnboardingStatusFilter | null>(null);

	useEffect(() => {
		const checkFeatureFlag = async () => {
			const checkFeatureFlag = await checkFeatureFlagByName(
				token,
				companyId,
				userId,
				'payfit_file_feature'
			);
			const { isActive } = checkFeatureFlag?.data;
			setShowExportPayfitButton(isActive);
		};
		checkFeatureFlag();
	}, [token, companyId, userId]);

	useEffect(() => {
		const limitSelected = 10;

		findParticipantsContributionData(
			token,
			currentPage,
			limitSelected,
			selectedYear,
			statusFilter
		).then((res) => {
			const managementAccount = res?.data?.items.find((item: any) =>
				item.user.roles.includes('management')
			);

			if (managementAccount) {
				setManagementAccount(managementAccount);
			}

			const itemsWithoutManagementAccount = res.data.items.filter(
				(item: any) => item !== managementAccount
			);
			setUsersInputCalculations(itemsWithoutManagementAccount);

			setTotalPages(res.data.totalPages);
			setIsPageLoading(false);
			setInitialLoading(false);
		});
	}, [token, companyId, currentPage, selectedYear, statusFilter]);

	const inviteEmployeesTitle = translate('admin.staff.inviteEmployeesTitle');
	const inviteEmployeesSubtitle = translate('admin.staff.inviteEmployeesSubtitle');

	const handleOpenGenericModal = () => {
		onOpenGeneric();
	};

	const handleClickInviteEmployees = async (emails?: string) => {
		const emailsToInvite = emails?.split(',').map((email) => email.trim()) || [];

		try {
			setIsInviteLoading(true);
			const inviteEmployeesResponse = await inviteEmployees(token, companyId, emailsToInvite);

			if (!inviteEmployeesResponse.success) {
				setToastInfo({
					title: `${translate('admin.staff.inviteEmployeesToastTitle')}`,
					description: `${translate('admin.staff.inviteEmployeesToastDescription')}`,
					status: ToastStatusEnum.ERROR
				});
				setShowToast(true);
			} else {
				const totalEmployeesInvited = inviteEmployeesResponse?.data?.totalEmailsSended;

				const intitationTextSingularOrPlural =
					totalEmployeesInvited === 1
						? `${translate('admin.staff.inviteEmployeesToastSingle')}`
						: `${translate('admin.staff.inviteEmployeesToastPlural')}`;

				const sendedTextSingularOrPlural =
					totalEmployeesInvited === 1
						? `${translate('admin.staff.inviteEmployeesSendedSingle')}`
						: `${translate('admin.staff.inviteEmployeesSendedPlural')}`;

				const fullDescription =
					totalEmployeesInvited > 0
						? `${totalEmployeesInvited} ${intitationTextSingularOrPlural} ${sendedTextSingularOrPlural}`
						: `${translate('admin.staff.employeesAlreadyInvited')}`;

				setToastInfo({
					title: `${translate('admin.staff.inviteEmployeesCompleted')}`,
					description: fullDescription,
					status: ToastStatusEnum.SUCCESS
				});
				setShowToast(true);
			}
		} finally {
			setIsInviteLoading(false);
		}
	};

	const handleExportStaffTable = async () => {
		try {
			setIsExportLoading(true);
			const response = await exportStaffTable(token);

			if (response.success) {
				setToastInfo({
					title: translate('admin.staff.exportDataSuccessTitle'),
					description: translate('admin.staff.exportDataSuccessDescription'),
					status: ToastStatusEnum.SUCCESS
				});
			} else {
				setToastInfo({
					title: translate('admin.staff.exportDataErrorTitle'),
					description: translate('admin.staff.exportDataErrorDescription'),
					status: ToastStatusEnum.ERROR
				});
			}
			setShowToast(true);
		} catch (error) {
			setToastInfo({
				title: translate('admin.staff.exportDataErrorTitle'),
				description: translate('admin.staff.exportDataErrorDescription'),
				status: ToastStatusEnum.ERROR
			});
			setShowToast(true);
		} finally {
			setIsExportLoading(false);
		}
	};

	const handlePageChange = (page: number) => {
		setIsPageLoading(true);
		setCurrentPage(page);
	};

	const handleSalaryInform = async () => {
		setIsPageLoading(true);
		try {
			const limitSelected = 10;
			const res = await findParticipantsContributionData(
				token,
				currentPage,
				limitSelected,
				selectedYear,
				statusFilter
			);
			const managementAccount = res?.data?.items.find((item: any) =>
				item.user.roles.includes('management')
			);

			if (managementAccount) {
				setManagementAccount(managementAccount);
			}

			const itemsWithoutManagementAccount = res.data.items.filter(
				(item: any) => item !== managementAccount
			);
			setUsersInputCalculations(itemsWithoutManagementAccount);
		} finally {
			setIsPageLoading(false);
		}
	};

	const toggleRow = (userId: string) => {
		setExpandedRows((prev) => ({
			...prev,
			[userId]: !prev[userId]
		}));
	};

	const handleFilterChange = (newFilter: UserOnboardingStatusFilter | null) => {
		setStatusFilter(newFilter);
		setCurrentPage(1);
	};

	return (
		<div>
			{initialLoading ? (
				<Box h="80dvh" display="flex" justifyContent="center" alignItems="center">
					<LoadingViewSpinner />
				</Box>
			) : (
				<Box mt="16px">
					<Box
						display="flex"
						flexDirection={{ base: 'column', md: 'row' }}
						justifyContent="space-between"
						gap={{ base: 3, md: 0 }}>
						<SectionTitle text={translate('admin.staff.staffList')} />

						<Stack
							direction={{ base: 'column', md: 'row' }}
							spacing={{ base: 2, md: 4 }}
							width={{ base: '100%', md: 'auto' }}
							alignItems={{ base: 'flex-start', md: 'center' }}>
							<Box width={{ base: '100%', md: 'auto' }}>
								<SelectCurrentYear
									selectedYear={selectedYear}
									setSelectedYear={setSelectedYear}
									startYear={startYear}
								/>
							</Box>
							<ArcaPrimaryButton
								isLoading={isInviteLoading}
								onClick={handleOpenGenericModal}
								width={{ base: '100%', md: 'auto' }}>
								{translate('admin.staff.inviteAllEmployees')}
							</ArcaPrimaryButton>
							<Box
								display={{ base: 'none', md: 'block' }}
								mt="6px"
								height="48px"
								borderLeft="1px solid black"
								borderRadius="2px"
							/>
							<ArcaPrimaryButton
								isLoading={isExportLoading}
								onClick={handleExportStaffTable}
								width={{ base: '100%', md: 'auto' }}>
								{translate('admin.staff.exportData')}
							</ArcaPrimaryButton>
							{showExportPayfitButton && (
								<ArcaPrimaryButton
									isLoading={false}
									onClick={() => {
										const bodyParams = {
											step: METRICS_WAVE_FUNNEL_PAYFIT_FEATURE.CLICK_EXPORT_PAYFIT_BUTTON
										};
										triggerMetricsWaveEvent(
											'8daaff64-9c3b-4af2-ad2b-4655431673e3',
											userId,
											bodyParams
										);
										setShowExportPayfitModal(true);
									}}
									width={{ base: '100%', md: 'auto' }}>
									{translate('admin.staff.exportDataHHRR')}
								</ArcaPrimaryButton>
							)}
						</Stack>
					</Box>

					<Box mt="24px" mb="16px">
						<Text mb="8px" fontWeight="medium">
							{translate('admin.staff.filterByStatus')}
						</Text>
						<Select
							value={statusFilter || ''}
							onChange={(e) =>
								handleFilterChange(
									e.target.value ? (e.target.value as UserOnboardingStatusFilter) : null
								)
							}
							borderRadius="20px"
							borderColor={ProjectConfig.ARCA_COLORS.GREEN_600}
							color={ProjectConfig.ARCA_COLORS.GREEN_600}
							maxWidth="300px"
							backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}>
							<option value="">{translate('admin.staff.allUsers')}</option>
							<option value={UserOnboardingStatusFilter.CONFIRMED_CONTRIBUTING}>
								{translate('admin.staff.confirmedContributing')}
							</option>
							<option value={UserOnboardingStatusFilter.PENDING_REGISTRATION}>
								{translate('admin.staff.pendingRegistration')}
							</option>
							<option value={UserOnboardingStatusFilter.BENEFIT_REJECTED}>
								{translate('admin.staff.benefitRejected')}
							</option>
						</Select>
					</Box>

					<Box
						p={{ base: '10px', md: '16px 25px 0px 25px' }}
						backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
						borderRadius="16px"
						overflowX={{ base: 'hidden', md: 'auto' }}
						mt={4}>
						{isMobile ? (
							<>
								<MobileStaffTable
									usersInputCalculations={usersInputCalculations}
									isPageLoading={isPageLoading}
									expandedRows={expandedRows}
									toggleRow={toggleRow}
									formatArcaNumber={formatArcaNumber}
									selectedYear={selectedYear}
									translate={translate}
									handleSalaryInform={handleSalaryInform}
									token={token}
								/>
								<Box mt={4} textAlign="center">
									<Pagination
										currentPage={currentPage}
										totalPages={totalPages}
										onPageChange={handlePageChange}
									/>
								</Box>
							</>
						) : (
							<Table width="100%">
								<Thead>
									<Tr>
										<Th
											color={ProjectConfig.ARCA_COLORS.GREEN_800}
											borderBottomWidth="2px"
											borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
											textAlign="center"
											maxW="130px"
											justifySelf="flex-end"
											fontSize="10px">
											{translate('admin.staff.employeeEmail')}
										</Th>
										<Th
											color={ProjectConfig.ARCA_COLORS.GREEN_800}
											borderBottomWidth="2px"
											borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
											textAlign="center"
											w="80px">
											{translate('admin.staff.userAdherentAndContributing')}
										</Th>
										<Th
											color={ProjectConfig.ARCA_COLORS.GREEN_800}
											borderBottomWidth="2px"
											borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
											textAlign="center"
											w="80px"
											fontSize="10px">
											{translate('admin.staff.totalAported') + ' ' + selectedYear}
										</Th>
										<Th
											color={ProjectConfig.ARCA_COLORS.GREEN_800}
											borderBottomWidth="2px"
											borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
											textAlign="center"
											w="80px"
											fontSize="10px">
											{translate('admin.staff.totalContributed') + ' ' + selectedYear}
										</Th>
										<Th
											color={ProjectConfig.ARCA_COLORS.GREEN_800}
											borderBottomWidth="2px"
											borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
											textAlign="center"
											w="80px"
											fontSize="10px">
											{translate('admin.staff.generatedEconomicalRights')}
										</Th>
										<Th
											color={ProjectConfig.ARCA_COLORS.GREEN_800}
											borderBottomWidth="2px"
											borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
											textAlign="center"
											w="80px"
											fontSize="10px">
											{translate('admin.staff.generatedSalaryByReduction') + ' ' + selectedYear}
										</Th>
										<Th
											color={ProjectConfig.ARCA_COLORS.GREEN_800}
											borderBottomWidth="2px"
											borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
											textAlign="center"
											w="80px"
											fontSize="10px">
											{translate('admin.staff.nextParticipantContribution')}
										</Th>
										<Th
											color={ProjectConfig.ARCA_COLORS.GREEN_800}
											borderBottomWidth="2px"
											borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
											textAlign="center"
											w="80px"
											fontSize="10px">
											{translate('admin.staff.nextPromoterContribution')}
										</Th>
										<Th
											color={ProjectConfig.ARCA_COLORS.GREEN_800}
											borderBottomWidth="2px"
											borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
											textAlign="center"
											w="120px"
											fontSize="10px">
											{translate('admin.staff.annualSalary')}
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									{/* Renderizamos las filas de usuarios */}
									{isPageLoading
										? Array.from({ length: 5 }).map((_, index) => (
												<Tr key={index}>
													<Td
														py="10px"
														borderColor="gray.300"
														textAlign="center"
														fontSize="14px"
														px="2">
														<Skeleton fadeDuration={7} height="30px" />
													</Td>
													<Td
														py="10px"
														borderColor="gray.300"
														textAlign="center"
														fontSize="14px"
														px="2">
														<Skeleton fadeDuration={7} height="30px" />
													</Td>
													<Td
														py="10px"
														borderColor="gray.300"
														textAlign="center"
														fontSize="14px"
														px="2">
														<Skeleton fadeDuration={7} height="30px" />
													</Td>
													<Td
														py="10px"
														borderColor="gray.300"
														textAlign="center"
														fontSize="14px"
														px="2">
														<Skeleton fadeDuration={7} height="30px" />
													</Td>
													<Td
														py="10px"
														borderColor="gray.300"
														textAlign="center"
														fontSize="14px"
														px="2">
														<Skeleton fadeDuration={7} height="30px" />
													</Td>
													<Td
														py="10px"
														borderColor="gray.300"
														textAlign="center"
														fontSize="14px"
														px="2">
														<Skeleton fadeDuration={7} height="30px" />
													</Td>
													<Td
														py="10px"
														borderColor="gray.300"
														textAlign="center"
														fontSize="14px"
														px="2">
														<Skeleton fadeDuration={7} height="30px" />
													</Td>
													<Td
														py="10px"
														borderColor="gray.300"
														textAlign="center"
														fontSize="14px"
														px="2">
														<Skeleton fadeDuration={7} height="30px" />
													</Td>
													<Td
														py="10px"
														borderColor="gray.300"
														textAlign="center"
														fontSize="14px"
														px="2">
														<Skeleton fadeDuration={7} height="30px" />
													</Td>
													<Td
														py="10px"
														borderColor="gray.300"
														textAlign="center"
														fontSize="14px"
														px="2">
														<Skeleton fadeDuration={7} height="30px" />
													</Td>
												</Tr>
											))
										: usersInputCalculations.map((userInputCalculation, index) => {
												const userActiveAndOnboard =
													userInputCalculation?.user?.status === 'active' &&
													userInputCalculation?.user?.completedOnboarding &&
													userInputCalculation?.user?.completedProfile;

												return (
													<Tr key={index}>
														<Td
															borderColor="gray.300"
															textAlign="center"
															fontSize="14px"
															px="2"
															maxW="150px">
															{userInputCalculation?.user?.email?.length > 24
																? `${userInputCalculation.user.email.substring(0, 24)}...`
																: userInputCalculation?.user?.email}
														</Td>
														<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
															{userInputCalculation?.user?.statusIcon}
														</Td>
														<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
															{`${formatArcaNumber(userInputCalculation.totalContributionsByParticipant)}  €`}
														</Td>
														<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
															{`${formatArcaNumber(userInputCalculation.totalContributionsByCompany)}  €`}
														</Td>
														<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
															{`${formatArcaNumber(userInputCalculation.totalEconomicsRightsGenerated)}  €`}
														</Td>
														<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
															{`${formatArcaNumber(userInputCalculation.savingsByContingencesReduction)}  €`}
														</Td>
														<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
															{userActiveAndOnboard
																? `${formatArcaNumber(userInputCalculation?.nextParticipantContribution)} €`
																: '-'}
														</Td>
														<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
															{userActiveAndOnboard
																? `${formatArcaNumber(Number(userInputCalculation?.nextPromoterContribution))} €`
																: '-'}
														</Td>
														<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
															{userInputCalculation?.user?.grossAnnualSalary ? (
																`${formatArcaNumber(userInputCalculation?.user?.grossAnnualSalary)} €`
															) : (
																<GrossSalaryInputComponent
																	token={token}
																	onInform={() => {
																		handleSalaryInform();
																	}}
																	userId={userInputCalculation?.user?.id}
																/>
															)}
														</Td>
													</Tr>
												);
											})}
								</Tbody>
								<Tfoot>
									<Tr>
										<Td colSpan={8} textAlign="center" py="20px">
											<Pagination
												currentPage={currentPage}
												totalPages={totalPages}
												onPageChange={handlePageChange}
											/>
										</Td>
									</Tr>
								</Tfoot>
							</Table>
						)}
					</Box>
					{managementAccount && (
						<>
							<Box mt={4}>
								<SectionTitle text={translate('admin.staff.managementAccount')} />
								<Box>
									<Text>{managementAccount?.user?.email}</Text>
								</Box>
							</Box>
						</>
					)}
				</Box>
			)}
			{showToast && (
				<GenericToast
					title={toastInfo?.title}
					description={toastInfo?.description}
					status={toastInfo?.status}
					showToast={showToast}
					duration={4000}
				/>
			)}
			<GenericModal
				isOpen={isOpenGeneric}
				onClose={onCloseGeneric}
				message={''}
				isError={false}
				onAction={handleClickInviteEmployees}
				inputString={true}
				modalTitle={inviteEmployeesTitle}
				modalSubtitle={inviteEmployeesSubtitle}
				closeModalButton={true}
				maxWidth="80%"
			/>
			<PayfitFileModal
				isOpen={showExportPayfitModal}
				onClose={() => setShowExportPayfitModal(false)}
			/>
		</div>
	);
};

export default PlantillaTab;
