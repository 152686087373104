import React, { useContext, useEffect, useState } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Text,
	Box,
	Input,
	Checkbox
} from '@chakra-ui/react';
import { ContributionTypeEnum } from '../../../api/users/interfaces/ContributionData';
import ArcaSecondaryButton from '../../Buttons/ArcaSecondaryButton';
import ArcaPrimaryButton from '../../Buttons/ArcaPrimaryButton';
import { ContributionPeriodicityEnum } from '../../../api/companies/interfaces/CompanyEppConfig';
import InfoIconClick from '../infoIconClick';
import calculateMaxAportationsInfo, {
	MaxAportationsInfoFreelanceOutput,
	MaxAportationsInfoOutput
} from '../../../api/companies/calculateMaxAportationsInfo';
import { ArcaContext } from '../../../context/ArcaContext.context';
import { GenericBackendResponse } from '../../../api/genericBackendResponse';
import askAdminSalaryData from '../../../api/users/askAdminSalaryData';
import GenericToast, { ToastStatusEnum } from '../Toasts/GenericToast';
import ArcaDestructiveButton from '../../Buttons/ArcaDestructiveButton';
import ProjectConfig from '../../../constants';

interface EditParticipantContributionModalProps {
	isOpen: boolean;
	onClose: () => void;
	isFreelance: boolean;
	contributionType: ContributionTypeEnum;
	setContributionType: (value: ContributionTypeEnum) => void;
	contributionFixedAmount: number | '' | undefined;
	handleChangeFixedContribution: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleSaveChangesEditFixedContribution: () => void;
	translate: (key: string) => string;
	companyEppConfig?: {
		contributionDay: number;
		employeeContributionRequired: boolean;
		employeeContributionMinimum: number;
		contributionPeriodicity: ContributionPeriodicityEnum;
	};
	contributeAlwaysMax: boolean;
	setContributeAlwaysMax: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditParticipantContributionModal: React.FC<EditParticipantContributionModalProps> = ({
	isOpen,
	onClose,
	isFreelance,
	contributionType,
	setContributionType,
	contributionFixedAmount,
	handleChangeFixedContribution,
	handleSaveChangesEditFixedContribution,
	translate,
	companyEppConfig,
	contributeAlwaysMax,
	setContributeAlwaysMax
}) => {
	const { token } = useContext(ArcaContext);

	const [maxAportationsInfo, setMaxAportationsInfo] = useState<MaxAportationsInfoOutput>();
	const [maxAportationsFreelanceInfo, setMaxAportationsFreelanceInfo] =
		useState<MaxAportationsInfoFreelanceOutput>();
	const [contributionFixedAmountState, setContributionFixedAmountState] = useState<
		number | '' | undefined
	>(contributionFixedAmount);
	const [showToast, setShowToast] = useState(false);
	const [toastInfo, setToastInfo] = useState({
		title: '',
		description: '',
		status: ToastStatusEnum.SUCCESS
	});

	useEffect(() => {
		setContributionFixedAmountState(contributionFixedAmount);
		if (isOpen) {
			const fetchMaxAportationsInfo = async () => {
				try {
					const res: GenericBackendResponse<
						MaxAportationsInfoOutput | MaxAportationsInfoFreelanceOutput
					> = await calculateMaxAportationsInfo(token);
					if (isFreelance) {
						setMaxAportationsFreelanceInfo(res.data as MaxAportationsInfoFreelanceOutput);
					} else {
						setMaxAportationsInfo(res.data as MaxAportationsInfoOutput);
					}
				} catch (error) {
					console.error('Error fetching max aportations info:', error);
				}
			};

			fetchMaxAportationsInfo();
		}
	}, [isOpen]);

	const handleMaxContributionClick = () => {
		let maxContributionNextPeriod;

		if (isFreelance) {
			maxContributionNextPeriod =
				maxAportationsFreelanceInfo?.maxFreelanceContributionEndOfNextPeriod;
		} else {
			maxContributionNextPeriod = maxAportationsInfo?.maxUserContributionEndOfNextPeriod;
		}

		if (maxContributionNextPeriod) {
			setContributionFixedAmountState(maxContributionNextPeriod);
			handleChangeFixedContribution({
				target: { value: maxContributionNextPeriod }
			} as unknown as React.ChangeEvent<HTMLInputElement>);
		} else {
			console.warn('No se pudo obtener el valor máximo de contribución.');
		}
	};

	const isButtonDisabled = () => {
		const isContributionTooLow =
			companyEppConfig?.employeeContributionRequired &&
			Number(contributionFixedAmountState) < Number(companyEppConfig?.employeeContributionMinimum);

		const isFreelanceContributionTooLow =
			isFreelance &&
			maxAportationsFreelanceInfo &&
			Number(contributionFixedAmountState) >
				maxAportationsFreelanceInfo?.recommendedConfig?.contributionPerPeriod;

		const isRegularContributionTooLow =
			!isFreelance &&
			maxAportationsInfo &&
			Number(contributionFixedAmountState) > maxAportationsInfo?.maxUserContributionEndOfNextPeriod;

		const today = new Date();
		const contributionDay = companyEppConfig?.contributionDay ?? 1;
		const daysUntilContribution = contributionDay - today.getDate();
		const checkBlock4DaysBeforeContributionDay =
			daysUntilContribution <= 4 && daysUntilContribution >= 0;

		return (
			isContributionTooLow ||
			isFreelanceContributionTooLow ||
			isRegularContributionTooLow ||
			checkBlock4DaysBeforeContributionDay
		);
	};

	const saveEmailToAdmin = async (token: string) => {
		try {
			await askAdminSalaryData(token);
			setToastInfo({
				title: 'Solicitud enviada',
				description: 'Se ha solicitado el dato de salario a RRHH con éxito.',
				status: ToastStatusEnum.SUCCESS
			});
			setShowToast(true);
		} catch (error) {
			console.error('Error al solicitar el dato de salario:', error);
		}
	};

	// Calcular si faltan 4 o menos días para la contribución
	const today = new Date();
	const contributionDay = companyEppConfig?.contributionDay ?? 1;
	const daysUntilContribution = contributionDay - today.getDate();
	const shouldBlockModification = daysUntilContribution <= 4 && daysUntilContribution >= 0;

	if (shouldBlockModification) {
		return (
			<Modal isOpen={isOpen} onClose={onClose} size="3xl">
				<ModalOverlay />
				<ModalContent p="20px">
					<ModalBody
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="center">
						<Text>
							{translate('user.myContributions.modalPayrollClosedMessage')}
							<strong>{contributionDay + 1}</strong>
							{translate('user.myContributions.modalPayrollClosedMessage2')}
						</Text>
						<Box display="flex" justifyContent="center" mt="4">
							<ArcaDestructiveButton onClick={onClose}>
								{translate('user.myContributions.modalCloseAction')}
							</ArcaDestructiveButton>
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		);
	}

	let maxContributionRecommended = 0;
	if (isFreelance) {
		maxContributionRecommended =
			maxAportationsFreelanceInfo?.recommendedConfig?.contributionPerPeriod ?? 0;
	} else {
		maxContributionRecommended =
			maxAportationsInfo?.recommendedConfig?.participantContributionPerPeriod ?? 0;
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="3xl">
			<ModalOverlay />
			<ModalContent p="20px">
				<ModalHeader textAlign="center">
					{translate('user.myContributions.modalEditMyContribution')}
				</ModalHeader>
				<ModalBody
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center">
					<Box textAlign="center">
						<Box whiteSpace="pre-line" mb="24px" fontSize="md" textAlign="justify">
							{isFreelance ? (
								translate('user.myContributions.modalCopyEditMyContributionFreelance')
							) : (
								<>
									{translate('user.myContributions.modalCopyEditMyContribution1')}
									<strong>
										{translate(
											`general.contributionPeriodicity.${companyEppConfig?.contributionPeriodicity}`
										)}
									</strong>
								</>
							)}
						</Box>
						<Box whiteSpace="pre-line" mb="24px" fontSize="md" textAlign="justify">
							{isFreelance
								? translate('user.myContributions.modalCopyEditMyContributionFreelance')
								: translate('user.myContributions.modalCopyEditMyContribution2')}
						</Box>
						<ArcaSecondaryButton
							leftIcon={
								<InfoIconClick
									text={
										translate('user.myContributions.modalMaxContributionInfo') +
										' ' +
										translate('user.myContributions.modalCopyEditMyContribution')
									}
									isVisible={true}
									maxW="500px"
									showLink="https://www.boe.es/buscar/act.php?id=BOE-A-2022-10852"
								/>
							}
							onClick={handleMaxContributionClick}>
							{translate('user.myContributions.modalMaxContribution')}
						</ArcaSecondaryButton>

						{maxAportationsInfo?.needToProvideGrossSalary && (
							<>
								<Text fontSize="m">
									{translate('user.myContributions.modalNeedToProvideGrossSalary')}
								</Text>
								<ArcaPrimaryButton onClick={() => saveEmailToAdmin(token)}>
									{translate('user.myContributions.modalRequestGrossSalary')}
								</ArcaPrimaryButton>
							</>
						)}
					</Box>
					<Box position="relative" mt={4}>
						<Box display="flex" alignItems="center" mt={4}>
							<Input
								type="number"
								min={0}
								max={99999}
								value={contributionFixedAmountState || 0}
								textAlign="center"
								width="150px"
								onChange={(e) => {
									handleChangeFixedContribution(e);
									setContributionFixedAmountState(Number(e.target.value) || 0);
								}}
							/>
							<Text
								position="absolute"
								fontSize={20}
								right="1rem"
								top="50%"
								transform="translateY(-50%)"
								pointerEvents="none">
								€
							</Text>
						</Box>
					</Box>
					<Box mt={4}>
						<Box display="flex" alignItems="center">
							<Checkbox
								isChecked={contributeAlwaysMax}
								onChange={(e) => {
									const isChecked = e.target.checked;
									setContributeAlwaysMax(isChecked);
									if (isChecked) {
										handleMaxContributionClick();
									}
								}}>
								{translate('user.myContributions.checkboxContributeAlwaysMax')}
							</Checkbox>
							<Box ml="2">
								<InfoIconClick
									text={translate('user.myContributions.checkboxInfoText')}
									isVisible={true}
									maxW="300px"
								/>
							</Box>
						</Box>
					</Box>
					<Box>
						<Text fontSize="sm">
							{translate('user.myContributions.modalMaxContributionRecommended')}{' '}
							<Text as="span" fontWeight="bold">
								{maxContributionRecommended}
							</Text>{' '}
							{translate('user.myContributions.modalMaxContributionRecommended2')}
						</Text>
					</Box>
				</ModalBody>
				<ModalFooter display="flex" justifyContent="center">
					<ArcaDestructiveButton onClick={onClose}>
						{translate('user.myContributions.modalClose')}
					</ArcaDestructiveButton>
					<ArcaPrimaryButton
						isDisabled={isButtonDisabled()}
						onClick={handleSaveChangesEditFixedContribution}
						color={ProjectConfig.ARCA_COLORS.WHITE}
						backgroundColor={ProjectConfig.ARCA_COLORS.GREEN_500}
						_hover={{
							bg: ProjectConfig.ARCA_COLORS.GREEN_600,
							color: ProjectConfig.ARCA_COLORS.WHITE
						}}>
						{translate('user.myContributions.modalSaveChanges')}
					</ArcaPrimaryButton>
				</ModalFooter>
			</ModalContent>
			{showToast && (
				<GenericToast
					title={toastInfo.title}
					description={toastInfo.description}
					status={toastInfo.status}
					showToast={showToast}
					duration={6000}
				/>
			)}
		</Modal>
	);
};

export default EditParticipantContributionModal;
