import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	Text,
	Input,
	Button,
	useDisclosure,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	InputGroup,
	InputRightElement,
	IconButton
} from '@chakra-ui/react';
import { ArcaContext } from '../../../context/ArcaContext.context';
import updateUserPassword from '../../../api/users/updateUserPassword';
import { UserUpdatePasswordI } from '../../../api/users/interfaces/UserUpdatePassword';
import GenericModal from '../../../components/ChakraUi/Modals/GenericModal';
import getOptionByName from '../../../api/generic-api/getOptionByName';
import updateUserLanguage from '../../../api/users/updateUserLanguage';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import ProjectConfig from '../../../constants';
import SectionTitle from '../../../components/Titles/SectionTitle';
import ArcaPrimaryButton from '../../../components/Buttons/ArcaPrimaryButton';
import { ViewOffIcon, ViewIcon, ChevronDownIcon } from '@chakra-ui/icons';

const UserAjustesTab: React.FC = () => {
	const {
		state: { translate }
	} = useContext(I18nContext);
	const { token, userId, language, setLanguage } = useContext(ArcaContext);
	const { dispatch } = React.useContext(I18nContext);

	const [loading, setLoading] = useState<boolean>(false);
	const [userUpdatePassword, setUserUpdatePassword] = useState<UserUpdatePasswordI[]>([]);
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [currentPasswordError, setCurrentPasswordError] = useState(false);
	const [newPasswordError, setNewPasswordError] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const [messageAlert, setMessageAlert] = useState(
		translate('user.userSettings.updatePasswordSuccess')
	);
	const [isError, setIsError] = useState(false);
	const [availableLanguages, setAvailableLanguages] = useState<string[]>([]);
	const [repeatPassword, setRepeatPassword] = useState('');
	const [repeatPasswordError, setRepeatPasswordError] = useState(false);
	const [passwordsMatch, setPasswordsMatch] = useState(false);

	const { isOpen: isOpenGeneric, onOpen: onOpenGeneric, onClose: onCloseGeneric } = useDisclosure();

	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showRepeatPassword, setShowRepeatPassword] = useState(false);

	useEffect(() => {
		const getAvailableLanguagesAsync = async () => {
			const availableLanguagesResponse = await getOptionByName(token, 'user-available-languages');
			setAvailableLanguages(JSON.parse(availableLanguagesResponse.data?.value));
		};

		const updateUserPasswordAsync = async () => {
			if (submitting && currentPassword && newPassword) {
				try {
					const res = await updateUserPassword(token, userId, currentPassword, newPassword);
					if (!res.success) {
						setMessageAlert(translate('user.userSettings.messageError'));
						if (res.message === 'USER_NOT_FOUND_OR_WRONG_PASSWORD') {
							setMessageAlert(translate('user.userSettings.userNotFoundOrPasswordIncorrect'));
						}
						onOpenGeneric();
						setIsError(true);
					} else {
						setMessageAlert(translate('user.userSettings.updatePasswordSuccess'));
						setUserUpdatePassword(res.data);
						setIsError(false);
						onOpenGeneric();
					}
				} catch (error) {
					console.error('Error updating password:', error);
					setMessageAlert(translate('user.userSettings.messageError'));
					onOpenGeneric();
					setIsError(true);
				} finally {
					setSubmitting(false);
					setCurrentPassword('');
					setNewPassword('');
				}
			}
		};

		getAvailableLanguagesAsync();
		updateUserPasswordAsync();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submitting, currentPassword, newPassword, token, userId]);

	const handleCurrentPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCurrentPassword(event.target.value);
		setCurrentPasswordError(false);
	};

	const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewPassword(event.target.value);
		setNewPasswordError(false);
		setPasswordsMatch(event.target.value === repeatPassword);
	};

	const handleRepeatPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRepeatPassword(event.target.value);
		setRepeatPasswordError(false);
		setPasswordsMatch(event.target.value === newPassword);
	};

	const handleSubmit = () => {
		if (!currentPassword || !newPassword || !repeatPassword) {
			if (!currentPassword) setCurrentPasswordError(true);
			if (!newPassword) setNewPasswordError(true);
			if (!repeatPassword) setRepeatPasswordError(true);
			return;
		}

		setSubmitting(true);
	};

	const changeLanguage = async (newLanguage: string) => {
		if (newLanguage === language) return;
		// Actualizo el idioma en el ArcaContext
		setLanguage(newLanguage);
		// Actualizo el idioma en el contexto de traducciones
		dispatch({ type: 'setLanguage', payload: newLanguage });
		// Actualizo el idioma en el localStorage
		localStorage.setItem('language', newLanguage);
		// Cambio el idioma en la base de datos. Si falla, no pasa nada (se cambia en la app pero no en la base de datos)
		await updateUserLanguage(token, newLanguage);
	};

	return (
		<Box p={[3, 4, 6]}>
			{loading ? (
				<Spinner
					thickness="4px"
					speed="0.65s"
					emptyColor="gray.200"
					color={ProjectConfig.ARCA_COLORS.GREEN_800}
					size="xl"
					textAlign="center"
					mx="auto"
					my={10}
				/>
			) : (
				<Box
					mx="auto"
					display="flex"
					flexDirection="column"
					alignItems="center"
					width="100%"
					maxW="500px">
					<SectionTitle text={translate('user.userSettings.title')} />

					<Box w="100%" mb={4}>
						<Text>{translate('user.userSettings.currentPassword')}</Text>
						<InputGroup>
							<Input
								type={showCurrentPassword ? 'text' : 'password'}
								value={currentPassword}
								onChange={handleCurrentPasswordChange}
								borderColor={currentPasswordError ? 'red.500' : undefined}
								backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
							/>
							<InputRightElement width="3rem">
								<IconButton
									aria-label={showCurrentPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
									icon={showCurrentPassword ? <ViewOffIcon /> : <ViewIcon />}
									onClick={() => setShowCurrentPassword(!showCurrentPassword)}
									variant="ghost"
									color={ProjectConfig.ARCA_COLORS.PINK_400}
									_hover={{ bg: 'transparent' }}
									size="sm"
								/>
							</InputRightElement>
						</InputGroup>
						{currentPasswordError && (
							<Text color="red.500">{translate('user.userSettings.mandatoryField')}</Text>
						)}
					</Box>

					<Box w="100%" mb={4}>
						<Text>{translate('user.userSettings.newPassword')}</Text>
						<InputGroup>
							<Input
								type={showNewPassword ? 'text' : 'password'}
								value={newPassword}
								onChange={handleNewPasswordChange}
								borderColor={newPasswordError ? 'red.500' : undefined}
								backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
								placeholder={translate('user.userSettings.introduceNewPassword')}
								_placeholder={{ fontSize: '14px' }}
							/>
							<InputRightElement width="3rem">
								<IconButton
									aria-label={showNewPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
									icon={showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
									onClick={() => setShowNewPassword(!showNewPassword)}
									variant="ghost"
									color={ProjectConfig.ARCA_COLORS.PINK_400}
									_hover={{ bg: 'transparent' }}
									size="sm"
								/>
							</InputRightElement>
						</InputGroup>
						{newPasswordError && (
							<Text color="red.500">{translate('user.userSettings.mandatoryField')}</Text>
						)}
					</Box>

					<Box w="100%" mb={6}>
						<InputGroup>
							<Input
								type={showRepeatPassword ? 'text' : 'password'}
								value={repeatPassword}
								onChange={handleRepeatPasswordChange}
								borderColor={repeatPasswordError ? 'red.500' : undefined}
								backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
								placeholder={translate('user.userSettings.repeatNewPassword')}
								_placeholder={{ fontSize: '14px' }}
							/>
							<InputRightElement width="3rem">
								<IconButton
									aria-label={showRepeatPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
									icon={showRepeatPassword ? <ViewOffIcon /> : <ViewIcon />}
									onClick={() => setShowRepeatPassword(!showRepeatPassword)}
									variant="ghost"
									color={ProjectConfig.ARCA_COLORS.PINK_400}
									_hover={{ bg: 'transparent' }}
									size="sm"
								/>
							</InputRightElement>
						</InputGroup>
						{repeatPasswordError && (
							<Text color="red.500" fontSize="sm">
								{translate('user.userSettings.mandatoryField')}
							</Text>
						)}
						{!passwordsMatch && repeatPassword && (
							<Text color="red.500" fontSize="sm">
								{translate('user.userSettings.passwordsDontMatch')}
							</Text>
						)}
					</Box>

					<Box w="100%" display="flex" flexDirection="column" alignItems="center" gap={4}>
						<ArcaPrimaryButton
							onClick={handleSubmit}
							isDisabled={!passwordsMatch || !currentPassword || !newPassword || !repeatPassword}
							width={['100%', 'auto']}>
							{translate('user.userSettings.updatePassword')}
						</ArcaPrimaryButton>

						<Menu>
							<ArcaPrimaryButton
								as={MenuButton}
								width={['100%', 'auto']}
								rightIcon={<ChevronDownIcon />}>
								{translate('user.userSettings.currentLanguage')}{' '}
								{translate(`user.userSettings.${language}`)}
							</ArcaPrimaryButton>
							<MenuList backgroundColor={ProjectConfig.ARCA_COLORS.LIGHT_GREY_02}>
								{availableLanguages.map((lang, index) => (
									<MenuItem
										key={index}
										onClick={() => changeLanguage(lang)}
										color={ProjectConfig.ARCA_COLORS.PINK_400}>
										{translate(`user.userSettings.${lang}`)}
									</MenuItem>
								))}
							</MenuList>
						</Menu>
					</Box>
				</Box>
			)}
			<GenericModal
				isOpen={isOpenGeneric}
				onClose={onCloseGeneric}
				message={messageAlert}
				isError={isError}
			/>
		</Box>
	);
};

export default UserAjustesTab;
