import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { AiOutlineMessage, AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import Draggable from 'react-draggable';
import ProjectConfig from '../../constants';

interface DraggableMobileChatButtonProps {
	onClick: () => void;
}

const DraggableMobileChatButton: React.FC<DraggableMobileChatButtonProps> = ({ onClick }) => {
	// Estado para controlar si el botón está minimizado
	const [isMinimized, setIsMinimized] = useState(false);

	// Estado para controlar si el usuario está interactuando con el botón
	const [isActive, setIsActive] = useState(false);

	// Estado para la posición del botón
	const [position, setPosition] = useState({ x: window.innerWidth - 80, y: 100 });
	const [isDragging, setIsDragging] = useState(false);
	const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

	// Guardar posición en localStorage cuando cambia
	useEffect(() => {
		localStorage.setItem('chatButtonPosition', JSON.stringify(position));
	}, [position]);

	// Función para manejar el inicio del arrastre
	const handleTouchStart = (e: React.TouchEvent) => {
		const touch = e.touches[0];
		setIsDragging(true);
		setDragOffset({
			x: touch.clientX - position.x,
			y: touch.clientY - position.y
		});
	};

	// Función para manejar el movimiento durante el arrastre
	const handleTouchMove = (e: React.TouchEvent) => {
		if (!isDragging) return;

		const touch = e.touches[0];
		const newX = touch.clientX - dragOffset.x;
		const newY = touch.clientY - dragOffset.y;

		// Mantener dentro de los límites de la pantalla
		const maxX = window.innerWidth - 60;
		const maxY = window.innerHeight - 60;

		setPosition({
			x: Math.min(Math.max(0, newX), maxX),
			y: Math.min(Math.max(0, newY), maxY)
		});
	};

	// Función para manejar el fin del arrastre
	const handleTouchEnd = () => {
		setIsDragging(false);
	};

	// Ajustar posición cuando cambia el tamaño de la ventana
	useEffect(() => {
		const handleResize = () => {
			setPosition((prevPosition) => ({
				x: Math.min(prevPosition.x, window.innerWidth - 60),
				y: Math.min(prevPosition.y, window.innerHeight - 60)
			}));
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	// Manejar clic en el botón principal
	const handleMainButtonClick = () => {
		// Llamar a onClick independientemente del estado de minimizado
		onClick();

		// Si está minimizado, también lo expandimos
		if (isMinimized) {
			setIsMinimized(false);
		}
	};

	// Establecer como activo cuando el usuario interactúa con el botón
	const handleMouseEnterOrTouch = () => {
		setIsActive(true);
		// Desactivar tras 3 segundos de inactividad
		setTimeout(() => setIsActive(false), 3000);
	};

	return (
		<Box
			position="fixed"
			top={`${position.y}px`}
			left={`${position.x}px`}
			zIndex="999"
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			onTouchEnd={handleTouchEnd}
			style={{ touchAction: 'none' }}>
			<IconButton
				aria-label="Chat"
				icon={<AiOutlineMessage size={isMinimized ? '16px' : '24px'} />}
				onClick={handleMainButtonClick}
				borderRadius="50%"
				bg={ProjectConfig.ARCA_COLORS.PINK_500}
				color="white"
				opacity={isActive ? 1 : 0.7}
				width={isMinimized ? '40px' : '60px'}
				height={isMinimized ? '40px' : '60px'}
				boxShadow="0 3px 10px rgba(0,0,0,0.2)"
				_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_600, opacity: 1 }}
				transition="all 0.3s ease"
			/>

			{/* Botón minimizar/maximizar */}
			<IconButton
				aria-label={isMinimized ? 'Expandir' : 'Minimizar'}
				icon={
					isMinimized ? <AiOutlinePlusCircle size="12px" /> : <AiOutlineMinusCircle size="12px" />
				}
				onClick={() => setIsMinimized(!isMinimized)}
				position="absolute"
				top={isMinimized ? '-3px' : '-5px'}
				right={isMinimized ? '-3px' : '-5px'}
				borderRadius="50%"
				bg="white"
				color={ProjectConfig.ARCA_COLORS.PINK_500}
				size="2xs"
				width="18px"
				height="18px"
				minWidth="18px"
				padding="0"
				boxShadow="0 1px 3px rgba(0,0,0,0.2)"
				border={`1px solid ${ProjectConfig.ARCA_COLORS.PINK_300}`}
				_hover={{ bg: 'gray.100', transform: 'scale(1.05)' }}
				_active={{ transform: 'scale(0.95)' }}
				transition="all 0.2s ease"
				zIndex="1000"
			/>
		</Box>
	);
};

export default DraggableMobileChatButton;
