import React from 'react';
import { Box } from '@chakra-ui/react';
import DesktopContributionsTable from './DesktopContributionsTable';
import MobileContributionsTable from './MobileContributionsTable';
import ProjectConfig from '../../constants';
import { CurrentUserI } from '../../api/users/interfaces/Users ';

interface ContributionsTableWrapperProps {
	rows: any[];
	isFreelance: boolean;
	isPageLoading: boolean;
	expandedRows: Record<string, boolean>;
	toggleRow: (month: string) => void;
	currentPage: number;
	totalPages: number;
	handlePageChange: (page: number) => void;
	translate: (key: string) => string;
	formatArcaNumber: (num: number) => string;
	currentUser: CurrentUserI;
}

const ContributionsTableWrapper: React.FC<ContributionsTableWrapperProps> = ({
	rows,
	isFreelance,
	isPageLoading,
	expandedRows,
	toggleRow,
	currentPage,
	totalPages,
	handlePageChange,
	translate,
	formatArcaNumber,
	currentUser
}) => {
	return (
		<Box
			backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
			borderRadius="12px"
			width="100%"
			overflowX="auto">
			{/* Desktop and tablet view */}
			<Box display={{ base: 'none', md: 'block' }}>
				<DesktopContributionsTable
					rows={rows}
					isFreelance={isFreelance}
					isPageLoading={isPageLoading}
					expandedRows={expandedRows}
					toggleRow={toggleRow}
					currentPage={currentPage}
					totalPages={totalPages}
					handlePageChange={handlePageChange}
					translate={translate}
					formatArcaNumber={formatArcaNumber}
					currentUser={currentUser}
				/>
			</Box>

			{/* Mobile view - card-based layout */}
			<Box display={{ base: 'block', md: 'none' }}>
				<MobileContributionsTable
					rows={rows}
					isFreelance={isFreelance}
					expandedRows={expandedRows}
					toggleRow={toggleRow}
					currentPage={currentPage}
					totalPages={totalPages}
					handlePageChange={handlePageChange}
					translate={translate}
					formatArcaNumber={formatArcaNumber}
					currentUser={currentUser}
				/>
			</Box>
		</Box>
	);
};

export default ContributionsTableWrapper;
