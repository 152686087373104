/**
 * Función para enviar eventos a MetricsWave
 */
export function triggerMetricsWaveEvent(eventUuid: string, userId: string, params: Object = {}) {
	const productionEnvironment = process.env.REACT_APP_BASE_API?.includes('dev') ? false : true;

	if (!productionEnvironment) {
		console.log(`[EventTracker] ${eventUuid}`, params);
		return;
	}

	const bodyParams = {
		...params,
		user_id: userId
	};

	fetch(`https://metricswave.com/webhooks/${eventUuid}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json'
		},
		body: JSON.stringify(bodyParams)
	});

	return;
}
