const toggleFeatureFlagByName = async (
	token: string,
	companyId: string,
	userId: string,
	featureFlagName: string
) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/feature-flags/toggle/${featureFlagName}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify({ companyId, userId, featureFlagName })
		}
	);

	return response.json();
};

export default toggleFeatureFlagByName;
