import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import ProjectConfig from '../../constants';

interface ArcaSecondaryButtonProps extends ButtonProps {
	color?: string;
	backgroundColor?: string;
	_hover?: object;
	onClick?: () => void;
	children: React.ReactNode;
}

const ArcaSecondaryButton: React.FC<ArcaSecondaryButtonProps> = ({
	color = ProjectConfig.ARCA_COLORS.PINK_400,
	backgroundColor = ProjectConfig.ARCA_COLORS.WHITE,
	_hover = {
		bg: ProjectConfig.ARCA_COLORS.WHITE,
		color: ProjectConfig.ARCA_COLORS.BLACK,
		backgroundColor: ProjectConfig.ARCA_COLORS.PINK_200,
		textDecoration: 'none'
	},
	onClick,
	children,
	...props
}) => {
	return (
		<Button
			color={color}
			backgroundColor={backgroundColor}
			_hover={_hover}
			onClick={onClick}
			textDecorationThickness="1px"
			textUnderlineOffset="2px"
			fontWeight="500"
			mx="auto"
			w="fit-content"
			px="30px"
			borderRadius="8px"
			border={`2px solid ${ProjectConfig.ARCA_COLORS.PINK_400}`}
			{...props}>
			{children}
		</Button>
	);
};

export default ArcaSecondaryButton;
