import { Box, Grid, GridItem, SimpleGrid } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import BenUserCard from '../../../components/ChakraUi/Cards/BenUserCard';
import ProjectConfig from '../../../constants';
import { SingleUserContributionData } from '../../../api/users/interfaces/ContributionData';
import getCalculatorsAdminAportations from '../../../api/calculators/getCalculatorsAdminAportations';
import { ArcaContext } from '../../../context/ArcaContext.context';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import { GenericPaginationOutput } from '../../../api/generic-dtos/genericPaginationOutput';
import LoadingViewSpinner from '../../../components/ChakraUi/Spinners/LoadingViewSpinner';
import { formatArcaNumber } from '../../../utils/formatArcaNumbers';
import SectionTitle from '../../../components/Titles/SectionTitle';
import BenUserCardHidden from '../../../components/ChakraUi/Cards/BenUserCardHidden';
import getCompanyNextContributionInfo, {
	CompanyNextContributionInfo
} from '../../../api/companies/getCompanyNextContributionInfo';
import { GenericBackendResponse } from '../../../api/genericBackendResponse';
import getCompanyInfoEppConfig from '../../../api/companies/getCompanyInfoEppConfig';
import { CompanyInfoI } from '../../../api/companies/interfaces/CompanyInfo';
import { CompanyEppConfigI } from '../../../api/companies/interfaces/CompanyEppConfig';
import AdminContributionsTableWrapper from '../../../components/contributions/AdminContributionsTableWrapper';

const AdminContribuciones: React.FC = () => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	interface CompanyInfoAndEppConfigI {
		company: CompanyInfoI;
		companyEppConfig: CompanyEppConfigI;
	}

	const [initialLoading, setInitialLoading] = useState<boolean>(true);
	const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
	const [nextContributionInfo, setNextContributionInfo] = useState<CompanyNextContributionInfo>();

	const [userAportationsByType, setUserAportationsByType] =
		useState<GenericPaginationOutput<SingleUserContributionData>>();
	const [currentCompanyAndEppConfig, setCurrentCompanyAndEppConfig] =
		useState<CompanyInfoAndEppConfigI>();

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const { token, companyId } = useContext(ArcaContext);

	useEffect(() => {
		const limitSelected = 5;

		getCalculatorsAdminAportations(token, currentPage, limitSelected).then((res) => {
			setUserAportationsByType(res.data || []);
			setTotalPages(res.data.totalPages);
			setInitialLoading(false);
			setIsPageLoading(false);
		});

		const fetchCompanyNextContributionInfo = async () => {
			const res: GenericBackendResponse<CompanyNextContributionInfo> =
				await getCompanyNextContributionInfo(token);
			setNextContributionInfo(res.data);
		};
		fetchCompanyNextContributionInfo();

		const fetchCompanyInfoEppConfig = async () => {
			const currentCompanyAndEppConfig = await getCompanyInfoEppConfig(token, companyId);
			const currentCompanyAndEppConfigData =
				currentCompanyAndEppConfig.data as CompanyInfoAndEppConfigI;
			setCurrentCompanyAndEppConfig(currentCompanyAndEppConfigData);
		};
		fetchCompanyInfoEppConfig();
	}, [token, companyId, currentPage]);

	function formatDate(dateString: string): string {
		const date = new Date(dateString);
		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
	}

	const handlePageChange = (page: number) => {
		setIsPageLoading(true);
		setCurrentPage(page);
	};

	const nextContributionDate = nextContributionInfo?.nextContributionDate;
	const totalNextContribution =
		(nextContributionInfo?.participantsAmount ?? 0) + (nextContributionInfo?.promoterAmount ?? 0);

	return (
		<div>
			{initialLoading ? (
				<Box h="80dvh" display="flex" justifyContent="center" alignItems="center">
					<LoadingViewSpinner />
				</Box>
			) : (
				<div>
					<Grid
						templateAreas={{
							base: `
							"slo00"
							"slo10"`,
							lg: `"slo00"
								 "slo10"`
						}}
						gridTemplateRows={{
							base: 'auto auto',
							lg: 'auto auto'
						}}
						h="100%"
						gap={{ base: '16px', xl: '16px' }}
						color="blackAlpha.700">
						<GridItem area={'slo00'} mt="16px">
							<SectionTitle text={translate('admin.contributions.nextContributionTitle')} />
							<SimpleGrid
								spacing="8px"
								columns={{ base: 1, md: 2, lg: 4 }}
								minChildWidth={{ base: '100%', md: '200px' }}
								h="100%">
								<BenUserCard
									heading={translate('admin.contributions.nextContributionAmount')}
									cardBody={`${formatArcaNumber(totalNextContribution)} €`}
									infoIconVisible={true}
									infoIconText={`${translate('admin.contributions.nextContributionIntro')}  
										${
											currentCompanyAndEppConfig?.companyEppConfig.employeeContributionRequired
												? translate('admin.contributions.nextContributionMandatoryContribution')
												: translate('admin.contributions.nextContributionOptionalContribution')
										}`}
								/>

								<BenUserCard
									heading={translate('admin.contributions.deadline')}
									cardBody={nextContributionDate}
									infoIconVisible={false}
									infoIconText={translate('user.myContributions.cardAmountContributedIconText')}
								/>
								<GridItem colSpan={{ base: 1, lg: 2 }}>
									<BenUserCardHidden
										heading={
											currentCompanyAndEppConfig?.company.bankAccount
												? translate('admin.contributions.bankAccount')
												: translate('admin.contributions.bankAccountConfigNeeded') +
													' ' +
													ProjectConfig.ARCA_CONTACT.EMAIL
										}
										cardBody={`${currentCompanyAndEppConfig?.company.bankAccount ?? ''}`}
										infoIconVisible={false}
										infoIconText={translate('user.myContributions.cardAmountContributedIconText')}
									/>
								</GridItem>
							</SimpleGrid>
						</GridItem>

						{/* Tabla de Aportaciones */}
						<GridItem area="slo10" mt={8}>
							<SectionTitle text={translate('admin.contributions.title')} />

							{/* Use the new wrapper component */}
							<AdminContributionsTableWrapper
								contributionItems={userAportationsByType?.items || []}
								isPageLoading={isPageLoading}
								currentPage={currentPage}
								totalPages={totalPages}
								handlePageChange={handlePageChange}
								translate={translate}
								formatArcaNumber={formatArcaNumber}
								formatDate={formatDate}
							/>
						</GridItem>
					</Grid>
				</div>
			)}
		</div>
	);
};

export default AdminContribuciones;
