import React from 'react';
import {
	Box,
	Flex,
	Text,
	useDisclosure,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerOverlay,
	Avatar
} from '@chakra-ui/react';
import { TbLogout } from 'react-icons/tb';
import { GrUserAdmin } from 'react-icons/gr';
import { FaRegUser } from 'react-icons/fa';
import ProjectConfig from '../../constants';
import { CurrentUserI } from '../../api/users/interfaces/Users ';
import ArcaGhostButton from '../Buttons/ArcaGhostButton';
import ArcaPrimaryButton from '../Buttons/ArcaPrimaryButton';

interface MobileBottomNavProps {
	currentTabs: {
		key: string;
		label: string;
		icon: React.ReactElement;
		route: string;
	}[];
	activeTab: string;
	setActiveTabAndNavigate: (tab: any) => void;
	currentUser: CurrentUserI;
	handleLogout: () => void;
	viewType: string;
	userAccountType: string;
	setViewType: (viewType: string) => void;
	translate: (key: string) => string;
	navigateToDefaultTab: (type: string) => void;
	isFreelance?: boolean;
}

const MobileBottomNav: React.FC<MobileBottomNavProps> = ({
	currentTabs,
	activeTab,
	setActiveTabAndNavigate,
	currentUser,
	handleLogout,
	viewType,
	userAccountType,
	setViewType,
	translate,
	navigateToDefaultTab,
	isFreelance
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	// Muestra solo los 4 tabs más importantes en el menú inferior
	const visibleTabs = currentTabs.slice(0, 4);

	return (
		<>
			{/* Menú inferior fijo */}
			<Flex
				position="fixed"
				bottom={0}
				left={0}
				right={0}
				bg={ProjectConfig.ARCA_COLORS.WHITE}
				boxShadow="0 -2px 10px rgba(0,0,0,0.1)"
				zIndex={100}
				p={2}
				justifyContent="space-around">
				{visibleTabs.map((tab) => (
					<Flex
						key={tab.key}
						direction="column"
						alignItems="center"
						onClick={() => setActiveTabAndNavigate(tab)}
						color={activeTab === tab.key ? ProjectConfig.ARCA_COLORS.PINK_500 : 'gray.500'}
						fontWeight={activeTab === tab.key ? 'bold' : 'normal'}
						flex={1}>
						<Box
							p={1}
							borderRadius="md"
							bg={activeTab === tab.key ? ProjectConfig.ARCA_COLORS.PINK_100 : 'transparent'}>
							{tab.icon}
						</Box>
						<Text fontSize="xs" mt={1} textAlign="center">
							{tab.label.length > 10 ? `${tab.label.substring(0, 8)}...` : tab.label}
						</Text>
					</Flex>
				))}

				<Flex direction="column" alignItems="center" onClick={onOpen} color="gray.500" flex={1}>
					<Box p={1}>
						<Avatar size="xs" name={currentUser?.user.name || ''} src="/imgs/user-profile.png" />
					</Box>
					<Text fontSize="xs" mt={1}>
						{translate('general.menu.more')}
					</Text>
				</Flex>
			</Flex>

			{/* Drawer para opciones adicionales */}
			<Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
				<DrawerOverlay />
				<DrawerContent borderTopRadius="20px" pb="70px">
					<DrawerBody p={4}>
						<Flex direction="column" align="center" mb={4}>
							<Avatar
								size="lg"
								name={currentUser?.user.name || ''}
								src="/imgs/user-profile.png"
								mb={2}
							/>
							<Text fontWeight="bold">
								{currentUser?.user.name} {currentUser?.user.firstSurname}
							</Text>
							<Text fontSize="sm">{currentUser?.user.email}</Text>
						</Flex>

						{/* Selector de rol (solo para BOTH) */}
						{userAccountType === 'BOTH' && (
							<Flex justify="center" mb={4}>
								<ArcaPrimaryButton
									mr={2}
									backgroundColor={
										viewType === 'ADMIN' ? ProjectConfig.ARCA_COLORS.PINK_500 : 'gray.200'
									}
									color={viewType === 'ADMIN' ? 'white' : 'gray.500'}
									_hover={{
										bg: viewType === 'ADMIN' ? ProjectConfig.ARCA_COLORS.PINK_600 : 'gray.300',
										color: viewType === 'ADMIN' ? 'white' : 'gray.700'
									}}
									leftIcon={<GrUserAdmin />}
									onClick={() => {
										setViewType('ADMIN');
										navigateToDefaultTab('ADMIN');
										onClose();
									}}>
									{translate('general.avatar.admin')}
								</ArcaPrimaryButton>
								<ArcaPrimaryButton
									backgroundColor={
										viewType === 'USER' ? ProjectConfig.ARCA_COLORS.PINK_500 : 'gray.200'
									}
									color={viewType === 'USER' ? 'white' : 'gray.500'}
									_hover={{
										bg: viewType === 'USER' ? ProjectConfig.ARCA_COLORS.PINK_600 : 'gray.300',
										color: viewType === 'USER' ? 'white' : 'gray.700'
									}}
									leftIcon={<FaRegUser />}
									onClick={() => {
										setViewType('USER');
										navigateToDefaultTab('USER');
										onClose();
									}}>
									{translate('general.avatar.user')}
								</ArcaPrimaryButton>
							</Flex>
						)}

						{/* Tabs adicionales que no caben en el menú inferior */}
						{currentTabs.slice(4).map((tab) => (
							<ArcaGhostButton
								key={tab.key}
								leftIcon={tab.icon}
								justifyContent="flex-start"
								w="100%"
								mb={2}
								px={4}
								color={ProjectConfig.ARCA_COLORS.PINK_500}
								_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_100 }}
								onClick={() => {
									setActiveTabAndNavigate(tab);
									onClose();
								}}>
								{tab.label}
							</ArcaGhostButton>
						))}

						{/* Botón de cierre de sesión */}
						<ArcaGhostButton
							leftIcon={<TbLogout />}
							justifyContent="flex-start"
							w="100%"
							mt={2}
							px={4}
							onClick={handleLogout}
							color={ProjectConfig.ARCA_COLORS.PINK_500}
							_hover={{ color: ProjectConfig.ARCA_COLORS.PINK_700 }}>
							{translate('general.button.logOut')}
						</ArcaGhostButton>
					</DrawerBody>
				</DrawerContent>
			</Drawer>

			{/* Espaciador para evitar que el contenido quede debajo del menú inferior */}
			<Box height="60px" display={{ base: 'block', sm: 'none' }} />
		</>
	);
};

export default MobileBottomNav;
