const ProjectConfig = {
	FONTS: {
		SPACE_GROTESK: 'Space Grotesk',
		INTER: 'Inter'
	},
	ARCA_COLORS: {
		WHITE: '#FFFFFF',
		BLACK: '#23261E',
		RED_500: '#E53E3E',
		RED_800: '#981142',
		GREEN_50: '#EDFEF3',
		GREEN_100: '#D4FFE9',
		GREEN_200: '#ACFDD4',
		GREEN_300: '#70F9B4',
		GREEN_400: '#2AE986',
		GREEN_500: '#12D06D',
		GREEN_600: '#0CA855',
		GREEN_700: '#0D8044',
		GREEN_800: '#106238',
		GREEN_900: '#104F30',
		GREEN_950: '#042C1A',
		PINK_50: '#FFEFF2',
		PINK_100: '#FFDFE7',
		PINK_200: '#FFB3C5',
		PINK_300: '#FF98B1',
		PINK_400: '#FE638A',
		PINK_500: '#F73469',
		PINK_600: '#E11855',
		PINK_700: '#BA1047',
		PINK_800: '#981142',
		PINK_900: '#7F123C',
		PINK_950: '#44081E',
		LIGHT_GREY_01: '#BABABA',
		LIGHT_GREY_02: '#EDEDED',
		LIGHT_GREY_03: '#F5F5F5'
	},
	ARCA_CONTACT: {
		EMAIL: 'soporte@arcadigital.app',
		PHONE: '+34 644 81 31 98',
		PHONE_FOR_WHATSAPP_LINK: '34644813198'
	}
};
export default ProjectConfig;
