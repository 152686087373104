// Calcula el valor futuro acumulado de forma compuesta para "years" años
export const calculateFutureValue = (
	currentPensionSavings: number,
	years: number,
	monthlyContribution: number,
	historicalReturn: number
) => {
	const monthlyRate = historicalReturn / 100 / 12;
	const totalMonths = years * 12;
	const futureValueSavings = currentPensionSavings * (1 + monthlyRate) ** totalMonths;
	const futureValueContributions =
		(monthlyContribution * ((1 + monthlyRate) ** totalMonths - 1)) / monthlyRate;
	return futureValueSavings + futureValueContributions;
};
